import { IBaseModel } from './IBaseModel';

export interface ProjectCard extends IBaseModel {
    title: string;
    listId: string;
    index: number;
    description: string;
    ticketId: number;
    ownerId: string;
    status: string;
    estimatedWork: number;
    actualWork: number;
    statusChanged: Date;
    roadmapItem: string;
}

export const EmptyProjectCard = {
    _id: "",
    created: new Date(),
    modified: new Date(),
    active: false,
    title: "",
    listId: "",
    index: 0,
    description: "",
    ticketId: 0,
    ownerId: "",
    status: "",
    estimatedWork: 0,
    actualWork: 0,
    statusChanged: new Date(),
    roadmapItem: "",
}