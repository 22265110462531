import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { CSSProperties, useEffect, useState, useRef } from 'react';
import { getRoadmap, setRoadmapCards, saveRoadmapCard, deleteRoadmapCard } from "../Store/Roadmap";
import { getProject, loadProject } from '../Store/Project';
import { MdAdd } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
//import RoadmapContextMenu from './RoadmapContextMenu';
//import { RoadmapContextMenuParams } from '../Models/Requests/RoadmapContextMenuParams';
import AddRoadmapCardContextMenu from "../Modals/AddRoadmapCardContextMenu";
import { AddRoadmapCardContextMenuParams } from '../Models/Requests/AddRoadmapCardContextMenuParams';

import { getQuarter, getMonth, getDayOfYear, endOfYear, getISOWeek, format, addDays, getDay,
    startOfQuarter
 } from 'date-fns';
import { Roadmap } from '../Models/Roadmap';
//import CardContextMenu from '../Components/CardContext';
//import { CardContextParam } from '../Models/Requests/CardContextParam';
//import Avatar from './Avatar';
import { ContextMenu } from '../Models/ContextMenu';
import PopupContextMenu from '../Modals/PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';
import RoadmapCategoryContextMenu from '../Modals/RoadmapCategoryContextMenu';
import { RoadmapCategoryContextMenuParam } from '../Models/Requests/RoadmapCategoryContextMenuParam';
//import { Tooltip } from 'react-tooltip';
//import { BsQuestionLg } from "react-icons/bs";
//import { MdLabel} from "react-icons/md";
//import { LabelContextMenuParam } from '../Models/Requests/LabelContextMenuParam';
//import LabelContextMenu from '../Components/LabelContextMenu';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoTrashOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { BsQuestionLg } from "react-icons/bs";
import { MdLabel } from "react-icons/md";
import { IoIosResize } from "react-icons/io";
import { GoPencil } from 'react-icons/go';
import { RoadmapCard } from '../Models/RoadmapCard';
import { getSettings } from '../Store/Settings';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import { AiOutlinePlus } from 'react-icons/ai';

interface Props {
    roadmap: Roadmap;
}

function RoadmapMain(props: Props) {
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);
    const dispatch = useAppDispatch();
    //const [roadmapContextMenuParams, setRoadmapContextMenuParams] = useState<RoadmapContextMenuParams>();
    const [addRoadmapCardContextMenuParams, setAddRoadmapCardContextMenuParams] = useState<AddRoadmapCardContextMenuParams>();
    const [categoryContextMenu, setCategoryContextMenu] = useState<ContextMenu>();
    const [categoryContextMenuParams, setCategoryContextMenuParams] = useState<RoadmapCategoryContextMenuParam>();
    //const [cardContextParams, setCardContextParams] = useState<CardContextParam>();
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    //const [labelContextMenuParams, setLabelContextMenuParams] = useState<LabelContextMenuParam>();
    const outerHeight = window.outerHeight;

    const [isDraggingTurnOff, setIsDraggingTurnOff] = useState<Map<string, boolean>>();
    const [cardWidth, setCardWidth] = useState<Map<string, string>>();
  
    const [resizeEvent, setResizeEvent] = useState(undefined); 
    const [resizeElement, setResizeElement] = useState<HTMLElement | null>(null);
    const [resizeWidth, setResizeWidth] = useState(0);
    const [resizeLeft, setResizeLeft] = useState("");
    const [resizeRight, setResizeRight] = useState("");
    const [resizeXCord, setResizeXCord] = useState(0);
    const [resizeCardId, setResizeCardId] = useState(0);

/*
    useEffect(() => {        
        if (resizeElement && resizeEvent && resizeCardId !== 0 && isDraggingTurnOff) {

            const styles =  window.getComputedStyle(resizeElement);
            let width = parseInt(styles.width, 10);
            let xCord = (resizeEvent as MouseEvent).clientX;

            setResizeWidth(width);
            setResizeXCord(xCord);
            setResizeLeft(styles.left);
            setResizeRight("");
            document.addEventListener("mousemove", onMouseMoveRightResize);
            document.addEventListener("mouseup", onMouseUpRightResize);
        }
    }, [resizeElement, resizeEvent, resizeCardId]);

    useEffect(() => {
        let map = new Map(cardWidth);
        if (map) {
            map.set(resizeCardId, `${resizeWidth}px`);
            console.log(`Resized Width: ${resizeWidth}px`);
            setCardWidth(map);
        }
    }, [resizeWidth]);

    const onMouseMoveRightResize = (event: any) =>  {
        if (resizeElement) {
            const dx = event.clientX - resizeXCord;
            let newWidth = resizeWidth + dx;
            setResizeWidth(newWidth);
        }
    };

    const onMouseUpRightResize = () => {
        document.removeEventListener("mousemove", onMouseMoveRightResize);
    };

    const onMouseDownRightResize = (event: any, element: HTMLElement | null, cardId: number) => {
        setResizeEvent(event); 
        setResizeElement(element);
        setResizeCardId(cardId);
    }
*/
    /********************************************************
     * Turn off/on the dragging of the card so we can resize
     ********************************************************/
   /*
    const handleEnableCardResize = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        e.stopPropagation();
        let map = new Map(isDraggingTurnOff);
        console.log(map);
        if (map) {
            let cardSetting = map.get(cardId);
            if (cardSetting) {
                map.set(cardId, !cardSetting);
                
            } else {
                map.set(cardId, true);
            }
            setIsDraggingTurnOff(map);
        }
    }*/







    
    // left
/*
    const onMouseMoveLeftResize = (event: any, element: HTMLElement, width: number, xCord: number) =>  {
        event.preventDefault();
        event.stopPropagation();
        const dx = event.clientX - xCord;
        xCord = event.clientX;
        width = width - dx;
        element.style.width = `${width}px`;
    };

    const onMouseUpLeftResize = (event: any, element: HTMLElement, width: number, xCord: number) => {
        event.preventDefault();
        event.stopPropagation();
        document.removeEventListener("mousemove", onMouseMoveLeftResize.bind(null, event, element, width, xCord));
    };

    const onMouseDownLeftResize = (event: any, element: HTMLElement | null) => {
        event.preventDefault();
        event.stopPropagation();

        if (element) {
            const styles =  window.getComputedStyle(element);
            let width = parseInt(styles.width, 10);
            //console.log("Width: " + width);
            //let xCord = 0;

            let xCord = event.clientX;
            element.style.right = styles.right;
            element.style.left = "";
            document.addEventListener("mousemove", onMouseMoveLeftResize.bind(null, event, element, width, xCord));
            document.addEventListener("mouseup", onMouseUpLeftResize.bind(null, event, element, width, xCord));
        }
    } 
*/
    /*
    useEffect(() => {
        if (projectState.status === 'roadmapDeleted') {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                dispatch(setRoadmaps([]));
                dispatch(setRoadmapCards([]));
            }
        }
    }, [projectState.status]);
    */
   /*
    const toggleLabelContextMenu = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        e.stopPropagation();
        setLabelContextMenuParams({ cardId: cardId, event: e, roadmap: true });   
    };

    const toggleCardContext = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        let container = projectState.containers.find(c => c.loaded);
        if (container) {
            let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
            if (roadmap) {
                setCardContextParams({ projectId: roadmap.id, cardId: cardId, event: e, roadmap: true });   
            }
        }
    };
*/

    const handleEditCard = (e: React.MouseEvent<HTMLDivElement>, index: number, card: RoadmapCard) => {
        e.preventDefault();
        e.stopPropagation();
        if(props.roadmap) {
            setAddRoadmapCardContextMenuParams({ event: e, id: index, roadmap: props.roadmap, mode: "edit", card: card });  
        } 
    }

    const toggleCategoryContextMenu = (e: React.MouseEvent<HTMLDivElement>, roadmapId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setCategoryContextMenuParams({ roadmapId: roadmapId, event: e });   
    };
    /*
    const showSettings = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setRoadmapContextMenuParams({ event: e, id: project.id });   
        }
        
    };
    */

    const showAddRoadmapCard = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
        e.preventDefault();
        if(props.roadmap) {
            setAddRoadmapCardContextMenuParams({ event: e, id: index, roadmap: props.roadmap, mode: "add" });  
        } 
    };
    
    const handleShowTrashCard = (e: React.MouseEvent<HTMLDivElement>, cardId: string) => {
        e.preventDefault();
        e.stopPropagation();
        // Show the delete confirmation window
        setPopupParams({
            _id: cardId,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteCardPopup,
            buttonHandlerWithId: deleteCardConfirmed,
            message: "The roadmap card will be removed. There is no undo.",
            headingText: "Delete Card",
            backButton: false,
        });
    }
    
    const closeDeleteCardPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // Key here is to zero-out label 
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteCardPopup,
            buttonHandlerWithId: deleteCardConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }
    
    const deleteCardConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(deleteRoadmapCard(id));
        let cards = [...roadmapState.cards];
        let removeIndex = cards.findIndex(c => c._id === id);
        if (removeIndex !== -1) {
            cards.splice(removeIndex, 1);
            dispatch(setRoadmapCards(cards));
        }
        closeDeleteCardPopup(e);
    }
    /*
    const handleShowTrashRoadmap = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let container = projectState.containers.find(c => c.loaded);
        if (container) {
            let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
            if (roadmap) {
                // Show the delete confirmation window
                setPopupParams({
                    id: roadmap.id,
                    event: e,
                    buttonClass: "btnDelete",
                    buttonText: "Delete",
                    closeHandler: closeDeleteRoadmapPopup,
                    buttonHandlerWithId: deleteRoadmapConfirmed,
                    message: "The entire roadmap will be removed. There is no undo.",
                    headingText: "Delete Roadmap",
                    backButton: false,
                });
            }
        }
    }

    const closeDeleteRoadmapPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // Key here is to zero-out label
        setPopupParams({
            id: 0,
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteRoadmapPopup,
            buttonHandlerWithId: deleteRoadmapConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }

    const deleteRoadmapConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(deleteRoadmap(id))
        closeDeleteRoadmapPopup(e);
    }
*/
    const handleShowCannotMove = () => {
        // Show the delete confirmation window
        setPopupParams({
            _id: props.roadmap._id,
            event: undefined,
            buttonClass: "",
            buttonText: "",
            message: "Cards can only be moved to empty slots",
            headingText: "Cannot Move Card",
            backButton: false,
            closeHandler: closeCannotMoveCardPopup,
        });
    }

    const closeCannotMoveCardPopup = () => {
        // Key here is to zero-out label
        setPopupParams({
            _id: "",
            event: undefined,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeCannotMoveCardPopup,
            message: "",
            headingText: "",
            backButton: false,
        });
    }
    
    const onDragEnd = (result: any) => {

        let cardId = result.draggableId;
        let newSlot = parseInt(result.destination.droppableId);
        let oldSlot = parseInt(result.source.droppableId);
        let roadmap = (props.roadmap) ? props.roadmap : undefined;
        
        //console.log("Source Card ID: " + cardId);
        console.log("Old Slot: " + oldSlot);       
        console.log("New Slot: " + newSlot);

        if (newSlot !== oldSlot) {

            if (roadmap) {
                let cardInWay: RoadmapCard | undefined = undefined;
                let sourceCard = roadmapState.cards.find(c => c._id === cardId);
                let destinationCard = roadmapState.cards.find(c => newSlot >= c.index && newSlot <= c.index + c.periodsToSpan - 1);
                //console.log(sourceCard);
                //console.log(destinationCard);
                if (sourceCard) {
                    //console.log(sourceCard);
                    //console.log(destinationCard);
                    let notEnoughRoom = false;
                    let endOfRange = false;
                    let saveSlotEnd = 0;
                    // if no destination card, check that our card will fit in the new slot(s)
                    if (!destinationCard || (destinationCard && destinationCard === sourceCard)) {
                        let rangeStart = newSlot;
                        let rangeEnd = newSlot + ((sourceCard.periodsToSpan > 1) ? sourceCard.periodsToSpan - 1 : 0);
                        let rowToCheck = (newSlot % 12 === 0) ? Math.ceil(newSlot / 12) + 1 :  Math.ceil(newSlot / 12);
                        let slotEnd =  rowToCheck * 12 - 1;
                        let slotStart = slotEnd - 12 + 1;
                        
                        if (rangeEnd > slotEnd) {
                            // we tried to drag a card to the last period and it spans more than the
                            // periods in the roadmap.
                            endOfRange = true;
                            saveSlotEnd = slotEnd;
                        } else {
                            for(let slot=slotStart;slot<=slotEnd;slot++) {
                                let cardInSlot = roadmapState.cards.find(c => c.index === slot && c.index !== oldSlot);
                                if (cardInSlot) {
                                    let cardInSlotStart = cardInSlot.index;
                                    let cardInSlotEnd = cardInSlotStart + ((cardInSlot.periodsToSpan > 1) ? cardInSlot.periodsToSpan - 1 : 0);
                                    for (slot=cardInSlotStart;slot<=cardInSlotEnd;slot++){
                                        if(slot >= rangeStart && slot <= rangeEnd) {
                                            cardInWay = cardInSlot;
                                            notEnoughRoom = true;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        notEnoughRoom = true;
                    }
                    if (notEnoughRoom && !cardInWay) {
                        //handleShowCannotMove();
                    } else {
                        // we have to update state here instead of in the reducer from
                        // the backend so we don't get a jerky, delayed update in the UI
                        let card = {...sourceCard};
                        card.index = newSlot;
                        card.startPeriod = newSlot - (Math.floor(newSlot / 12) * 12);
                        if (endOfRange) {
                            card.periodsToSpan = saveSlotEnd - card.index + 1;
                            console.log("Start Period: " + card.startPeriod);
                            console.log("Periods to Span: " + card.periodsToSpan);
                        } else if (notEnoughRoom) {
                            if (cardInWay) {
                                card.periodsToSpan = cardInWay.startPeriod - card.startPeriod;
                            }
                        }
                        let roadmapCards = [...roadmapState.cards];
                        let roadmapCardExists = roadmapState.cards.findIndex(c => c._id === card._id);
                        if (roadmapCardExists !== -1) {
                            roadmapCards[roadmapCardExists] = card;
                        }
                        dispatch(setRoadmapCards(roadmapCards));
                        dispatch(saveRoadmapCard(card));
                    }
                }
            }      
        }       
    } // onDragEnd()

    const getQuarterLabels = (columns: number, roadmap: Roadmap): Array<string> => {
        let columnLabels = [];
        let today = new Date(roadmap.created);
        let currentQuarter = getQuarter(today);
        for(let c=0; c<columns; c++) {
            if (c === 0) {
                columnLabels[c] = "Q" + currentQuarter;
            } else {
                let nextQuarter = currentQuarter + c;
                if (nextQuarter <= 4) {
                    columnLabels[c] = "Q" + nextQuarter;
                } else {
                    if (nextQuarter - 4 <= 4) {
                        columnLabels[c] = "Q" + (nextQuarter - 4);
                    } else if (nextQuarter - 4 <= 8) {
                            columnLabels[c] = "Q" + (nextQuarter - 8);
                    } else {
                        columnLabels[c] = "Q" + (nextQuarter - 12);
                    }
                }
            }
        }
        return columnLabels;
    } 

    const getMonthLabels = (columns: number, roadmap: Roadmap): Array<string> => {
        let columnLabels = [];
        //let currentMonth = getMonth(new Date) + 1;
        let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        //let currentMonth = roadmap.firstQuarterMonth;

        let currentMonth = getMonth(startOfQuarter(new Date(roadmap.created))) + 1;
        //let currentMonth = getMonth(new Date(roadmap.created));
        //console.log("Quarter Start: " + quarterStart);
        //console.log("Current Month: " + currentMonth);
        

        for(let c=0; c<columns; c++) {
            if (c === 0) {
                columnLabels[c] = months[currentMonth-1];
            } else {
                let nextMonth = currentMonth + c;
                if (nextMonth <= 12) {
                    columnLabels[c] = months[nextMonth-1];
                } else {
                    if (nextMonth - 12 <= 12) {
                        columnLabels[c] = months[(nextMonth - 12)-1];
                    } else if (nextMonth - 12 <= 24) {
                            columnLabels[c] = months[(nextMonth - 24)-1];
                    } else {
                        columnLabels[c] = months[(nextMonth - 36)-1];
                    }
                }
            }
        }
        return columnLabels;
    }

    const getWeekLabels = (columns: number, roadmap: Roadmap): Array<string> => {
        let columnLabels = [];
        /*
        To determine whether the current year has 52 or 53 weeks, we can use the following logic:
            A year has 53 weeks if it starts on a Thursday or if it’s a leap year and starts on a Wednesday.
            Otherwise, it will have 52 weeks.
        
        //const currentDate = new Date();
        const currentDate = new Date(roadmap.created);

        // Get the last day of the year
        let lastDayOfYear = endOfYear(currentDate);
        // Get the ISO week number of the last day of the year
        let totalWeeks = getISOWeek(lastDayOfYear);
        if (totalWeeks !== 53) {
            totalWeeks = 52; // it will = 1 when not 53 weeks because it rolls into 1st week of Jan
        }

        const currentWeek = getISOWeek(currentDate);

        for(let c=0; c<columns; c++) {
            if (c === 0) {
                columnLabels[c] = "W" + currentWeek;
            } else {
                let nextWeek = currentWeek + c;
                if (nextWeek <= totalWeeks) {
                    columnLabels[c] = "W" + nextWeek;
                } else {
                    if (nextWeek - totalWeeks <= totalWeeks) {
                        columnLabels[c] = "W" + (nextWeek - totalWeeks);
                        } else if (nextWeek - totalWeeks <= totalWeeks * 2) {
                            columnLabels[c] = "W" + (nextWeek - totalWeeks * 2);
                    } else {
                        columnLabels[c] = "W" + (nextWeek - totalWeeks * 3);
                    }
                }
            }
        }
        return columnLabels;
        */
        
        //const currentDate = new Date();
        let nextDate = new Date(roadmap.created);
        const dayCode = getDay(nextDate); // 0 for Sunday, 1 for Monday, etc.
        nextDate = addDays(nextDate, 6 - dayCode); /* make week ending */

        for(let c=0; c<columns; c++) {
            columnLabels[c] = format(nextDate, "L/d");
            nextDate = addDays(nextDate, 7);
        }
        return columnLabels;
    }

    const getDayLabels = (columns: number, roadmap: Roadmap): Array<string> => {
        let columnLabels = [];
        // Get the current year
        //let currentDate = new Date();
        //let currentYear = new Date().getFullYear();
        
        let nextDate = new Date(roadmap.created); 
                       /*
        let currentDate = new Date(roadmap.created);
        let currentYear = currentDate.getFullYear();

        // Determine the number of days in the year

        let daysInYear = ((currentYear % 4 === 0 && currentYear % 100 !== 0) || (currentYear % 400 === 0)) ? 366 : 365;
        let currentDay = getDayOfYear(currentDate);

        for(let c=0; c<columns; c++) {
            if (c === 0) {
                columnLabels[c] = "D" + currentDay;
            } else {
                let nextDay = currentDay + c;
                if (nextDay <= daysInYear) {
                    columnLabels[c] = "D" + nextDay;
                } else {
                    if (nextDay - daysInYear <= daysInYear) {
                        columnLabels[c] = "D" + (nextDay - daysInYear);
                        } else if (nextDay - daysInYear <= daysInYear * 2) {
                            columnLabels[c] = "D" + (nextDay - daysInYear * 2);
                    } else {
                        columnLabels[c] = "D" + (nextDay - daysInYear * 3);
                    }
                }
            }
        }
        */
        for(let c=0; c<columns; c++) {
            columnLabels[c] = format(nextDate, "iiiiii");
            nextDate = addDays(nextDate, 1);
        }

        return columnLabels;
    }

    /*************
     * Hack function to hide placeholder so when we drag over cards and drop, 
     * it doesn't try to reorder the cards not being dragged, causing UI issues
     */
    function getStyle(style: any, snapshot: any) {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }
        
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const handleLoadProject = (e: React.MouseEvent<HTMLDivElement>, id: string | undefined) => {
        e.preventDefault();
        let projectId = (id === undefined) ? "" : id;
        //dispatch(loadProject(projectId));
        /* If project is already loaded, just switch to project screen, else load the project first */
        let project = projectState.projects.find(p => p._id === projectId);
        if (project && project.loaded) {
            handleLoadComponent(dispatch, "work", settingsState.settings);
        } else {
            // old.getFolders() dispatch(getFolders());
            dispatch(loadProject(projectId));
        }

    }

/*
    let slot = 0;
    function incrementSlot() {
        slot += 1;
    }
*/
    //console.log("Dragging disabled? " + isDraggingTurnOff);

    let roadmap: Roadmap | undefined = props.roadmap;
    let categories = roadmapState.categories.filter(c => c.roadmapId === props.roadmap._id);
    let columns = 12;
    let columnLabels: Array<string> = [];
    let columnSubLabels: Array<string> = [];
    let itemAdjustment = 1;

    if (roadmap) {
        //columns = roadmap.uomNumber;
        if (roadmap.uom === "quarterly") {
            //let today = new Date();
            columnLabels = getQuarterLabels(4, roadmap);
            columnSubLabels = getMonthLabels(12, roadmap);
            //itemAdjustment = 3;
            //columnLabels = getQuarterLabels(columns, roadmap);
            //columnSubLabels = getMonthLabels(columns * itemAdjustment, roadmap);
        } /* else if (roadmap.uom === "monthly") {
            itemAdjustment = 4;
            columnLabels = getMonthLabels(columns, roadmap);
            columnSubLabels = getWeekLabels(columns * itemAdjustment, roadmap);
        } else if (roadmap.uom === "weekly") {
            itemAdjustment = 7;
            columnLabels = getWeekLabels(columns, roadmap);
            columnSubLabels = getDayLabels(columns * itemAdjustment, roadmap);
        } else if (roadmap.uom === "daily") {
            columnLabels = getDayLabels(columns, roadmap);
        }*/
    }
    //}

    let columnWidth = 100 / columns;
    let columnStyle = {
        width: "25%",
        //width: columnWidth + "%",
        display: "inline-block",
    }
    // onClick={(e) => showAddRoadmap(e, index + 1)}
    let formattedColumns = columnLabels.map((label, index) => {
        if (roadmap) {
            return (
                <div key={"column-label-"+index} style={columnStyle}>
                    {label}
                    {roadmap.roadmap &&
                        <div className="ms-2" style={{ display: "inline-block", cursor: "pointer", position: "relative", top: "-1px" }} 
                            onClick={(e) => showAddRoadmapCard(e, index + 1)}>
                            <MdAdd size="22" />
                        </div>
                    }
                </div>
            );
        }
    });

    let subColumnWidth = 100 / columnSubLabels.length;
    let subColumnStyle = {
        width: subColumnWidth + "%",
        display: "inline-block",
    }

    let formattedSubColumns = columnSubLabels.map((label, index) => {
        return (
            <div key={"column-sublabel-"+index} style={subColumnStyle}>
                {label}
            </div>
        );
    });

    let formattedRows: any = [];
    let formattedRowsAllDropzones: any = [];
    let formattedCateogies: any = [];
    let cardsWithNoCategory: boolean = false;

    if(roadmap) {

        let highestIndex = roadmapState.cards.filter(c => c.roadmapId === roadmap!._id).sort((a, b) => b.index - a.index);
   //console.log(highestIndex);
        //let rows = (highestIndex.length > 0) ? Array.from({ length: Math.ceil(highestIndex[0].index / roadmap!.uomNumber) + 1 }) : [];
        let rowCount = (highestIndex.length > 0) ? (Math.ceil(highestIndex[0].index % 12) === 0) ? Math.ceil(highestIndex[0].index / 12) + 2 : Math.ceil(highestIndex[0].index / 12) + 1 : 0;
        let rows = (highestIndex.length > 0) ? Array.from({ length: rowCount }) : [];
        let slot = 0;
        formattedRows = rows.map((r,i) => {
            let columns = [];
            let skipUntil = -1;
            let runningWidth = 0;
            //for(let col=0; col<roadmap!.uomNumber;col++){
            for(let col=0; col<12;col++){
                let card = roadmapState.cards.find(c => c.roadmapId === roadmap!._id && c.index === slot);
                //let thisCardWidth = "100%";
                let id = "item" + i + col;

                let style: CSSProperties = {
                    display: "inline-block",
                    height: "34px",
                    //margin: "20px 0px",
                    fontSize: "11pt",
                    width: (card) ? (columnWidth * card.periodsToSpan) + "%" : columnWidth + "%",
                    textAlign: "left",
                    //border: "1px solid red",
                    position: "relative",
                };

                let styleInner: CSSProperties = {
                    display: "inline-block",
                    fontSize: "11pt",
                    height: "30px",
                    width: "100%",
                    color: "#000",
                    backgroundColor: (card) ? "#ebeaea" : "#fff",
                    //border: "1px solid #fff",
                    borderRadius: "30px", 
                    //position: "relative",
                    //zIndex: "9999999",
                };

                //let adjustedCardWidth = "100%";

                if (card) {
                    if (cardWidth) {
                        let storedWidth = cardWidth.get(card!._id);
                        if (storedWidth !== undefined) {
                            style.display = "flex";
                            style.justifyContent = "center";
                            style.position = "relative";
                            style.width = storedWidth;
                            style.left = resizeLeft;
                            style.right = resizeRight;
                        }
                        
                        //styleCardNotHover.width = `${cardWidth.get(card!.id)} !important`;
                    }
                    let cardCat = roadmapState.categories.find(c => c._id === card!.categoryId);
                    if (cardCat) {
                        styleInner.color = cardCat.fontColor;
                        styleInner.backgroundColor = cardCat.backgroundColor;
                    }
                    //console.log(settingsState.settings.searchText);
                    /* Handle filtering items based on search */
                    if (settingsState.settings.searchText &&
                        settingsState.settings.searchText.toLowerCase() !== "" && 
                        !card.title.toLowerCase().includes(settingsState.settings.searchText.toLowerCase())) {
                        styleInner.color = "#000";
                        styleInner.backgroundColor = "#000";
                    }
                }

                if (skipUntil === -1 || col >= skipUntil) {
                    skipUntil = -1;
                    if (card) {
                        /*if (card.index === 11) {
                            console.log("skipUntil: " + skipUntil + " col: " + col);
                        }*/
                        cardsWithNoCategory = (card.categoryId === undefined || card.categoryId === "") ? true : cardsWithNoCategory;
                        if (slot === card.index) {
                            /*
                            let cardOwner = projectState.projectMembers.find(pm => pm.userId === card!.ownerId);
                            let labels = projectState.roadmapLabelsToCards.filter(lc => lc.cardId === card!.id); 
                            if (labels.length > 0) {
                                styleInner.color = projectState.roadmapLabels.find(l => l.id === labels[0].labelId)?.fontColor;
                                styleInner.backgroundColor = projectState.roadmapLabels.find(l => l.id === labels[0].labelId)?.backgroundColor;
                            }
                            */
                            let styleProgressOverlay: CSSProperties = {
                                position: "absolute",
                                top: "1px",
                                left: "2px",
                                //backgroundColor: "gray",
                                //width: "50%",
                                height: "30px",
                                borderRadius: "30px",
                                background: "rgba(0, 0, 0, .2)",
                                //opacity: ".8",
                                //zIndex: 1
                            }
                            
                            let showProgress = false;
                            //let progress = 0;
                            //let estimatedWork = 0;
                            //let actualWork = 0;
                            //let attachedProject = projectState.projects.find(p => p.roadmapId )
/*
                            projectState.cards.forEach(c => {
                                if (c.roadmapItem === card!.id) {
                                    actualWork += c.actualWork;
                                    estimatedWork += c.estimatedWork;
                                }
                            });*/
                            //console.log("Card Name: " + card.title);
                            //console.log("Actual Work: " + actualWork);
                            //console.log("Estimated Work: " + estimatedWork);

                            if (card!.progress > 0) {
                                showProgress = true;
                                //progress = actualWork / estimatedWork * 100;
                                //if (progress >= 100) { progress = 99 }
                                styleProgressOverlay.width = card!.progress + "%";
                            }
                            
                            //let subMenuForCard: any = undefined;
                            //let subMenuExtend: any = undefined;
                            //let subMenuShrink: any = undefined;
                            //let slotRow = (card!.index % roadmap!.uomNumber === 0) ? Math.ceil(card!.index / roadmap!.uomNumber) + 1 : Math.ceil(card!.index / roadmap!.uomNumber);
                            //let lastSlotInRow = slotRow * roadmap!.uomNumber - 1;

                            let slotRow = (card!.index % 12 === 0) ? Math.ceil(card!.index / 12) + 1 : Math.ceil(card!.index / 12);
                            let lastSlotInRow = slotRow * roadmap!.uomNumber - 1;

                            //let firstSlotInRow = lastSlotInRow - roadmap!.uomNumber + 1;
                            /*
                            subMenuExtend = (
                                <div style={{ display: "inline-block" }}>
                                    Extend:&nbsp;
                                    {card!.index !== firstSlotInRow &&
                                       <span style={{ textDecoration: "underline", cursor: "pointer" }}>Left</span>
                                    }
                                    {card!.index !== firstSlotInRow && card!.index !== lastSlotInRow &&
                                        <>&nbsp;|&nbsp;</>
                                    }
                                    {card!.index !== lastSlotInRow &&
                                        <span style={{ textDecoration: "underline", cursor: "pointer" }}>Right</span>
                                    }
                                </div>
                            );
                            
                            if (card!.periodsToSpan > 1) {
                                subMenuShrink = (                          
                                    <div style={{ display: "inline-block" }}>
                                        &nbsp;&nbsp;Shrink:&nbsp;
                                        <span style={{ textDecoration: "underline", cursor: "pointer" }}>Left</span>&nbsp;|&nbsp;
                                        <span style={{ textDecoration: "underline", cursor: "pointer" }}>Right</span>
                                    </div>
                                );
                            }

                            subMenuForCard = (
                                <div className="roadmapSubMenu">
                                    {subMenuExtend}
                                    {subMenuShrink}
                                </div>
                            );

                            */
                           //onClick={(e) => toggleCardContext(e, card!.id)}

                            let draggingDisabled = false;
                            let colorOverride: CSSProperties = {};
                            let map = isDraggingTurnOff;
                            if (map) {
                                let setting = map.get(card!._id);
                                if (setting) {
                                    draggingDisabled = setting;
                                    colorOverride = { backgroundColor: "#c44f01" };
                                }
                            }

                            let roadmapCardProject = projectState.projects.find(p => p.roadmapId === roadmap?._id && p.categoryId === card!.categoryId);

                            if (roadmap && !roadmap.roadmap) {
                                columns[col] = (
                                    <div style={style}>                      
                                        <div> 
                                            <div style={styleInner}>
 
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {

                                columns[col] = (
                                <Droppable key={"column-"+col} droppableId={card!.index.toString()} type="group">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} style={style}>                      
                                            <Draggable key={card!._id} draggableId={card!._id} index={card!.index} isDragDisabled={draggingDisabled}>
                                                {(provided, snapshot) => (
                                                <div
                                                    className="roadmapCardNotHover"
                                                    onClick={(e) => handleLoadProject(e, roadmapCardProject?._id)}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getStyle({...provided.draggableProps}.style, snapshot)}
                                                > 
                                                    <div style={styleInner}>
                                                        {showProgress &&
                                                            <div style={styleProgressOverlay}>
                                                                &nbsp;
                                                            </div>
                                                        }
                                                        <div style={{ 
                                                            zIndex: "-1",
                                                            padding: "3px 12.5px 4px 12.5px",
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap", 
                                                        }}>
                                                            {/*
                                                            <Avatar size="xxsmall" projectMember={cardOwner} inline={true} owner={false} />
                                                            */}
                                                            {/*
                                                            <span style={{ fontWeight: "bold", fontSize: "11pt" }}>
                                                                {"Slot: " + card!.index.toString() + " "}
                                                            </span>
                                                            */}
                                                            <span>
                                                                {card!.title}
                                                                {/*
                                                                {" [Index: " + card!.index }
                                                                {" | Slot Row: " + slotRow }
                                                                {" | 1st Slot: " + firstSlotInRow}
                                                                {" | Last Slot: " + lastSlotInRow + "]"}
                                                                */}
                                                            </span>
                                                            
                                                            {/*
                                                            <div className="roadmapCardIcon3" data-tooltip-id={id} data-tooltip-content={card!.title}>
                                                                <span style={{ position: "relative", top: "-2px" }}>
                                                                    <BsQuestionLg size="14" color="#fff" />
                                                                </span>
                                                            </div>
                                                            */}
                                                            {/* onClick={(e) => toggleLabelContextMenu(e, card!.id)} */}
                                                            {/*
                                                            <div className="roadmapCardIcon2" >
                                                                <span style={{ position: "relative", top: "-2px", left: "1px" }}>
                                                                    <MdLabel size="14" color="#fff" />
                                                                </span>
                                                            </div>
                                                            */}
                                                            
                                                            <div className="roadmapCardIcon2"
                                                                onClick={e => handleShowTrashCard(e, card!._id)}>
                                                                <span style={{ position: "relative", top: "-2px" }}>    
                                                                    <FaRegTrashAlt size="11" color="#fff" />
                                                                </span>
                                                            </div>
                                                            {/*
                                                            <div className="roadmapCardIcon2"
                                                                style={colorOverride}
                                                                onClick={e => handleEnableCardResize(e, card!.id)}>
                                                                <span style={{ position: "relative", top: "-1px" }}>    
                                                                    <IoIosResize size="12" color="#fff" />
                                                                </span>
                                                            </div>
                                                            */}
                                                            <div className="roadmapCardIcon"
                                                                onClick={e => handleEditCard(e, card!.index, card!)}>
                                                                <span style={{ position: "relative", top: "-1px" }}>    
                                                                    <GoPencil size="12" color="#fff" />
                                                                </span>
                                                            </div>
                                                            {/*
                                                            {draggingDisabled &&
                                                            <>
                                                                <div className="resizer rs" onMouseDown={(e) => onMouseDownLeftResize(e, e.currentTarget.parentElement)}></div>
                                                                <div className="resizer re" onMouseDown={(e) => onMouseDownRightResize(e, e.currentTarget.parentElement, card!.id)}></div>
                                                            </>
                                                            }
                                                            */}
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <Tooltip id={id} /> 
                                                    {subMenuForCard}
                                                    */}
                                                </div>
                                            )} 
                                            </Draggable>
                                            <span style={{ display: "none" }}>
                                                {provided.placeholder}
                                            </span>
                                        </div>
                                        )}
                                    </Droppable>
                                );
                            }
                        }
                    } else {
                        /*
                        let dragIndex = 0;
                        if (i === 0) {
                            dragIndex = col;
                        } else {
                            if (col === 0) {
                                dragIndex = roadmap!.uomNumber * i; 
                            } else {
                                dragIndex = roadmap!.uomNumber * i + col;
                            }
                        }
                        */
                        let tempSlot = slot;
                        columns[col] = (
                            <Droppable key={"column-"+col} droppableId={slot.toString()} type="group">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} style={{...style, borderRadius: "30px", backgroundColor: snapshot.isDraggingOver ? '#f7dc6f' : '#fff' }}>
                                        <div style={{ ...styleInner, ...{ backgroundColor: snapshot.isDraggingOver ? '#f7dc6f' : '#fff' } }}>
                                            {/*
                                            <span style={{ fontWeight: "bold", fontSize: "11pt" }}>
                                                {"Slot: " + tempSlot.toString() + " "}
                                            </span>
                                            */}
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        );
                    }
                } else {
                    /* Invisible slot behind a multi-slot card, so we can drag cards to the
                       right by one month, two months, etc and start the card in a different
                       month.
                    */
                   // if (card) {
                    let tempSlot = slot;
                    let tempRunningSlotWidth = runningWidth;
                    let destinationCard = roadmapState.cards.find(c => slot >= c.index && slot <= c.index + c.periodsToSpan - 1);
                    //console.log(destinationCard);
                    if (destinationCard) {
                        columns[col] = (
                            <Droppable key={"column-"+col} droppableId={slot.toString()} type="group">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} style={{...style, borderRadius: "30px", position: "absolute", left: tempRunningSlotWidth + "%", zIndex: snapshot.isDraggingOver && snapshot.draggingOverWith === destinationCard!._id ? "1" : "-1", backgroundColor: snapshot.isDraggingOver && snapshot.draggingOverWith === destinationCard!._id ? '#f7dc6f' : '#fff' }}>
                                        <div style={{ ...styleInner, ...{ backgroundColor: snapshot.isDraggingOver && snapshot.draggingOverWith === destinationCard!._id ? '#f7dc6f' : '#fff' } }}>
                                            {/*
                                            <span style={{ fontWeight: "bold", fontSize: "11pt" }}>
                                                {"Slot: " + tempSlot.toString() + " "}
                                            </span>
                                            */}
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        );
                    }
                }
                if (card) {
                    if (card.periodsToSpan > 1) {
                        skipUntil = col + card.periodsToSpan;
                    //} else if (card.periodsToSpan === 1) {
                    //    skipUntil = col;
                    }
                }
                //incrementSlot();
                slot++;
                runningWidth += columnWidth;
            }
            //<div key={i} className="d-flex align-items-center justify-content-center"
            return (
                <div key={i} className="d-flex"
                    style={{ width: "100%" }}>
                    {columns}
                </div>
            );
        });
    }

    

    
    /*
    let formattedRoadmapLabels: any = undefined;
    if (roadmap) {
        let roadmapLabels = projectState.roadmapLabels.filter(l => l.roadmapId === roadmap!.id);
        formattedRoadmapLabels = roadmapLabels.map((l,i) => {
            return (
                <div style={{ display: "inline-block", padding: "5px 20px", borderRadius: "30px", marginRight: "3px",
                    color: l.fontColor, backgroundColor: l.backgroundColor}}>
                    {l.title}
                </div>
            );
        });
    }
        */


    let catStyle: CSSProperties = {
        cursor: "pointer",
        display: "inline-block",
        borderRadius: "30px",
        fontSize: "11pt",
        //height: "32px",
        margin: "10px 1px 10px 1px",
        padding: "3px 20px",
        backgroundColor: "#efefef",
    };

    let formattedCategories = categories.map((c,i) => {
        let tempStyle = {...catStyle};
        tempStyle.color = c.fontColor;
        tempStyle.backgroundColor = c.backgroundColor;
        let roadmapCardProject = projectState.projects.find(p => p.roadmapId === roadmap?._id && p.categoryId === c._id);
        return (
            <div key={"category-"+i} style={tempStyle} onClick={(e) => handleLoadProject(e, roadmapCardProject?._id)}>
                {c.title}
            </div>
        )
    });

   // if (cardsWithNoCategory) {
       /* let catStyle: CSSProperties = {
            display: "inline-block",
            borderRadius: "30px",
            fontSize: "11pt",
            //height: "32px",
            margin: "0px 1px 5px 1px",
            padding: "5px 10px",
            backgroundColor: "#efefef",
        };*/
        // onClick={(e) => toggleCategoryContextMenu(e, props.roadmap._id )}
        let roadmapCardProject = projectState.projects.find(p => p.roadmapId === roadmap?._id && p.categoryId === "");
        let noCategoryElement = (
            <div key = "cat-uncategorized" style={catStyle} onClick={(e) => handleLoadProject(e, roadmapCardProject?._id)}>
                Uncategorized
            </div>
        );
        formattedCategories.splice(0,0, noCategoryElement);
    //}

    let categoryStyle: CSSProperties = {
        display: "inline-block",
        textAlign: "left",
        width: "15%",
        // thought is 385px account for top part that doesn't scroll
        height: outerHeight - 385,
        overflowY: "scroll",
        //border: "1px solid red"
    }

    let roadmapStyle: CSSProperties = {
        position: "relative",
        //display: "inline-block",
        textAlign: "center",
        // thought is 385px account for top part that doesn't scroll
        height: outerHeight - 385,
        overflowY: "scroll",
        //border: "1px solid red"
    }

    let styleCategoryGap: CSSProperties = {
        width: "1%",
        display: "inline-block"
    }
    
    let styleMainMap: CSSProperties = {
        width: "100%"
    }
/*
    let styleMainMap: CSSProperties = {
        width: "84%",
        display: "inline-block"
    }

    if (categories.length === 0) {
        styleMainMap.width = "100%";
        styleMainMap.display = "block";
    }
*/
    return (
        <div className="rightContentDefault" style={{ marginTop: "-5px" }}>
            {/*
            <div>
                <div className="ms-2 iconMainRoadmapAdd" 
                    onClick={(e) => showSettings(e)}>  
                    <div style={{ position: "relative", top: "-2px" }}>
                        <MdAdd size="24" color="#9e9d9d" />
                    </div>
                </div>
                {roadmap && 
                    <div className="ms-2 iconMainRoadmapTrash" 
                        onClick={(e) => handleShowTrashRoadmap(e)}>
                        <div style={{ position: "relative", left: "3px", top: "-3px" }}>
                            <FaRegTrashAlt size="19" color="#9e9d9d" />
                        </div>
                    </div>
                }
            </div>
*/} 
            {/*
            {!roadmap &&
                <div style={{ padding: "100px", textAlign: "center" }}>
                    <div>
                        <img src='images/herdr-logo-cat-only.png' style={{ width: "60px", marginBottom: "10px" }} />
                    </div>
                    <h2>Build a roadmap</h2>
                    <div>
                        Get started by clicking the <MdAdd size="24" color="gray" /> button.
                    </div>
                </div> 
            }
                */}

                    {/*
                        <>
                        <div style={categoryStyle}>
                            <DragDropContext onDragEnd={(e) => onCategoryDragEnd(e)}>
                                {formattedCategories}  
                            </DragDropContext>
                        </div>
                        <div style={styleCategoryGap}></div>
                        </>
                    */}

            {roadmap &&
            <>
                {roadmap.roadmap &&
                    <>
                        <div style={{
                            display: "inline-flex", backgroundColor: "#efefef",
                            alignItems: "center", justifyContent: "center", top: "3px",
                            borderRadius: "50%", padding: "2px", height: "28px", width: "28px",
                            position: "relative", textAlign: "center", cursor: "pointer"
                        }}
                            onClick={(e) => toggleCategoryContextMenu(e, props.roadmap._id )}>
                            <AiOutlinePlus size={16} />
                        </div>
                        {formattedCategories}    
                    </>
                }

                <div style={styleMainMap}>
                    {/*
                    <div style={{ paddingBottom: "5px" }}>
                        {formattedRoadmapLabels}
                    </div>
                    */}
                    {/*
                    <div style={{ marginBottom: "7px", marginTop: "2px", borderRadius: "30px", backgroundColor: "#f6f6f6", padding: "5px", fontWeight: "bold", textAlign: "center" }}>
                        {roadmap.name}
                    </div>
                    */}    
                    {roadmap && !roadmap.roadmap &&
                        <div style={{ marginBottom: "10px", borderRadius: "30px", backgroundColor: "#f4d03f", padding: "3px", textAlign: "center" }}>
                            This work was shared with you, but without roadmap access, you're seeing a limited version.
                        </div>
                    }

                    <div style={{ backgroundColor: "#02152a", color: "#F8F9FA", borderRadius: "30px", padding: "3px", textAlign: "center" }}>
                        {formattedColumns}    
                    </div>

                    <div style={{ borderRadius: "30px", fontSize: "10pt", padding: "5px", fontWeight: "500", textAlign: "center" }}>
                        {formattedSubColumns}    
                    </div>
                
                    <div style={roadmapStyle}>
                        <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                            {formattedRows}
                        </DragDropContext>
                    </div>
                </div>
            </>
            }
            <RoadmapCategoryContextMenu params={categoryContextMenuParams} />
            <AddRoadmapCardContextMenu params={addRoadmapCardContextMenuParams} />
            <PopupContextMenu params={popupParams} />
            {/*
            <RoadmapContextMenu params={roadmapContextMenuParams} />
            <CardContextMenu params={cardContextParams} />
            <LabelContextMenu params={labelContextMenuParams} />
            */}
        </div>
    );
}

export default RoadmapMain;