import React, { useEffect, useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
import { SharingContextParam } from'../Models/Requests/SharingContextParam';
import { AiOutlineClose } from "react-icons/ai";
import { getProject } from "../Store/Project";
import { getRoadmap, shareViaEmail, saveMember, setMembers } from '../Store/Roadmap';
import { Member } from '../Models/Requests/Member';
import { userType } from '../Helpers/types';

/*import { getProject, getProjects, saveSharing, deleteSharing,
    deleteContainerSharing, 
    shareViaEmail, getProjectMembers, removeMemberFromProject,
    changeProjectMemberPermission, saveContainerSharing, getContainerMembers,
    getContainers, shareContainerViaEmail, removeMemberFromContainer,
    changeContainerMemberPermission
} from "../Store/Project"; */

import { IconContext } from "react-icons";
import { FiLink } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { shareType } from '../Helpers/types';
import { isValidEmail } from "../Helpers/isValidEmail";
import Avatar from "../Components/Avatar";

interface Props {
    params: SharingContextParam | undefined;    
}

function SharingContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const [sharingContext, setSharingContext] = useState<ContextMenu>();
    /* top email share dropdown */
    const [dropDownClass, setDropDownClass] = useState<string>("dropDown");
    const [showingDropDown, setShowingDropDown] = useState<boolean>(false);



    const [selectedOption, setSelectedOption] = useState<string>("Member");
    /* share via link dropdown */
    const [shareLinkDropDownClass, setShareLinkDropDownClass] = useState<string>("dropDown");
    const [showingShareLinkDropDown, setShareLinkShowingDropDown] = useState<boolean>(false);
    /* members lists */
    const [showingMemberDropDown, setShowingMemberDropDown] = useState<Array<boolean>>([]);
    //const [shareType, setShareType] = useState<string>("board");
    //const [shareLinkSelectedOption, setSelectedShareLinkOption] = useState<string>("Member");
    /* action messages */
    const [showingLinkCopied, setShowingLinkCopied] = useState<boolean>(false);
    const [showingLinkCreated, setShowingLinkCreated] = useState<boolean>(false);
    const [showingLinkDeleted, setShowingLinkDeleted] = useState<boolean>(false);
    const [showingInviteSent, setShowingInviteSent] = useState<boolean>(false);
    const [showingEmailInviteBox, setShowingEmailInviteBox] = useState<boolean>(false);
    const [showingEmailInviteBoxEmail, setShowingEmailInviteBoxEmail] = useState<boolean>(false);
    const [emailInviteBoxValue, setEmailInviteBoxValue] = useState<string>("");
    const [showingEmailShareDescription, setShowingEmailShareDescription] = useState<boolean>(false);
    //const [showingSelectedEmailsBox, setShowingSelectedEmailsBox] = useState<boolean>(false);
    const [emailShareDescription, setEmailShareDescription] = useState<string>("");
    const [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
    const [removedMyself, setRemovedMyself] = useState<boolean>(false);
    const [chkHideBoards, setChkHideBoards] = useState<boolean>(true);
    const [chkHideFutureBoards, setChkHideFutureBoards] = useState<boolean>(true);
    //const [chkBoards, setChkBoards] = useState<Array<boolean>>([]);
    const [chkBoards, setChkBoards] = useState<Map<string, boolean>>();
    const [chkRoadmap, setChkRoadmap] = useState<boolean>(true);
    const [editingMember, setEditingMember] = useState<Member | undefined>();
  
    useEffect(() => {
        if (props.params) {
            
            if (props.params && props.params.email !== "") {
                let member = roadmapState.members.find(m => m.email === props.params!.email);
                if (member) {
                    setEditingMember(member);
                    if (member.memberType) {
                        setChkHideBoards(false);
                        setChkRoadmap(member.roadmap);
                        console.log(member.futureBoards);
                        setChkHideFutureBoards(member.futureBoards);
                        setSelectedEmails([props.params.email]);
                        setSelectedOption(member.memberType.charAt(0).toUpperCase() + member.memberType.slice(1));
                        /* If any projects are in the member, then show the boards */
                        if (member.projectIds.length > 0) {
                            const boardMap = new Map();
                            member.projectIds.map(projectId => {
                                boardMap.set(projectId, true);
                                console.log(projectId);
                            });
                            setChkBoards(boardMap);
                        }
                    }
                }
            }

            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((sharingContext && sharingContext._id !== props.params._id) || !sharingContext) {
                let menuWidth = window.outerWidth * .96;
                let menuXStart = window.outerWidth * .10;
                let menuYStart = 20;
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                contextMenu._id = props.params._id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
            }
            setSharingContext(contextMenu);
        }
    }, [props.params]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        resetState();
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setSharingContext(contextMenu);
    }

    const resetState = () => {
        dehighlightDropdown();
        setEmailInviteBoxValue("");
        setSelectedOption("Member");
        setShowingEmailInviteBox(false);
        setShowingEmailInviteBoxEmail(false);
        setShowingEmailShareDescription(false);
        setSelectedEmails([]);
        setChkBoards(new Map());
        setChkRoadmap(true);
        setChkHideBoards(true);
    }

    const toggleDropDownHighlight = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(!showingDropDown) {
            highlightDropdown();
        } else {
            dehighlightDropdown();
        }
    }

    const highlightDropdown = () => {
        setDropDownClass("dropDownSelected");
        setShowingDropDown(true);
        //setChkHideBoards(true);
        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setShowingMemberDropDown([]);
    }

    const dehighlightDropdown = () => {
        setDropDownClass("dropDown");
        setShowingDropDown(false);
        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setShowingMemberDropDown([]);
    }

    const selectOption = (e: React.MouseEvent<HTMLDivElement>, option: string) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedOption(option);
        dehighlightDropdown();
    }

    const handleShowingEmailInviteBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEmailInviteBoxValue(e.currentTarget.value);
    }

    const handleShowingEmailInviteBoxKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if(showingEmailInviteBoxEmail) {
            if (['Enter'].indexOf(e.key) !== -1) {
                selectEmail();
                return;
            }
        }

        if(e.currentTarget.value !== "") {
            setShowingEmailInviteBox(true);
            setShowingEmailInviteBoxEmail(false);
            if(isValidEmail(e.currentTarget.value)) {
                setShowingEmailInviteBoxEmail(true);
            }   
        } else {
            setShowingEmailInviteBox(false);
            setShowingEmailInviteBoxEmail(false);
        }
    }

    const handleSelectEmail = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        selectEmail();
    }

    const selectEmail = () => {
        
        setShowingEmailShareDescription(true);
        setShowingEmailInviteBox(false);
        setShowingEmailInviteBoxEmail(false);

        let emails = [...selectedEmails];
        if(!emails.includes(emailInviteBoxValue)) {
            emails.push(emailInviteBoxValue);
            setSelectedEmails(emails);
            setEmailInviteBoxValue("");
        }
    }

    const handleChangeEmailShareDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEmailShareDescription(e.currentTarget.value);
    }

    const removeSelectedEmail = (e: React.MouseEvent<HTMLDivElement>, i: number) => {
        e.preventDefault();
        e.stopPropagation();
        
        let emails = [...selectedEmails];
        emails.splice(i,1);
        if (sharingContext !== undefined && emails.length === 0) {
            let contextMenu = { ...sharingContext };
            contextMenu.height =  window.outerHeight * .65;
            setSharingContext(contextMenu);
            setShowingEmailShareDescription(false);
        }
        setSelectedEmails(emails);
    }

    const handleShareViaEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(sharingContext && selectedEmails.length > 0) {
            dispatch(shareViaEmail({
                projects: chkBoards, 
                emailBody: emailShareDescription,
                emails: selectedEmails,
                permission: selectedOption,
                roadmapId: sharingContext._id,
                roadmap: chkRoadmap,
                futureBoards: chkHideFutureBoards
            }));
            // show success, reset screen
            setShowingInviteSent(true);
            setTimeout(() => {
                setShowingInviteSent(false);
                closeContextMenu(e);
            }, 1000);
        }
    }

    const handleSaveMember = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        if(sharingContext && editingMember) {
            
            let tempMember = {...editingMember};
            tempMember.memberType = selectedOption.toLowerCase() as userType;
            tempMember.roadmap = chkRoadmap;
            //console.log(chkBoards);
            let projects: Array<string> = [];
            if (chkBoards) {
                chkBoards.forEach((value, key) => {
                    if (value) {
                        projects.push(key);
                    }
                });
            }
            //console.log("Projects:");
            //console.log(projects);
            tempMember.projectIds = projects;
            tempMember.futureBoards = chkHideFutureBoards;
            /* update backend */
            dispatch(saveMember({ roadmapId: sharingContext._id, member: tempMember }));
            /* update frontend */
            let members = [...roadmapState.members];
            let index = roadmapState.members.findIndex(m => m.email === tempMember.email);
            if (index !== -1) {
                members[index] = tempMember;
                dispatch(setMembers(members));
            }
            // show success, reset screen
            //resetState();
            setShowingInviteSent(true);
            setTimeout(() => {
                setShowingInviteSent(false);
                closeContextMenu(e);
            }, 1000);
        }
    }

    const chkBoard = (e: React.ChangeEvent<HTMLInputElement>, cardId: string) => {
        //const isChecked = e.target.checked;

        let map = new Map(chkBoards);
        if (map) {
            let cardSetting = map.get(cardId);
            if (cardSetting) {
                map.set(cardId, !cardSetting);
            } else {
                map.set(cardId, true);
            }
            //setIsDraggingTurnOff(map);
            setChkBoards(map);
        }
            //let boardsChecked = [...chkBoards];
        //boardsChecked[cardId] = e.target.checked;
        //setChkBoards(boardsChecked);
    }

    let project = projectState.projects.find(p => p.loaded);
    //let container = projectState.containers.find(c => c.loaded);
    //let projectMembers = projectState.projectMembers.filter(m => m.active);
    //let containerMembers = projectState.containerMembers.filter(c => c.active);
    //let members: Array<ProjectMember> = (shareType === "board") ? projectMembers : containerMembers;

    //let container = { shareLink: undefined };
    //let projectMembers = undefined;
    //let containerMembers = undefined;
    //let members: Array<ProjectMember> = [];

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        top: (sharingContext) ? sharingContext.y : 0,
        width: (sharingContext) ? sharingContext.width : 0,
    }
    
    const memberSelected = (selectedOption === "Member") ? "selected": "";
    const observerSelected = (selectedOption === "Observer") ? "selected": "";
    const adminSelected = (selectedOption === "Admin") ? "selected": "";

    let pCount = 0;
    let boards: Array<JSX.Element> = [];
    let projects = [...projectState.projects].sort((a, b) => (a.name > b.name ? 1 : -1));
    projects.map(project => {
        //let project = projectState.projects.find(p => p.categoryId === c._id);
        //if (project) {
            pCount++;
            let checked = (chkBoards) ? (chkBoards.get(project!._id)) ? chkBoards.get(project!._id) : false : false;
            let style = {
                padding: "10px 15px",
                fontSize: "11pt",
                borderRadius: "5px",
                backgroundColor: (pCount % 2 === 0) ? "#efefef" : "#fff",
            }
            boards.push(
                <div style={style}>
                    <div style={{ display: "inline-block", paddingRight: "10px", position: "relative", top: "2px" }}>
                        <input type="checkbox" checked={checked} onChange={e => chkBoard(e, project!._id)} />
                    </div>
                    <div style={{ display: "inline-block" }}>
                        {project.name}
                    </div>
                </div>
            );
        //}
    });

/*
    let boards: Array<JSX.Element> = [];
    let nocatProject = projectState.projects.filter(p => p.categoryId === "");

    nocatProject.forEach((project, index) => {
        pCount++;
        let checked = (chkBoards) ? (chkBoards.get(project!._id)) ? chkBoards.get(project!._id) : false : false;
        boards.push(        
            <div style={{ padding: "10px 15px", fontSize: "11pt", borderRadius: "5px", backgroundColor: "#fff" }}>
                <div style={{ display: "inline-block", paddingRight: "10px", position: "relative", top: "2px" }}>
                    <input type="checkbox" checked={checked} onChange={e => chkBoard(e, project!._id)} />
                </div>
                <div style={{ display: "inline-block" }}>
                    {project.name}
                </div>
            </div>
        );
    });
    
    roadmapState.categories.forEach((c, i) => {
        //console.log(c.title);
        let project = projectState.projects.find(p => p.categoryId === c._id);
        if (project) {
            pCount++;
            let checked = (chkBoards) ? (chkBoards.get(project!._id)) ? chkBoards.get(project!._id) : false : false;
            let style = {
                padding: "10px 15px",
                fontSize: "11pt",
                borderRadius: "5px",
                backgroundColor: (pCount % 2 === 0) ? "#efefef" : "#fff",
            }
            boards.push(
                <div style={style}>
                    <div style={{ display: "inline-block", paddingRight: "10px", position: "relative", top: "2px" }}>
                        <input type="checkbox" checked={checked} onChange={e => chkBoard(e, project!._id)} />
                    </div>
                    <div style={{ display: "inline-block" }}>
                        {c.title}
                    </div>
                </div>
            )
        }
    });
*/
    let chkBoardsDescription = "All Boards";
    //let boardsSelected = chkBoards.filter(value => value === true);
    let boardsSelected = 0;
    if (chkBoards) {
        chkBoards.forEach((value, key) => {
            if (value) {
                boardsSelected++;
            }
        });
    }

    if (!chkHideBoards) {
        chkBoardsDescription = "Close Board Selection";
    } else if (boardsSelected > 0) {
        chkBoardsDescription = "(" + boardsSelected + ") " + ((boardsSelected === 1) ? "Board Selected" : "Boards Selected");
    }

    return (
        <>
        {sharingContext && sharingContext._id !== "" &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation(); dehighlightDropdown(); }}>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div className="sharingContext" style={{ margin: "auto 0px", textAlign: "center" }}
                        onClick={(e) => { e.stopPropagation(); dehighlightDropdown(); }}>
                        <div style={{ width: "100%", margin: "0px auto 10px auto" }}>
                            {showingInviteSent &&
                                <div className="sharingInviteSent">
                                    <IconContext.Provider value={{ size: "20", className: "icon" }}>
                                        <IoIosCheckmarkCircleOutline />
                                    </IconContext.Provider>  
                                    <span className="ps-2">
                                        {editingMember ? "Member Saved" : "Invite(s) sent" }
                                    </span>
                                </div>
                            }
                            <h1 style={{ width: "100%", margin: "0px auto", fontSize: "16pt", textAlign: "left", marginBottom: "20px" }}>
                                {editingMember ? "Edit Member" : "Invite Users" }
                            </h1>
                            <div style={{ display:"inline-block", width: "25%", marginRight: "2%", verticalAlign: "top" }}>
                                <div>
                                    {selectedEmails.length > 0 ?
                                        <div className="mimicInput text-start">
                                            {selectedEmails.map((e, i) => {
                                                return (
                                                <div className="emailButton">
                                                    <span className="pe-2">{e}</span>
                                                    {!editingMember &&
                                                        <div onClick={(e) => removeSelectedEmail(e,i)} 
                                                            style={{ display: "inline-block", cursor: "pointer"  }}>
                                                            <AiOutlineClose size={13} />
                                                        </div>
                                                    }
                                                </div>
                                                )
                                            })}
                                            {!editingMember &&
                                                <input type="text"   
                                                    style={{ 
                                                        display: "inline-block",
                                                        border: "0",
                                                    }} 
                                                    onKeyUp={(e) => handleShowingEmailInviteBoxKeyUp(e)}
                                                    onChange={(e) => handleShowingEmailInviteBoxChange(e)}
                                                    value={emailInviteBoxValue}
                                                />
                                            }
                                        </div>
                                    :
                                        <input type="text" placeholder="Email address" style={{ width:"100%", height: "40px" }} 
                                            onKeyUp={(e) => handleShowingEmailInviteBoxKeyUp(e)}
                                            onChange={(e) => handleShowingEmailInviteBoxChange(e)}
                                            value={emailInviteBoxValue}
                                        />
                                    }
                                </div>
                                {showingEmailInviteBox &&
                                    <div className="emailInviteBox">
                                        {showingEmailInviteBoxEmail ?
                                            <div className="emailInviteBoxInner" onClick={(e) => handleSelectEmail(e)}>
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <IconContext.Provider value={{ size: "35", color: "#000", className: "avatarIcon" }}>
                                                            <FaUserCircle />
                                                        </IconContext.Provider>
                                                    </div>
                                                    <div className="col-10 text-start ps-1">
                                                        {emailInviteBoxValue}
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div style={{ padding: "10px 15px" }}>
                                                Add their email address to invite them
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div onClick={(e) => toggleDropDownHighlight(e)} 
                                style={{ position: "relative", display:"inline-block", width: "9%", verticalAlign: "top", textAlign: "left" }}>
                                <div className={dropDownClass}>
                                    <div style={{ paddingLeft: "10px" }}>
                                        <span>
                                            {selectedOption}    
                                        </span>
                                        <IconContext.Provider value={{ size: "20", className: "dropDownIcon" }}>
                                            <MdKeyboardArrowDown />
                                        </IconContext.Provider>                                
                                    </div>
                                </div>
                                {showingDropDown === true &&
                                    <div className="dropDownOptionsContainer">
                                        <div className={`dropDownOption ps-2 pt-2 pb-1 ${memberSelected}`} onClick={(e) => selectOption(e, "Member")}>
                                            <div className={`dropDownOptionsTitle`}>Member</div>
                                            <div className={`dropDownOptionsDescription`}>Members can view, add, and edit the roadmap's items.</div>
                                        </div>
                                        <div className={`dropDownOption ps-2 pt-1 pb-1 ${observerSelected}`} onClick={(e) => selectOption(e, "Observer")}>
                                            <div className={`dropDownOptionsTitle`}>Observer</div>
                                            <div className={`dropDownOptionsDescription`}>Observers can view and comment (if applicable).</div>
                                        </div>
                                        <div className={`dropDownOption ps-2 pt-1 pb-3 ${adminSelected}`} onClick={(e) => selectOption(e, "Admin")}>
                                            <div className={`dropDownOptionsTitle`}>Admin</div>
                                            <div className={`dropDownOptionsDescription`}>Admins can have full rights, including managing access.</div>
                                        </div>
                                    </div>
                                } 
                            </div>
                            <div style={{ 
                                display: "inline-block",
                                width: "9%",
                                marginLeft: "2%",
                                verticalAlign: "top",
                                position: "relative",
                                height: "40px",
                                backgroundColor: "#efefef",
                                borderRadius: "5px",
                                lineHeight: "40px",
                                textAlign: "left",
                                paddingLeft: "10px",
                             }}>
                                <input type="checkbox" checked={chkRoadmap} onChange={e => setChkRoadmap(e.currentTarget.checked)}/> Roadmap
                            </div>
                            <div style={{ 
                                display: "inline-block",
                                width: "16%",
                                marginLeft: "2%",
                                verticalAlign: "top",
                                position: "relative",
                                height: "40px",
                                backgroundColor: "#efefef",
                                borderRadius: "5px",
                                lineHeight: "40px",
                                textAlign: "left",
                                paddingLeft: "10px",
                             }}>
                                <input type="checkbox" checked={chkHideBoards} onChange={e => setChkHideBoards(e.currentTarget.checked)}/> {chkBoardsDescription}
                            </div>
                            <div style={{ 
                                display: "inline-block",
                                width: "16%",
                                marginLeft: "2%",
                                verticalAlign: "top",
                                position: "relative",
                                height: "40px",
                                backgroundColor: "#efefef",
                                borderRadius: "5px",
                                lineHeight: "40px",
                                textAlign: "left",
                                paddingLeft: "10px",
                             }}>
                                <input type="checkbox" checked={chkHideFutureBoards} onChange={e => setChkHideFutureBoards(e.currentTarget.checked)}/> All Future Boards
                            </div>
                            <div style={{ display:"inline-block", width: "8%", marginLeft: "2%", verticalAlign: "top", position: "relative", top: "7px" }}>
                                {editingMember ?
                                    <Link to="" onClick={(e) => handleSaveMember(e)} className="btn-orange" style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px" }}>
                                        Save
                                    </Link>
                                :
                                    <Link to="" onClick={(e) => handleShareViaEmail(e)} className="btn-orange" style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px" }}>
                                        Invite
                                    </Link>
                                } 
                            </div>
                            {showingEmailShareDescription &&
                                <div className="row gx-0" style= {{ width: "91%", paddingTop: "7px", margin: "0px auto" }}>
                                    <textarea style={{ width:"100%" }} 
                                        value={emailShareDescription}
                                        onChange={(e) => handleChangeEmailShareDescription(e)}
                                        placeholder="Join me on Herdr and let's work together!" />
                                </div>
                            }
                            {!chkHideBoards &&
                                <div style={{ 
                                    width: "100%",
                                    backgroundColor: "#efefef",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                    textAlign: "left"
                                }}>
                                    {boards}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default SharingContext;