import React, { CSSProperties, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { Link } from 'react-router-dom';
import { VscTriangleDown } from "react-icons/vsc";
import { BiSearch } from "react-icons/bi";
import { RxAvatar } from "react-icons/rx";
import { LuKanban } from "react-icons/lu";
import Avatar from "../Components/Avatar";
//import { getProject } from '../Store/Project';
import { getSettings, setSearchText } from '../Store/Settings';
import { getRoadmap, saveRoadmapMode } from '../Store/Roadmap';
import { getProject, saveProjectMode, unloadProject, setProjects } from '../Store/Project';
import { SettingsContextParam } from "../Models/Requests/SettingsContextParam";
import SettingsContext from "../Components/SettingsContext";
//import { Roadmap } from '../Models/Roadmap';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import { RxDashboard } from 'react-icons/rx';
import { IoPeopleOutline } from 'react-icons/io5';
import { IoMapOutline } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import { BsLightning } from "react-icons/bs";
import { IoIosList } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { RoadmapCategory } from '../Models/RoadmapCategory';

function TopNav() {

    const dispatch = useAppDispatch();
    //const projectState = useAppSelector(getProject);
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);
    const projectState = useAppSelector(getProject);
    const [settingsContextParams, setSettingsContextParams] = useState<SettingsContextParam>();
    const [search, setSearch] = useState("");
    /*
    useEffect(() => {
        if (projectState.status === "modeUpdated") {
            handleLoadComponent(dispatch, "work", "calendar")
        }
    },[projectState.status])
    */
    
    const handleSaveSearch = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => { 
    /* I think this one we want saving on every keystroke
        let key = "";
        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            key = keypress.key;          
        }

        if (key === 'Enter' || e.type !== "keydown") {
            dispatch(setSearchText(search));
        }*/
    }
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        dispatch(setSearchText(e.currentTarget.value));
    }

    const popSettingsWindow = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        //closeContainer();
        setSettingsContextParams({ _id: "1", event: e }); 
    }

        const returnToRoadmap = () => {
            /*
            let loadedProject = projectState.projects.find(p => p.loaded);
            if (loadedProject) {
                // update backend
                dispatch(unloadProject(loadedProject._id));
                // update frontend
                let projects = [...projectState.projects];
                let index = projectState.projects.findIndex(p => p.loaded);
                if (index !== -1) {
                    let project = {...loadedProject}
                    project.loaded = false;
                    projects[index] = project;
                    dispatch(setProjects(projects));
                }
            }
                */
            handleLoadComponent(dispatch, "roadmap", settingsState.settings);
        }

    let settings = settingsState.settings;
    //let currentMember = roadmapState.getMembers.find(m => m.me && m.active);
    let currentMember = undefined;
    //let roadmap: Roadmap | undefined;
    let loadedComponentName = "name of loaded component goes here";
    let componentNav: JSX.Element = (<div></div>);
    let showSearchWork = true;

    let category: RoadmapCategory | undefined = undefined;
    let project = projectState.projects.find(p => p.loaded);
    
    if (project) {
        category = roadmapState.categories.find(c => c._id === project!.categoryId);
        // let listStyle: CSSProperties = (category) ? { backgroundColor : category.backgroundColor, color: category.fontColor } : {};
    }

    if (settings.loadedComponentType === "global_herdr") {
        showSearchWork = false;
        componentNav = (
            <div style={{ fontSize: "14pt", fontWeight: "550" }}>
                Herdr Platform Settings
            </div>
        );

    } else if (settings.loadedComponentType === "roadmap") {
        let roadmap = roadmapState.roadmaps.find(r => r.loaded);
        if (roadmap) {
            loadedComponentName = roadmap.name;

            let dashboardClass = (roadmap.mode === "dashboard") ? "subTopNavSelected" : "subTopNav";
            let generateClass = (roadmap.mode === "generate") ? "subTopNavSelected" : "subTopNav";
            let roadmapClass = (roadmap.mode === "roadmap") ? "subTopNavSelected" : "subTopNav";
            let membersClass = (roadmap.mode === "members") ? "subTopNavSelected" : "subTopNav";
            //let settingsClass = (roadmap.mode === "settings") ? "subTopNavSelected" : "subTopNav";

            if (roadmap._id) {
                componentNav = (
                    <>
                    {/*
                    <div className={dashboardClass} style={{ padding: "0px 15px 0px 0px" }} 
                        onClick={() => dispatch(saveRoadmapMode({ id: roadmap!.id, mode: "dashboard"}))}>
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <RxDashboard size={16} />
                        </div>
                        <div className={dashboardClass}>
                            Dashboard
                        </div>
                    </div>
                    */}
                    <div className={roadmapClass} style={{ padding: "0px 15px" }} 
                        onClick={() => dispatch(saveRoadmapMode({ _id: roadmap!._id, mode: "roadmap"}))}>  
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <IoMapOutline size={16} />
                        </div>
                        <div className={roadmapClass}>
                            Roadmap
                        </div>
                    </div>
                    {/*
                    <div className={generateClass} style={{ padding: "0px 15px" }} 
                        onClick={() => dispatch(saveRoadmapMode({ id: roadmap!.id, mode: "generate"}))}> 
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <BsLightning size={16} />
                        </div>
                        <div className={generateClass}>
                            Generate
                        </div>
                    </div>
                    */}
                    {roadmap.roadmap &&
                        <div className={membersClass} style={{ padding: "0px 15px" }} 
                            onClick={() => dispatch(saveRoadmapMode({ _id: roadmap!._id, mode: "members"}))}>
                            <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                                <IoPeopleOutline size={18} />
                            </div>
                            <div className={membersClass}>
                                Members
                            </div> 
                        </div>
                    }
                    {/*
                    <div className={settingsClass} style={{ padding: "0px 10px" }} 
                       onClick={() => dispatch(saveRoadmapMode({ id: roadmap!.id, mode: "settings"}))}>  
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <IoSettingsOutline size={16} />
                        </div>
                        <div className={settingsClass}>
                            Settings
                        </div> 
                    </div>
                    */}
                    </>
                );
            }
        }
    } else if (settings.loadedComponentType === "work") {

        //let project = projectState.projects.find(p => p.loaded);
        if (project) {

            let calendarClass = (project.mode === "calendar") ? "subTopNavSelected" : "subTopNav";
            let boardClass = (project.mode === "board") ? "subTopNavSelected" : "subTopNav";
            let listClass = (project.mode === "list") ? "subTopNavSelected" : "subTopNav";
            let membersClass = (project.mode === "members") ? "subTopNavSelected" : "subTopNav";

            if (project._id) {
                componentNav = (
                    <>
                    <div className={boardClass} style={{ padding: "0px 15px 0px 0px" }} 
                        onClick={() => dispatch(saveProjectMode({ _id: project!._id, mode: "board"}))}>  
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <LuKanban size={16} />
                        </div>
                        <div className={boardClass}>
                            Board
                        </div>
                    </div>
                    {/*
                    <div className={listClass} style={{ padding: "0px 15px" }} 
                        onClick={() => dispatch(saveProjectMode({ id: project!.id, mode: "list"}))}> 
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <IoIosList size={16} />
                        </div>
                        <div className={listClass}>
                            List
                        </div>
                    </div>
                    <div className={calendarClass} style={{ padding: "0px 15px" }} 
                        onClick={() => dispatch(saveProjectMode({ id: project!.id, mode: "calendar"}))}>
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <IoCalendarOutline size={16} />
                        </div>
                        <div className={calendarClass}>
                            Calendar
                        </div>
                    </div>
                    */}
                    {/*
                    <div className={membersClass} style={{ padding: "0px 15px" }} 
                        onClick={() => dispatch(saveProjectMode({ id: project!.id, mode: "members"}))}>
                        <div style={{ position: "relative", display: "inline-block", top: "-1px", paddingRight: "5px" }}>
                            <IoPeopleOutline size={17} />
                        </div>
                        <div className={membersClass}>
                            Members
                        </div> 
                    </div>
                    */}
                    </>
                );
            }
        }
    }

    
    //if (roadmap) {
    
    let searchPlaceholder = "";
    if (settingsState.settings.loadedComponentType === "roadmap") {
        let roadmap = roadmapState.roadmaps.find(r => r.loaded);
        if (roadmap) {
            if (roadmap.mode === "members") {
                searchPlaceholder = "Search members...";
            } else {
                searchPlaceholder = "Search your items...";
            }
        }
    } else if (settingsState.settings.loadedComponentType === "work") {
        searchPlaceholder = "Search your work...";
    }

    let catStyle: CSSProperties = {
        backgroundColor : (category) ? category.backgroundColor : "#efefef",
        color: (category) ? category.fontColor : "#000",
        borderRadius: "30px",
        padding: "4px 10px",
        cursor: "pointer",
        fontSize: "9pt",
        display: "flex", 
        alignItems: "center", 
        position: "relative", 
        top: "1px"
    }

    return (
        <>
        <div style={{ width: "100%", padding: "7px 15px", backgroundColor: "#fff", 
            borderTopRightRadius: "20px", display: "flex", alignItems: "center",
            justifyContent: "space-between"
            }}>
            <div style={{ display: "inline-block", marginRight: "auto" }}>
                {/*
                <div style={{ display: "inline-block", paddingRight: "5px", position: "relative", top: "-2px" }}>
                    <IoMapOutline size={19} color="#c44f01" />
                </div>
                <div style={{ display: "inline-block", fontSize: "13pt", fontWeight: "550", paddingRight: "5px" }}>
                    {loadedComponentName}
                </div>
                <div style={{ display: "inline-block" }}>
                    <VscTriangleDown size={14} />
                </div>
                */}
                <div style={{ fontSize: "10pt" }}>
                    {componentNav}
                </div>
            </div>
            {(settingsState.settings.loadedComponentType === "work") &&
                <div style={catStyle} onClick={() => returnToRoadmap()}>
                    <b>{category ? category.title : "Uncategorized"}</b> {": Return to Roadmap"}
                </div>
            }
            <div style={{ display: "flex",alignItems: "center" }}>
                {showSearchWork &&
                <>
                    <div style={{ display: "inline-block", position: "relative", left: "26px" }}>
                        <BiSearch color="gray" size={18} />
                    </div>
                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                        <input type="text" className="navSearchInput" placeholder={searchPlaceholder} 
                            value={search}
                            onChange={(e) => handleSearchChange(e)}
                            //onChange={(e) => setSearch(e.currentTarget.value)}
                            //onKeyDown={(e) => handleSaveSearch(e)} 
                            //onBlur={(e) => handleSaveSearch(e)}
                        />
                    </div>
                </>
                }
                <div style={{ display: "inline-block", 
                    fontSize: "8pt", marginRight: "20px" }}>
                    <div style={{ fontWeight: "bold" }}>7 Day Trial</div>
                    <div style={{ fontWeight: "bold", color: "#007AFF" }}>
                        Upgrade&nbsp;
                        <VscTriangleDown size={14} color="#007AFF" />
                    </div>
                </div>
                <div onClick={(e) => popSettingsWindow(e)} style={{ cursor: "pointer" }}>    
                    <Avatar size="small" member={currentMember} />
                </div>
            </div>
        </div>
        <SettingsContext params={settingsContextParams} />
        </>
    );
}

export default TopNav;