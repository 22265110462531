import { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import { emailSupport } from '../../Store/Utility';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';
//import Registration from '../../Modals/Registration';
import PricingTiers from '../../Components/PricingTiers';
import { isValidEmail } from "../../Helpers/isValidEmail";

function Pricing() {

    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    //const [showRegistration, setShowRegistration] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
/*
    const toggleRegistration = (visible: boolean) => {
        setShowRegistration(visible);
    }
*/
    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
      };

    const handleEmailSupport = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {

        e.preventDefault();

        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            if (keypress.key !== 'Enter') {
                return;
            }            
        }
        
        if (isValidEmail(email) && message !== "") {
            dispatch(emailSupport({
                marketingSource: "Herdr Web Inquiry: Pricing Page Inquiry Form",
                email: email,
                message: message,
            }));
            setEmail("");
            setMessage("");
            setSuccess(true);
            setTimeout(() => setSuccess(false), 2000);
        } else {
            setError(true);
            setTimeout(() => setError(false), 2000);
        }
    }

    return (
        <>
            <Navigation />
            <div className="pageHeader" style={{ paddingBottom: "300px" }}>
                <div className="standardPublicPage60">
                    <div className="standardPublicParagraph" style={{ textAlign: "center" }}>
                        <h1 className="homePageHeader" style={{ textAlign: "center" }}>
                            A simplified tool with simplified pricing 
                        </h1> 
                        <div style={{ color: "#cacfd2"}}>
                            For individuals and small teams seeking big-team roadmapping, 
                            action planning, and project management tools to push their ideas to the finish line.
                        </div>
                    </div>
                </div>
            </div>

            <div className="pricingPageTiersContainer">
                <PricingTiers 
                    mostPopularButtonBackgroundColor='#e55c02' 
                    mostPopularButtonColor='#fff'
                    mostPopularButtonBorder='3px solid #02152a' 
                />
            </div>

            <div className="pricingPageHelpForm">
                <div className="pricingPageHelpFormLeft">
                    <h2 style={{ color: "#fff" }}>Have questions or need assistance?</h2>
                    <div style={{ textAlign: "left", fontSize: "14pt", color: "#cacfd2" }}>
                        Our team is ready to help. Click the button below to reach out, and we'll 
                        get back to you as soon as possible.
                    </div>
                    {error &&
                        <div className="error">
                            Please enter a valid email and request
                        </div>
                    }
                    {success &&
                        <div className="success">
                            Inquiry sent. We'll be in touch!
                        </div>
                    }
                    <input onChange={(e) => handleEmailChange(e)} value={email} placeholder="Enter your email address"
                        className="pricingPageHelpFormEmail" />
                    <textarea onChange={(e) => handleMessageChange(e)} value={message} placeholder="Enter your question or request"
                        className="pricingPageHelpFormMessage">
                    </textarea>
                    <button className="yellowButton" onClick={(e) => handleEmailSupport(e)} onKeyDown={(e) => handleEmailSupport(e)}>
                        Send Question
                    </button>
                </div>
                <div className="pricingPageHelpFormRight">
                    <img src='/images/productivity-app.png' style={{ width: "90%", height: "90%" }} />
                </div>
            </div>

            <Footer />
            {/*
            <Registration visible={showRegistration} visibleHandler={toggleRegistration} />
            */}
        </>
    );
}

export default Pricing;