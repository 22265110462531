import React, { CSSProperties, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { loadComponent, toggleChat, toggleWork, toggleRoadmaps, getSettings } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import { FaRegFolder } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { createFolder, getFolder } from '../Store/Folder';

function ChatSubNav() {

    const dispatch = useAppDispatch();
    const settingsState = useAppSelector(getSettings);
    const folderState = useAppSelector(getFolder);
    const [search, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState("");

    const createChatFolder = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        //dispatch(createFolder({ type: 'chat', roadmapId: "" }));
    }
    const handleShowAddChat = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        //setChatContextMenuParams({ event: e, _id: "1" });    
    }
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>  {
        e.preventDefault();
        setSearchText(e.currentTarget.value);
    }

    return (
        <div className="leftSubNavWrapper">
            <div style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "15px" }}>
                <div style={{ fontWeight: "500", color: "#F8F9FA", width: "40%",fontSize: "12pt", display: "inline-block" }}>
                    Chat
                </div>
                <div style={{ width: "60%", display: "inline-block", textAlign: "right" }}>
                    {!search ?
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => setShowSearch(true)}>
                            <BiSearch size={16} color="#909495" />
                        </div>
                    :
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => { setSearchText(""); setShowSearch(false) }}>
                            <AiOutlineClose size={16} color="#909495" />
                        </div>
                    }
                    <div style={{ display: "inline-block", paddingRight: "10px", cursor: "pointer" }}
                        onClick={(e) => createChatFolder(e)}>
                        <FaRegFolder size={14} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "0px", cursor: "pointer" }}
                        onClick={(e) => handleShowAddChat(e)}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                </div>
            </div>
            <hr style={{ width: "94%", color: "#909495", margin: "15px auto 0px auto" }} />
            {search &&
                <div style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "20px" }}>
                    <input type="text" placeholder="Search channels..." className="navSearch"
                        value={searchText}
                        onChange={(e) => handleSearchChange(e)} />
                </div>
            }
        </div>
    );
}

export default ChatSubNav;