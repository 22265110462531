import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ProjectCard } from '../Models/ProjectCard';
import { MComment } from '../Models/MComment';
import { getProject, saveComment } from '../Store/Project';
import { getSettings } from '../Store/Settings';
import { useAppDispatch, useAppSelector } from '../Core/hooks';

/* Quill imports are required because nested lists in editor use classes, not
   true semmantic nested lists. To get them to display correctly in view mode, 
   we must import these and apply the same class Quill does in editor mode.
   https://github.com/quilljs/quill/issues/1314
*/
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

interface Props {
    mode: string;
    card: ProjectCard;
    comment?: MComment;
    closeHandler?: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void
}

function CardComment(props: Props) {
    const projectState = useAppSelector(getProject);
    const userSettings = useAppSelector(getSettings);
    const editorRef = useRef<ReactQuill | null>(null);
    const dispatch = useAppDispatch();
    const [richText, setRichText] = useState('');
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        if (props.mode === "edit" && props.comment) {
            setEditing(true);
            setRichText(props.comment.richText);
        }
    }, []);


    useEffect(() => {
        if (editing && editorRef && editorRef.current && editorRef.current.editor) {
            let textLength = editorRef.current.editor.getLength();
            editorRef.current.editor.setSelection(textLength +1,1);
        }
    }, [editing])

    const save = (e: React.MouseEvent<HTMLAnchorElement>) => {
   
        e.preventDefault();
        e.stopPropagation();

        if (props.mode === "add") { 
            let newComment: MComment = {
                _id: "",
                userId: "",
                created: new Date(),
                modified: new Date(),
                active: true,
                cardId: props.card._id,
                richText: richText
            }
            dispatch(saveComment(newComment));
            setRichText("");
        } else {
            if (props.closeHandler && props.comment) {
                let comment = { ...props.comment};
                comment.richText = richText;
                dispatch(saveComment(comment));
                props.closeHandler(e, props.comment._id);
            }
        }
        toggleEdit(e, false);
    }

    const toggleEdit = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>, visible: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        setEditing(visible);
    }

    let project = projectState.projects.find(p => p.loaded);
    let existingComment = (props.comment) ? props.comment.richText : "";

    return (
        <>
            {editing ?
                <>
                    <div className="descriptionBox">
                        <div className="commentDetails" onClick={(e) => {e.preventDefault(); e.stopPropagation(); }}> 
                            <ReactQuill theme="snow"
                                ref={editorRef}
                                value={richText}
                                onChange={setRichText}
                                modules={{
                                    toolbar: {
                                      container: [...[
                                        { 'header': '1' },
                                        { 'header': '2' },
                                        "underline",
                                        "bold",
                                        "italic",
                                        "link",
                                        { 'list': 'ordered' },
                                        { 'list': 'bullet' },
                                        { 'indent': '-1' },
                                        { 'indent': '+1' }, 
                                        "image",
                                        "video",
                                        "code-block",
                                        'blockquote',
                                      ]],
                                    },
                                  }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                        <Link to="" onClick={(e) => save(e)} className="btn-orange">
                            Save
                        </Link>
                        <Link to="" onClick={(e) => {
                            if (props.mode === "add") {
                                toggleEdit(e, false); 
                            } else {
                                if (props.closeHandler && props.comment) {
                                    props.closeHandler(e, props.comment._id);
                                }
                            }
                        }}
                            className="ps-3" style={{ fontWeight: "500" }}>
                            Cancel
                        </Link>
                    </div>
                </>
            :
                <>
                {project?.permission?.toString() !== "observer" ?
                    <div onClick={(e) => {toggleEdit(e, true)}} className="mt-2 mb-1">
                        <div style={{
                            width: "100%", padding: "7px 12px", borderRadius: "5px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            fontWeight: "500", fontSize: "11pt", color: "gray" }}>
                            Add a comment
                        </div>
                    </div>
                :
                    <>
                    {props.mode !== "add" &&
                        <>
                            {project?.permission?.toString() !== "observer" ?
                                <div onClick={(e) => {toggleEdit(e, true)}} 
                                className="cardDescription pt-3 ql-editor" 
                                style={{ height: "auto", paddingLeft: "0px" }}
                                dangerouslySetInnerHTML={{
                                    __html: (richText === '') ? existingComment : richText
                                }} />
                            :
                                <div className="cardDescription pt-3 ql-editor" 
                                style={{ height: "auto", paddingLeft: "0px" }}
                                dangerouslySetInnerHTML={{
                                    __html: (richText === '') ? existingComment : richText
                                }} />
                            }
                        </>
                    }
                    </>
                }
                </>
            }
        </>
    );    
}

export default CardComment;