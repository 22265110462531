import React, { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap, setRoadmaps, saveRoadmap } from '../Store/Roadmap';
import { loadComponent } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import ChatSubNav from './ChatSubNav';
import { getSettings } from '../Store/Settings';
import { getProject, setProjects, saveProject, unloadProject } from '../Store/Project';
import WorkSubNav from './WorkSubNav';
import { Project } from '../Models/Project';
import { getFolder, setFolderStatus } from '../Store/Folder';
//import { GoSidebarExpand, GoSidebarCollapse } from "react-icons/go";

interface Props {
    roadmap: Roadmap | undefined;
    project: Project | undefined;
}

function LeftSubNav(props: Props) {

    const dispatch = useAppDispatch();
    const folderState = useAppSelector(getFolder);
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);
    const projectState = useAppSelector(getProject);
    const [sendingBack, setSendingBack] = useState(false);
    const [showEditName, setShowEditName] = useState(false);
    const [name, setName] = useState("");

    //let backHandler: any;
    //let loadedComponentName = "";

    let previousComponent: loadedComponentType = (settingsState.settings.loadedComponentType === "work") ? "roadmap" : "global_maps";

    useEffect(() => {
        setShowEditName(false);
        if (settingsState.settings.loadedComponentType === "work" && props.project) {
            setName(props.project.name);
        } else if (settingsState.settings.loadedComponentType === "roadmap" && props.roadmap) {
            setName(props.roadmap.name);
        }
    }, [settingsState.settings.loadedComponentType]);

    const returnToPreviousScreen = () => {
        /*
        let loadedProject = projectState.projects.find(p => p.loaded);
        if (loadedProject) {
            // update backend
            dispatch(unloadProject(loadedProject._id));
            // update frontend
            let projects = [...projectState.projects];
            let index = projectState.projects.findIndex(p => p.loaded);
            if (index !== -1) {
                let project = {...loadedProject}
                project.loaded = false;
                projects[index] = project;
                dispatch(setProjects(projects));
            }
        }
        */

        if (settingsState.settings.loadedComponentType === "work") {
            handleLoadComponent(dispatch, previousComponent);
        } else if (settingsState.settings.loadedComponentType === "roadmap") {
            handleLoadComponent(dispatch, previousComponent);
        }
    }

    const handleSaveName = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => { 
  
        let key = "";
        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            key = keypress.key;          
        }

        if (key === 'Enter' || e.type !== "keydown") {
            if (settingsState.settings.loadedComponentType === "work") {
                if (props.project) {
                    let project: Project = {...props.project};
                    project.name = e.currentTarget.value;
                    let projects: Array<Project> = [...projectState.projects];
                    let index = projects.findIndex(p => p._id === project._id);
                    if (index !== -1) {
                        projects[index] = project;
                        dispatch(setProjects(projects));
                        dispatch(saveProject(project));
                    }
                }
            } else if (settingsState.settings.loadedComponentType === "roadmap") {
                if (props.roadmap) {
                    let roadmap: Roadmap = {...props.roadmap};
                    roadmap.name = e.currentTarget.value;
                    let roadmaps: Array<Roadmap> = [...roadmapState.roadmaps];
                    let index = roadmaps.findIndex(r => r._id === roadmap._id);
                    if (index !== -1) {
                        roadmaps[index] = roadmap;
                        dispatch(setRoadmaps(roadmaps));
                        dispatch(saveRoadmap(roadmap));
                    }
                }
            }
            setShowEditName(false);
        }
    }

    let navItemStyle = { 
        fontSize: "10pt",
        display: "inline-block", 
        paddingLeft: "10px", 
        color: "#bec1c3" 
    };

    let navItemSelectedStyle = {
        fontSize: "10pt",
        display: "inline-block",
        paddingLeft: "10px",
        color: "#e55c02", fontWeight: "450",
        textDecoration: "underline", textUnderlineOffset: "5px", textDecorationThickness: "1px",
    };

    //let roadmapSubType = (settingsState.settings.loadedComponentType === "roadmap") ? settingsState.settings.loadedComponentSubtype : "";

    /* icon selected / not selected classes */
/*
    let dashboardTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "dashboard");
    let dashboardIconColor = (dashboardTabLoaded) ? "#e55c02" : "#bec1c3";
    let dashboardDivStyle: CSSProperties = (dashboardTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let roadmapTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "roadmap");
    let roadmapIconColor = (roadmapTabLoaded) ? "#e55c02" : "#bec1c3";
    let roadmapDivStyle: CSSProperties = (roadmapTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let membersTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "members");
    let membersIconColor = (membersTabLoaded) ? "#e55c02" : "#bec1c3";
    let membersDivStyle: CSSProperties = (membersTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let actionTabLoaded = (props.roadmap && props.roadmap.loaded && settingsState.settings.loadedComponentType === "action");
    let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let actionDivStyle: CSSProperties = (actionTabLoaded) ? navItemSelectedStyle : navItemStyle;
*/

// /{(props.project) ? props.project.name : (props.roadmap) && props.roadmap.name}

    return (
        <div className="leftSubNavWrapper">

            <div style={{ width: "94%", margin: "0px auto", color: "#F8F9FA", paddingTop: "15px",
                fontWeight: "500", textAlign: "center"
             }}>
                <div style={{ width: "5%", display: "inline-block", position: "relative", top: "-8px", cursor: "pointer" }} 
                    onClick={() => returnToPreviousScreen()}>
                    <BiSolidLeftArrow size={12} color="#F8F9FA" />
                </div>
                <div style={{ display: "inline-block", paddingLeft: "10px", paddingRight: "10px", textOverflow: "ellipsis",
                    width: "calc(95%)", overflow: "hidden", whiteSpace: "nowrap" }}>
                    {!showEditName ?
                        <div onClick={(e) => setShowEditName(true)}>
                            {( props.project && settingsState.settings.loadedComponentType === "work") ? props.project.name : (props.roadmap) && props.roadmap.name}
                            &nbsp;&nbsp;
                            </div>
                    :
                        <input className="form-control-custom-1" style={{ height: "26px" }}
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            onKeyDown={(e) => handleSaveName(e)} 
                            onBlur={(e) => handleSaveName(e)} />
                    }
                </div>
                {/*
                <div style={{ width: "5%", display: "inline-block", position: "relative", top: "-7px" }}>
                    <IoSettingsOutline size={16} color="#bec1c3" />
                </div>
                */}
                {/*
                <div style={{ width: "20%", display: "inline-block", textAlign: "right", paddingRight: "20px" }}>
                    <RiArrowDownSFill  size={24} color="#909495" />
                </div>
                */}
            </div>
 {/*
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, "dashboard")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <RxDashboard size={18} color={dashboardIconColor} />
                </div>
                <div style={dashboardDivStyle}>
                    Dashboard
                </div>
            </div>
           
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }} 
                onClick={(e) => handleLoadComponent(dispatch, "roadmap", roadmapSubType )}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <IoMapOutline  size={18} color={roadmapIconColor} />
                </div>
                <div style={roadmapDivStyle}>
                    Roadmap
                </div>
            </div>
         
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, "members")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <IoPeopleOutline size={18} color={membersIconColor} />
                </div>
                <div style={membersDivStyle}>
                    Members
                </div>
            </div>

            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495", cursor: "pointer" }}
                onClick={(e) => handleLoadComponent(dispatch, "action")}>
                <div style={{ display: "inline-block", textAlign: "right" }}>
                    <BsBell size={18} color={actionIconColor} />
                </div>
                <div style={actionDivStyle}>
                    Action
                </div>
            </div>
            <hr style={{ width: "94%", color: "#909495", margin: "10px auto 0px auto" }} />
            <div style={{ width: "80%", margin: "10px auto 0px auto", color: "#909495" }}>
                <div style={{ width: "10%", display: "inline-block" }}>
                    <IoMapOutline size={20} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Roadmaps
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", position: "relative", top: "1px" }}>
                        <VscTriangleDown  size={18} color="#909495" />
                    </div>
                </div>
            </div>
*/}
            {/*
            <div style={{ width: "94%", margin: "10px auto 0px auto", color: "#909495" }}>
                <div style={{ width: "10%", display: "inline-block" }}>
                    <LuKanban size={20} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "50%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Work
                </div>
                <div style={{ width: "40%", display: "inline-block", textAlign: "right" }}>
                    <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px" }}>
                        <BiSearch size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", position: "relative", top: "1px" }}>
                        <VscTriangleDown  size={18} color="#909495" />
                    </div>
                </div>
            </div>
            */}
            {props.roadmap &&
                <>
                    <hr style={{ width: "94%", color: "#909495", margin: "10px auto 0px auto" }} />
                    <WorkSubNav roadmap={props.roadmap} />
                </>
            }
            {/*
            {settingsState.settings.loadedComponentType !== "work" ?
            <>
                {props.roadmap &&
                <>
                    <hr style={{ width: "94%", color: "#909495", margin: "10px auto 0px auto" }} />
                    <WorkSubNav roadmap={props.roadmap} />
                </>
                }
            </>
            :
                <ChatSubNav />
            }
            */}
        </div>
    );
}

export default LeftSubNav;