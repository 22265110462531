//import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { Folder } from "../Models/Folder";
import { Roadmap } from "../Models/Roadmap";
import { Project } from "../Models/Project";
import { setRoadmapFolders, setWorkFolders, saveFolders } from "../Store/Folder";
import { setRoadmaps, saveRoadmaps } from "../Store/Roadmap";
import { setProjects, saveProjects } from "../Store/Project";
import { folderType } from "./types";

interface FileFolder {
    type: string;
    _id: string;
    index: number;
    folderId: string;
    expanded: boolean;
}

/*****************************************************************************
 * rearrangeFoldersAndFiles: Sort and save files and folders
 *****************************************************************************/

export const rearrangeFoldersAndFiles = (dispatch: any, folders: Array<Folder>, 
    unsortedFiles: Array<Roadmap | Project>, type: folderType) => {
    
    folders.sort(function(a, b) { return a.sort - b.sort });

    //let roadmaps = [...roadmapState.roadmaps];
    //if(unsortedFiles.every(item => item instanceof Roadmap)

    unsortedFiles.sort(function(a, b) { return a.sort - b.sort });

    let key = 0;

    let files: Array<Roadmap | Project> = [];

    folders.forEach(f => {        
        unsortedFiles.filter(item => item.folderId === f._id).forEach(item => {
            let file = {...item, sort: key };
            files.push(file);
            key++;
        });
    });

    unsortedFiles.filter(item => item.folderId === "" || !item.folderId).forEach(item => {
        let file = {...item, sort: key };
        files.push(file);
        key++;
    });

    /* update frontend */
    //dispatch(setFolders(folders));
    /* update backend */
    dispatch(saveFolders(folders));

    if(type === 'roadmap') {
        dispatch(setRoadmapFolders(folders)); /* update frontend */
        dispatch(setRoadmaps(files as Roadmap[])); /* frontend */
        dispatch(saveRoadmaps(files as Roadmap[])); /* backend */
    } else if (type === 'work') {
        dispatch(setWorkFolders(folders)); /* update frontend */
        dispatch(setProjects(files as Project[])); /* frontend */
        dispatch(saveProjects(files as Project[])); /* backend */
    }
    //console.log(files);
}

/*****************************************************************************
 * onFolderFileDragEnd: A folder or file was dragged and dropped 
 *****************************************************************************/

export const onFolderFileDragEnd = (result: any, dispatch: any, folders: Array<Folder>, 
    files: Array<Roadmap | Project>, type: folderType) => {

        //console.log(result.draggableId);
        //console.log(folders);
        //console.log(files);
        //console.log(type);

    let id = result.draggableId.split("-");
    let newIndex = parseInt(result.destination.index);
    let oldIndex = parseInt(result.source.index);

    let fileId = "", folderId = "";
    
    if (id[0] === "file") {
        fileId = id[1];
    } else if (id[0] === "folder") {
        folderId = id[1];
    }

    console.log("New Index: " + newIndex);
    console.log("Old Index: " + oldIndex);
    
    let oldFileIndex = files.findIndex(f => f._id === fileId);
    let newFileIndex = oldFileIndex + (newIndex - oldIndex);

    newFileIndex = (newFileIndex < 0) ? 0 : newFileIndex;

    console.log("Old File Index: " + oldFileIndex);
    console.log("New File Index: " + newFileIndex);
    
    /* Need to see if a file is being dragged across folders. If it is, we need to account for 
        the folder indexes. If not being dragged across folders, it is handled by the 
        newRoadmapIndex statement about. 
    */

    let key = 0;
    let foldersAndFiles: Array<FileFolder> = [];

    folders.forEach((folder,i) => {
        foldersAndFiles.push({
            type: "folder",
            _id: folder._id,
            index: key,
            folderId: folder._id,
            expanded: folder.expanded
        });
        key++;
        if (folder.expanded) {
            files.filter(file => file.folderId === folder._id).forEach(file => {
                foldersAndFiles.push({
                    type: "file",
                    _id: file._id,
                    index: key,
                    folderId: file.folderId,
                    expanded: false
                });
                key++;
            });
        }
      });
      files.filter(file => file.folderId === "" || !file.folderId).forEach(file => {
          foldersAndFiles.push({
              type: "file",
              _id: file._id,
              index: key,
              folderId: file.folderId,
              expanded: false
          });
          key++;
      });

    //console.log(foldersAndFiles);

    let foldersBetweenDrag = 0; /* actually folders to the drag, not between drag */
    let start = (newIndex > oldIndex) ? oldIndex : newIndex;
    let end = (newIndex > oldIndex) ? newIndex : oldIndex;  
    //console.log("Start: " + start + " End: " + end);  

    for (let i=start; i <= end; i++) {
        let folderFound = foldersAndFiles.find(f => f.index === i && f.type === "folder");
        if (folderFound) {
            foldersBetweenDrag++;
        }
    }

    console.log("Folders found: " + foldersBetweenDrag);

    if (foldersBetweenDrag > 0) {
        if (newIndex < oldIndex) {
            newFileIndex = oldFileIndex + (newIndex - (oldIndex - foldersBetweenDrag));
        } else {
            newFileIndex = oldFileIndex + (newIndex - foldersBetweenDrag - oldIndex);
        }
    }

    console.log("New File Index Adjusted: " + newFileIndex);
   
    let skipNewFolderAssignmentCheck = false;
    if (fileId !== "") {
        //let roadmap = roadmapState.roadmaps.find(r => r.id === roadmapId);
        let file = files.find(file => file._id === fileId);
        if (file) {
            //console.log("File Name: " + file.name);
            let tempFile = {...file};
            /* Determine if the new index is in a folder. If it is, update the roadmap's 
                folderId to place it in the folder, else make it zero to move out of 
                or keep out of a folder */
            let fileAtNewIndex = files.find(file => file.sort === newFileIndex);
            //console.log(fileAtNewIndex);
            if (fileAtNewIndex && file !== fileAtNewIndex) {
                if (!fileAtNewIndex.folderId) {
                    tempFile.folderId = "";
                    skipNewFolderAssignmentCheck = true;
                    //console.log("no folder id found");
                } else {
                    tempFile.folderId = fileAtNewIndex.folderId;
                }
            }

            if (foldersBetweenDrag > 0 && !skipNewFolderAssignmentCheck) {
                console.log("Assigning a new folder");
                /* Find the first folder before the index. That will be the one we 
                    are dragging into */
                let start = (newIndex > oldIndex) ? newIndex : newIndex - 1;
                for (let i=start; i >= 0; i--) {
                    let folderFound = foldersAndFiles.find(f => f.index === i && f.type === "folder");
                    if (folderFound) {
                        tempFile.folderId = folderFound._id;
                        break;
                    }
                }
            }

            //console.log("Old Roadmap Index: " + oldFileIndex);
            //console.log("New Roadmap Index: " + (newFileIndex));

            files.splice(oldFileIndex, 1);
            files.splice(newFileIndex, 0, tempFile);

            //console.log("files");
            //console.log(files);

            let sortedFiles = files.map((file, i) => {
                return {...file, sort: i };
            });

            if (type === 'roadmap') {
                dispatch(setRoadmaps(sortedFiles as Array<Roadmap>)); // update frontend
                dispatch(saveRoadmaps(files as Array<Roadmap>)); // update backend
            } else if (type === 'work') {
                dispatch(setProjects(sortedFiles as Array<Project>)); // update frontend
                dispatch(saveProjects(files as Array<Project>)); // update backend
            }
        }
    }
    
    if (folderId !== "") {
        //let folder = folderState.folders.find(f => f.id === folderId);
        let folder = folders.find(folder => folder._id === folderId);
        if (oldIndex !== newIndex) {
            //let folders = [...folderState.folders].sort(function(a, b) { return a.sort - b.sort });
            folders.sort(function(a, b) { return a.sort - b.sort });
            if (folder) {
                folders.splice(oldIndex, 1);
                folders.splice(newIndex, 0, folder);
            
                let sortedFolders = folders.map((f, i) => {
                    return {...f, sort: i };
                });
                //rearrangeFoldersAndFiles(dispatch, sortedFolders, [...roadmapState.roadmaps], 'roadmap');
                rearrangeFoldersAndFiles(dispatch, sortedFolders, files, type);
            }
        }
    }
}