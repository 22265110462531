import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Folder } from '../Models/Folder';
import { folderStatus } from '../Helpers/types';
import { CreateRoadmapParams } from '../Models/Requests/CreateRoadmapParams';
import { RequestExpandFolder } from '../Models/Requests/RequestExpandFolder';
import { RequestSaveFolderName } from '../Models/Requests/RequestSaveFolderName';
import { CreateFolderParams } from '../Models/Requests/CreateFolderParams';

export interface FolderState {
  //folders: Array<Folder>;
  roadmapFolders: Array<Folder>;
  workFolders: Array<Folder>;
  chatFolders: Array<Folder>;
  status: folderStatus;
  errorMessage: string;
  editingFolder: number;
}

const initialState: FolderState = {
  //folders: [],
  roadmapFolders: [],
  workFolders: [],
  chatFolders: [],
  status: "unset",
  errorMessage: "",
  editingFolder: 0,
};

export const getRoadmapFolders = createAsyncThunk(
  'get-roadmap-folders',
  async () => {    
    return await axiosPrivate.get('api/folder/roadmap',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getWorkFolders = createAsyncThunk(
  'get-work-folders',
  async (roadmapId: string) => {    
    return await axiosPrivate.get('api/folder/work/' + roadmapId,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/*
export const setFolders = createAsyncThunk(
  'set-folders',
  async (folders: Array<Folder>) => {    
      return folders;
});
*/
export const setRoadmapFolders = createAsyncThunk(
  'set-roadmap-folders',
  async (folders: Array<Folder>) => {    
      return folders;
});

export const setWorkFolders = createAsyncThunk(
  'set-work-folders',
  async (folders: Array<Folder>) => {    
      return folders;
});

export const createFolder = createAsyncThunk(
  'api-folder-create',
  async (param: CreateFolderParams) => {    
    return await axiosPrivate.post('api/folder',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const expandFolder = createAsyncThunk(
  'api-folder-expand',
  async (param: RequestExpandFolder) => {    
    return await axiosPrivate.post('api/folder/expand',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveFolderName = createAsyncThunk(
  'api-folder-save-name',
  async (param: RequestSaveFolderName) => {    
    return await axiosPrivate.post('api/folder/name',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveFolders = createAsyncThunk(
  'api-folder-save',
  async (folders: Array<Folder>) => {    
    return await axiosPrivate.post('api/folder/save',
      JSON.stringify({
        folders: folders
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteFolder = createAsyncThunk(
  'api-delete-folder',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/folder/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const setFolderStatus = createAsyncThunk(
  'set-folder-status',
  async (status: folderStatus) => {    
      return status;
});

export const FolderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(setFolderStatus.fulfilled, (state, action) => {
        state.status = action.payload;
      })
      .addCase(setRoadmapFolders.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roadmapFolders = [...action.payload];
      })
      .addCase(setWorkFolders.fulfilled, (state, action) => {
        state.status = 'idle';
        state.workFolders = [...action.payload];
      })
      .addCase(saveFolderName.fulfilled, (state, action) => {
        state.status = 'idle';
        let folders: Array<Folder> = [];
        let folder = action.payload;
        if (folder.type === "roadmap") {
          folders = [...state.roadmapFolders];
        } else if (folder.type === "work") {
          folders = [...state.workFolders];
        }
        let folderIndex = folders.findIndex(f => f._id === folder._id);
        if (folderIndex !== -1) {
          folders[folderIndex].name = folder.name;
          if (folder.type === "roadmap") {
            state.roadmapFolders = folders;
          } else if (folder.type === "work") {
            state.workFolders = folders;
          }
        }
      })
      .addCase(expandFolder.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        
        let folders: Array<Folder> = [];
        let folder = action.payload;

        if (folder.type === "roadmap") {
          folders = [...state.roadmapFolders];
        } else if (folder.type === "work") {
          folders = [...state.workFolders];
        }

        folders.splice(0,0,folder);

        if (folder.type === "roadmap") {
          state.roadmapFolders = folders;
        } else if (folder.type === "work") {
          state.workFolders = folders;
        }        
        state.editingFolder = folder._id;
        state.status = "folderCreated";
      })
      .addCase(saveFolders.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(getWorkFolders.pending, (state) => {
        state.status = 'loadingWorkFolders';
      })
      .addCase(getWorkFolders.fulfilled, (state, action) => {
        state.workFolders = [...action.payload];
        state.status = 'workFoldersLoaded';
      })
      .addCase(getWorkFolders.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })

      .addCase(getRoadmapFolders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRoadmapFolders.fulfilled, (state, action) => {
        state.status = 'roadmapFoldersLoaded';
        state.roadmapFolders = [...action.payload];
      })
      .addCase(getRoadmapFolders.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
  },
});

export const getFolder = (state: RootState) => state.folder;
export default FolderSlice.reducer;
