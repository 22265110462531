import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Roadmap } from '../Models/Roadmap';
import { RoadmapCard } from '../Models/RoadmapCard';
import { RoadmapCategory } from '../Models/RoadmapCategory';
import { loadedSubComponentType, roadmapStatus } from '../Helpers/types';
import { CreateRoadmapParams } from '../Models/Requests/CreateRoadmapParams';
import { AddRoadmapCardParams } from '../Models/Requests/AddRoadmapCardParams';
import { ShareRoadmapParams } from '../Models/Requests/ShareRoadmapParams';
import { mapToObject } from '../Helpers/mapToObject';
import { Member } from '../Models/Requests/Member';
import { RoadmapInvitePublicParams } from '../Models/Requests/RoadmapInvitePublicParams';
import { GetRoadmapPublicParam } from '../Models/Requests/GetRoadmapPublicParam';
import { GetRoadmapMemberPublicParam } from '../Models/Requests/GetRoadmapMemberPublicParam';
import { SaveMemberParams } from '../Models/Requests/SaveMemberParams';

export interface RoadmapState {
  roadmaps: Array<Roadmap>;
  categories: Array<RoadmapCategory>;
  cards: Array<RoadmapCard>;
  status: roadmapStatus;
  errorMessage: string;
  members: Array<Member>;
}

const initialState: RoadmapState = {
  roadmaps: [],
  categories: [],
  cards: [],
  status: "unset",
  errorMessage: "",
  members: [],
};

export const getRoadmaps = createAsyncThunk(
  'get-roadmaps',
  async () => {    
    return await axiosPrivate.get('api/roadmap',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getRoadmapCards = createAsyncThunk(
  'get-roadmaps-cards',
  async (roadmapId: string) => {    
    return await axiosPrivate.get('api/roadmap/' + roadmapId + '/card',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getRoadmapCategories = createAsyncThunk(
  'get-roadmaps-categories',
  async (roadmapId: string) => {    
    return await axiosPrivate.get('api/roadmap/' + roadmapId + '/category',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const createRoadmap = createAsyncThunk(
  'api/roadmap/create',
  async (param: CreateRoadmapParams) => {    
    return await axiosPrivate.post('api/roadmap',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const loadRoadmap = createAsyncThunk(
  'api/roadmap/load',
  async (_id: string) => {    
    return await axiosPrivate.post('api/roadmap/load',
      JSON.stringify({
        _id: _id
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const createCategory = createAsyncThunk(
  'api/roadmap/category',
  async (category: RoadmapCategory ) => {    
    return await axiosPrivate.post('api/roadmap/category',
      JSON.stringify(category),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveCategory = createAsyncThunk(
  'api/roadmap/category/update',
  async (category: RoadmapCategory) => {    
    return await axiosPrivate.put('api/roadmap/category',
      JSON.stringify(category),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveRoadmaps = createAsyncThunk(
  'api/roadmap/save',
  async (roadmaps: Array<Roadmap>) => {    
    return await axiosPrivate.post('api/roadmap/save',
      JSON.stringify({
        roadmaps: roadmaps
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveMember = createAsyncThunk(
  'save-member',
  async (params: SaveMemberParams) => {    
    return await axiosPrivate.put('api/roadmap/member',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteMember = createAsyncThunk(
  'delete-member',
  async (params: { roadmapId: string, email: string }) => {    
    return await axiosPrivate.post('api/roadmap/member/delete',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteRoadmap = createAsyncThunk(
  'api/delete/roadmap',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/roadmap/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveRoadmapMode = createAsyncThunk(
  'api/roadmap/mode',
  async (params: { _id: string, mode: loadedSubComponentType }) => {    
    return await axiosPrivate.put('api/roadmap/mode',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const moveRoadmap = createAsyncThunk(
  'api/roadmap/move',
  async (params: { _id: string, folderId: string }) => {    
    return await axiosPrivate.put('api/roadmap/move',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addRoadmapCard = createAsyncThunk(
  'api/roadmap/card',
  async (params: AddRoadmapCardParams) => {    
    return await axiosPrivate.post('api/roadmap/card',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteRoadmapCard = createAsyncThunk(
  'api/delete/card',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/roadmap/card/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveRoadmapCard = createAsyncThunk(
  'api-save-roadmap-card',
  async (card: RoadmapCard) => {    
    return await axiosPrivate.put('api/roadmap/card',
      JSON.stringify(card),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveRoadmap = createAsyncThunk(
  'api-save-roadmap',
  async (roadmap: Roadmap) => {    
    return await axiosPrivate.put('api/roadmap',
      JSON.stringify(roadmap),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteCategory = createAsyncThunk(
  'api-delete-category',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/roadmap/category/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const shareViaEmail = createAsyncThunk(
  'api-roadmap-share',
  async (params: ShareRoadmapParams) => {    
    return await axiosPrivate.post('api/roadmap/share',
      JSON.stringify({
        roadmapId: params.roadmapId,
        projects: (params.projects) ? mapToObject(params.projects) : {},
        emails: params.emails,
        emailBody: params.emailBody,
        permission: params.permission,
        roadmap: params.roadmap,
        futureBoards: params.futureBoards,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

/* private route, has email, etc */
export const getMembers = createAsyncThunk(
  'get-members',
  async (roadmapId: string) => {    
    return await axiosPrivate.get('api/roadmap/' + roadmapId +'/member',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const acceptProjectInvitePublic = createAsyncThunk(
  'accept-public-invite',
  async (params: RoadmapInvitePublicParams) => {    
    return await axiosPublic.post('api/invite',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

/* public route */
export const getRoadmapPublic = createAsyncThunk(
  'get-roadmap-public',
  async (params: GetRoadmapPublicParam) => {    
    return await axiosPublic.get('api/invite/roadmap/' + params.roadmapId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

/* public route */
export const getMembersPublic = createAsyncThunk(
  'get-public-members',
  async ( params: GetRoadmapMemberPublicParam) => {    
    return await axiosPublic.get('api/invite/roadmap/members/' + params.roadmapId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const setRoadmaps = createAsyncThunk(
  'set/roadmaps',
  async (roadmaps: Array<Roadmap>) => {    
      return roadmaps;
});

export const setRoadmapStatus = createAsyncThunk(
  'set/roadmap/status',
  async (status: roadmapStatus) => {    
      return status;
});

export const setRoadmapCards = createAsyncThunk(
  'set-roadmap-cards',
  async (cards: Array<RoadmapCard>) => {    
      return cards;
});

export const setCategories = createAsyncThunk(
  'set-categories',
  async (categories: Array<RoadmapCategory>) => {    
      return categories;
});

export const setMembers = createAsyncThunk(
  'set-members',
  async (members: Array<Member>) => {    
      return members;
});

export const RoadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembersPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMembersPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.members = [...action.payload];
      })
      .addCase(getMembersPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getRoadmapPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRoadmapPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roadmaps = [...action.payload];
      })
      .addCase(getRoadmapPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(acceptProjectInvitePublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acceptProjectInvitePublic.fulfilled, (state, action) => {
        state.status = 'inviteAccepted';
      })
      .addCase(acceptProjectInvitePublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(shareViaEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(shareViaEmail.fulfilled, (state, action) => {
        state.status = 'sharedViaEmail';
        //state.invitesToProjects.push(action.payload);
      })
      .addCase(shareViaEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getMembers.pending, (state) => {
        state.status = 'loadingMembers';
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.members = [...action.payload];
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(createCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.status = 'categoryCreated';
        state.categories.push(action.payload);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addRoadmapCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addRoadmapCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards.push(action.payload);
      })
      .addCase(addRoadmapCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(moveRoadmap.fulfilled, (state, action) => {
        let roadmaps = [...state.roadmaps];
        let index = roadmaps.findIndex(r => r._id === action.payload._id);
        if (index !== -1) {
          roadmaps[index].folderId = action.payload.folderId;
          state.roadmaps = roadmaps;
          state.status = 'roadmapMoved';
        }
      })
      .addCase(setRoadmaps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roadmaps = [...action.payload];
      })
      .addCase(saveRoadmaps.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.roadmaps = action.payload;
      })
      .addCase(setRoadmapStatus.fulfilled, (state, action) => {
        state.status = action.payload;
        //console.log(action.payload);
      })
      .addCase(loadRoadmap.fulfilled, (state, action) => {
        state.status = 'roadmapLoaded';
        let roadmaps = [...state.roadmaps];

        let loadedRoadmap = state.roadmaps.findIndex(r => r.loaded === true);
        if (loadedRoadmap !== -1) {
          roadmaps[loadedRoadmap].loaded = false;
        }

        let roadmapExists = state.roadmaps.findIndex(r => r._id === action.payload._id);
        if (roadmapExists !== -1) {
          roadmaps[roadmapExists].loaded = true;
        }
        state.roadmaps = roadmaps;
      })
      .addCase(saveRoadmapMode.fulfilled, (state, action) => {
        state.status = 'roadmapModeSaved';
        let roadmaps = [...state.roadmaps];
        let roadmapExists = state.roadmaps.findIndex(r => r._id === action.payload._id);
        if (roadmapExists !== -1) {
          roadmaps[roadmapExists].mode = action.payload.mode;
        }
        state.roadmaps = roadmaps;
      })
      .addCase(createRoadmap.fulfilled, (state, action) => {
        //state.status = 'roadmapCreated';
        let roadmaps = [...state.roadmaps];
        let roadmap = action.payload;
        roadmaps.push(roadmap);
        state.roadmaps = roadmaps;
        state.status = 'roadmapCreated';
      })
      .addCase(getRoadmaps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRoadmaps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.roadmaps = [...action.payload];
      })
      .addCase(getRoadmaps.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getRoadmapCategories.pending, (state) => {
        state.status = 'loadingRoadmapCategories';
      })
      .addCase(getRoadmapCategories.fulfilled, (state, action) => {
        state.status = 'idle';
        state.categories = [...action.payload];
      })
      .addCase(getRoadmapCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getRoadmapCards.pending, (state) => {
        state.status = 'loadingRoadmapCards';
      })
      .addCase(getRoadmapCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(getRoadmapCards.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setRoadmapCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(setCategories.fulfilled, (state, action) => {
        state.status = 'idle';
        state.categories = [...action.payload];
      })
      .addCase(setMembers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.members = [...action.payload];
      })
  },
});

export const getRoadmap = (state: RootState) => state.roadmap;
export default RoadmapSlice.reducer;
