import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { createProject } from '../Store/Project';
import { getRoadmap, createCategory, saveCategory, setCategories, deleteCategory, setRoadmapStatus } from '../Store/Roadmap';
import { ContextMenu } from '../Models/ContextMenu';
import { RoadmapCategoryContextMenuParam } from'../Models/Requests/RoadmapCategoryContextMenuParam';
import { colors } from '../Models/Requests/Colors';
import { FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { MdClose } from "react-icons/md";
import PopupContextMenu from './PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';
import { AiOutlineClose } from "react-icons/ai";
import { getProject, setProjects } from '../Store/Project';
/*
import { 
    saveLabel,
    updateLabel,
    setLabels,
    attachLabelToCard,
    detachLabelFromCard,
    setLabelsToCards,
    deleteLabel
} from '../Store/Project';
*/
import { RoadmapCategory, EmptyRoadmapCategory } from '../Models/RoadmapCategory';



interface Props {
    params: RoadmapCategoryContextMenuParam | undefined;    
}

function RoadmapCategoryContextMenu(props: Props) {

    const C_COLOR = "#efefef";
    const C_FONT  = "#000";
    const C_TITLE = "";

    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const projectState = useAppSelector(getProject);
    const [categoryContextMenu, setCategoryContextMenu] = useState<ContextMenu>();
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    const [newCategoryWindow, setNewCategoryWindow] = useState<boolean>(false);
    const [color, setColor] = useState<string>(C_COLOR);
    const [font, setFont] = useState<string>(C_FONT);
    const [title, setTitle] = useState<string>(C_TITLE);
    const [categoryBeingEdited, setCategoryBeingEdited] = useState<string>("");
    const [categorySelected, setCategorySelected] = useState<Array<boolean>>([]);
    const [searchText, setSearchText] = useState<string>("");
    //const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((categoryContextMenu && categoryContextMenu._id !== props.params.roadmapId) || !categoryContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfCategories = roadmapState.categories.length;
                //let menuHeight = 145 + 50 * numberOfLabels;
                //let menuXStart = props.params.event.clientX / 2 - 150;
                let menuXStart = window.outerWidth / 2 - 150;
                //menuXStart = (menuXStart < 0) ? 20 : menuXStart;
                let menuYStart = 100;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                /*if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }*/
                
                contextMenu._id = props.params.roadmapId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setCategoryContextMenu(contextMenu);
            //refreshCategorySelections();
        }
    }, [props.params]);

    useEffect(() => {
        if (roadmapState.status === "categoryCreated") {
            dispatch(setRoadmapStatus("idle"));
            let newCategory = {...roadmapState.categories[roadmapState.categories.length - 1]};
            if (newCategory) {
                /* Add a project with the categoryId */
                dispatch(createProject({
                    name: newCategory.title,
                    roadmapId: newCategory.roadmapId,
                    folderId: "",
                    categoryId: newCategory._id
                }));
                console.log("rs8");
            }
        }
    }, [roadmapState.status]);

    /*
    const refreshCategorySelections = () => {
        // Set checkboxes on categories
        let selectedCategories: Array<boolean> = [];
        roadmapState.categories.forEach(c => {
            if (c.roadmapId === props.params?.roadmapId) {
                selectedCategories[c.id] = true;
            }
        })
        setCategorySelected(selectedCategories);
    }
    */

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setCategoryContextMenu(contextMenu);
        setNewCategoryWindow(false);
        resetSelection();
    }

    const showNewCategoryWindow = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setNewCategoryWindow(true);
    }

    const selectColor = (e: React.MouseEvent<HTMLDivElement>, color: string, font: string, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        setColor(color);
        setFont(font);
    } 

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setTitle(e.currentTarget.value);
    }

    const handleCreateCategory = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        let category = EmptyRoadmapCategory;
        let roadmap = roadmapState.roadmaps.find(r => r._id === props.params?.roadmapId);
        if (roadmap) {
            category.roadmapId = roadmap._id;
            category.title = title;
            category.fontColor = font;
            category.backgroundColor = color;
            dispatch(createCategory(category));
            resetSelection();
            setNewCategoryWindow(false);
        }
    }

    const handleSaveCategory = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();
        e.stopPropagation();

        let categories = [...roadmapState.categories];
        let updatedCategories = categories.map(category => {
            if (category._id === categoryBeingEdited) {
                let updatedCategory = { ...category, title: title, fontColor: font, backgroundColor: color };
                dispatch(saveCategory(updatedCategory));
                return (updatedCategory);
            } else {
                return category
            }
        });

        dispatch(setCategories(updatedCategories));    
        
        /* Update project name that is attached to category */
        let projects = [...projectState.projects];
        let index = projects.findIndex(p => p.categoryId === categoryBeingEdited);
        if (index !== -1) {
            let project = {...projects[index]}; 
            project.name = title;
            projects[index] = project;
            dispatch(setProjects(projects)); 
        }
 
        resetSelection();
        setNewCategoryWindow(false);
    }

    const closeDeleteCategoryPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        /* Key here is to zero-out label */
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteCategoryPopup,
            buttonHandler: deleteCategoryConfirmed,
            message: "",
            headingText: "",
            backButton: true,
        });
    }

    const deleteCategoryConfirmed = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(deleteCategory(categoryBeingEdited));
        /* Remove label from master list */
        let categories = [...roadmapState.categories];
        let removedCategoryIndex = categories.findIndex(c => c._id === categoryBeingEdited);
        if (removedCategoryIndex !== -1) {
            categories.splice(removedCategoryIndex, 1);
            dispatch(setCategories(categories));
            /* Remove project that belongs to the category */
            let projects = [...projectState.projects].filter(p => p.categoryId !== categoryBeingEdited);
            dispatch(setProjects(projects));
            /* Finish up */
            setCategoryBeingEdited("");
            setNewCategoryWindow(false);
        }
        closeDeleteCategoryPopup(e);
    }

    const handleDeleteCategory = (e: React.MouseEvent<HTMLAnchorElement>, categoryId: string) => {
        e.preventDefault();
        e.stopPropagation();

        /* Show the delete confirmation window */
        setPopupParams({
            _id: categoryId,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteCategoryPopup,
            buttonHandler: deleteCategoryConfirmed,
            message: "This will remove the category from the roadmap. There is no undo.",
            headingText: "Delete Category",
            backButton: true,
        });
    }
    /*
    const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        
        e.preventDefault();
        e.stopPropagation();
        let categorySelections = [...categorySelected];

        categorySelections[index] = (categorySelections[index]) ? false : true;
        setCategorySelected(categorySelections);

        if (categorySelections[index] !== true) {
            detachCategory(props.params?.roadmapId, index); 
        }
        
    };
    */
    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchText(e.currentTarget.value);
    }

    const resetSelection = () => {
        setColor(C_COLOR);
        setFont(C_FONT);
        setTitle(C_TITLE);
        setCategoryBeingEdited("");
    }

    const resetColor = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setColor(C_COLOR);
        setFont(C_FONT);
    }

    const editingCategory = (e: React.MouseEvent<HTMLAnchorElement>, category: RoadmapCategory) => {
        e.preventDefault();
        e.stopPropagation();
        setCategoryBeingEdited(category._id);
        setColor(category.backgroundColor);
        setFont(category.fontColor);
        setTitle(category.title);
        setNewCategoryWindow(true);
    }

    const sContextMenu: React.CSSProperties = {
        top: (categoryContextMenu) ? categoryContextMenu.y : 0,
        left: (categoryContextMenu) ? categoryContextMenu.x : 0,
        width: (categoryContextMenu) ? categoryContextMenu.width : 0,
        height: "auto",
        paddingBottom: "15px",
    }

    let sortedColors = colors.sort(({ sort :a }, { sort :b }) => a-b );
    let mappedColors = sortedColors.map((c, i) => {

        const style= {
            backgroundColor: c.hex,
            border: (color === c.hex) ? "2px solid #fff" : "none",
            outline: (color === c.hex) ? "2px solid #2874A6" : "none",
            color: c.font,
        }

        if (i % 4 === 0) {
            return (
                <>
                    <div className="colorContainer"></div>
                    <div className="col1" onClick={(e) => selectColor(e, c.hex, c.font, i)} style={style}></div>
                </>
            );
        } else {
            return (<div className="col2-4" onClick={(e) => selectColor(e, c.hex, c.font, i)} style={style}></div>);
        }
    });
    
    let categories = (searchText === "") ? roadmapState.categories : 
        roadmapState.categories.filter(c => c.title.toLowerCase().includes(searchText.toLowerCase()));
    
    return (
        <>
            {categoryContextMenu && categoryContextMenu._id !== "" &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <>
                        {newCategoryWindow ?
                            <>
                                {categoryBeingEdited === "" ? <h1>Create Category</h1> : <h1>Edit Category</h1>}
                                {newCategoryWindow && 
                                    <div className="divBack">
                                        <Link to="" onClick={(e) => { setNewCategoryWindow(false);resetSelection() } }>
                                            <IoIosArrowBack color="#555" size="13" />
                                        </Link>
                                    </div>
                                }
                                <div className="divClose">
                                    <Link to="" onClick={(e) => closeContextMenu(e)}>
                                        <AiOutlineClose color="#555" size="13" />
                                    </Link>
                                </div>
                                <div className="inputLabel">
                                    Title
                                </div>
                                <div>
                                    <input className="input" type="text" value={title} onChange={(e) => handleTitleChange(e)} />
                                </div>
                                <div className="inputLabel">
                                    Select a color
                                </div>
                                {mappedColors} 
                                <div style={{clear: "both", }}></div>
                                {color !== "#efefef" &&
                                    <div className="divCardOption mt-2 text-center" onClick={(e) => resetColor(e)}>
                                        <span className="label">
                                            <MdClose /> Remove color
                                        </span>
                                    </div>
                                }
                                {categoryBeingEdited === "" ?
                                    <div style={{ marginTop: "10px" }}>
                                        <Link to="" onClick={(e) => handleCreateCategory(e)} className="btn-orange">
                                            Create
                                        </Link>  
                                    </div> 
                                :
                                    <div>
                                        <hr />
                                        <div style={{ float: "left" }}>
                                            <Link to="" onClick={(e) => handleSaveCategory(e)} className="btn-orange">
                                                Save
                                            </Link>  
                                        </div> 
                                        <div style={{ float: "right" }}>
                                            <Link to="" onClick={(e) => handleDeleteCategory(e, categoryBeingEdited)} className="btn-black">
                                                Delete
                                            </Link>  
                                        </div> 
                                        <div style={{ clear: "both"}}></div>
                                    </div>
                                }
                            </>
                        :
                            <>
                                <h1 style={{ marginBottom: "0px" }}>Categories</h1>
                                <div className="divClose">
                                    <Link to="" onClick={(e) => closeContextMenu(e)}>
                                        <AiOutlineClose color="#555" size="13" />
                                    </Link>
                                </div>
                                <div>
                                    <input className="input" type="text" placeholder="Search categories..." 
                                        autoFocus
                                        onChange={(e) => handleSearchText(e)}
                                        value={searchText}
                                    />
                                </div>
                                {categories.map((c, i) => {
                                    let style = {
                                        backgroundColor: c.backgroundColor,
                                        color: c.fontColor,
                                        borderRadius: "30px",
                                        cursor: "pointer",
                                    }

                                    let checked = (categorySelected[parseInt(c._id)]) ? true : false;
                                    
                                    return (
                                        <>
                                            {i === 0 && <h1 className="mt-2 mb-2">Existing</h1>}
                                            <div className="row mt-1 g-0" key={i}>
                                                {/*
                                                <div className="col-2 text-center">
                                                    <input type="checkbox"
                                                        key={Math.random()}
                                                        checked={checked}
                                                        onChange={(e) => handleCategoryChange(e, c.id)}
                                                        style={{
                                                            position: "relative", top:"3px", border: "1px solid #efefef" 
                                                        }} 
                                                    />
                                                </div>
                                                */}
                                                <div className="divColorLabel col-10" style={style}>
                                                    <span className="label" style={{ cursor: "pointer"}}>
                                                        {c.title}
                                                    </span>
                                                </div>
                                                <div className="col-2 text-center">
                                                    <Link to="" onClick={(e) => editingCategory(e, c)}>
                                                        <FaRegEdit color="#5D6D7E" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                                <div className="divCardOption mt-2 text-center" 
                                    style={{ borderRadius: "30px" }}
                                    onClick={(e) => showNewCategoryWindow(e)}>
                                    <span className="label" style={{ cursor: "pointer" }}>
                                        Create a new category
                                    </span>
                                </div>
                            </>
                        }
                        </>
                    </div>
                </div>
            }
            <PopupContextMenu params={popupParams} />
        </>
    );
}

export default RoadmapCategoryContextMenu;