import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { createProject } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { ProjectContextMenuParams } from '../Models/Requests/ProjectContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { getFolder } from '../Store/Folder';

interface Props {
    params: ProjectContextMenuParams | undefined;    
}

function ProjectContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const folderState = useAppSelector(getFolder);
    const [name, setName] = useState("");
    const [folderId, setFolderId] = useState("");
    const [projectContextMenu, setProjectContextMenu] = useState<ContextMenu>();

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((projectContextMenu && projectContextMenu._id !== props.params._id) || !projectContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu._id = props.params._id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setProjectContextMenu(contextMenu);
           /*
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
                if (roadmap) {
                    setName(roadmap.name);
                    setUom(roadmap.uom);
                    setUomNumber(roadmap.uomNumber);
                }
            }
                */
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setName("");
        setFolderId("");
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setProjectContextMenu(contextMenu);
    }
    
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    /*
    const handleUomChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUom(e.currentTarget.value)
    }
    const handleUomNumberChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUomNumber(parseInt(e.currentTarget.value))
    }
    */
    const handleCreateProject = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.params) {
            dispatch(createProject({
                name: name,
                roadmapId: props.params._id,
                folderId: folderId,
                categoryId: ""
            }));
            closeContextMenu();
        }
    }

    const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFolderId(e.currentTarget.value);
    }

    const sContextMenu: React.CSSProperties = {
        top: (projectContextMenu) ? projectContextMenu.y : 0,
        left: (projectContextMenu) ? projectContextMenu.x : 0,
        width: (projectContextMenu) ? projectContextMenu.width : 3000,
        height: "auto",
        paddingBottom: "15px",
    }

    let roadmapId = (props.params) ? props.params._id : "";

    let folders = [...folderState.workFolders].filter(f => f.roadmapId === roadmapId);
    folders = folders.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return (
        <>
        {projectContextMenu && projectContextMenu._id !== "" &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>New Project</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div>
                            <input type="text" 
                                className="standardInput"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => handleNameChange(e)} 
                                />
                        </div>
                        {folders.length > 0 &&
                            <div>
                                <select 
                                    className="standardInput"
                                    value={folderId}
                                    onChange={(e) => handleFolderChange(e)}>
                                        <option value={0}>Folder (optional)</option>
                                        {folders.map((f,i) => {
                                            return (
                                                <option key={i} value={f._id}>{f.name}</option>
                                            );
                                        })}
                                </select>
                            </div>
                        }
                        {/*
                        <div>
                            <select 
                                className="standardInput"
                                value={uom}
                                onChange={(e) => handleUomChange(e)}>
                                <option value="">Timeline</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option value="daily">Daily</option>
                            </select>
                        </div>
                        <div>
                            <select 
                                className="standardInput"
                                value={uomNumber}
                                onChange={(e) => handleUomNumberChange(e)}>
                                <option value={0}>Number of Periods</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                            </select>
                        </div>
                        */}
                        <div style={{ textAlign: "left" }}>
                            <Link to="" onClick={(e) => handleCreateProject(e)} className="btn-orange">
                                Create
                            </Link> 
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default ProjectContextMenu;