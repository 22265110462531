import { useState, useEffect} from 'react';
import { useAppSelector } from '../Core/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { getUser } from '../Store/Auth';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import SettingsContext from "../Components/SettingsContext";
import { SettingsContextParam } from "../Models/Requests/SettingsContextParam";

function Navigation() {

    const [isOpen, setIsOpen] = useState(false);
    const user = useAppSelector(getUser);
    const navigate = useNavigate();
    const style = (isOpen) ? { marginTop: "20px", marginBottom: "20px" } : {};
    const [logoPath, setLogoPath] = useState("/");
    const [settingsContextParams, setSettingsContextParams] = useState<SettingsContextParam>();

    useEffect(() => {
        setLogoPath("/");
        setSettingsContextParams(undefined);
    }, [user.accessToken]);

    return (
        <>
            <header className="navigation bgc sticky-top">
                <Navbar className="navbar navbar-expand-md navbar-light" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <Link to={logoPath} onClick={() => setIsOpen(false)} className="text-white fw-bold fs-4 logoLink">
                        <div>
                            <img className="pb-2 pt-2" style={{ width: "50px", position: "relative", top: "5px", left: "10px" }} src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
                        </div>
                        <div style={{ position: 'relative', top: '-15px', left: '10px'}}>
                            <img className="pb-2 pt-2" style={{ width: "55px" }} src="/images/herdr-white-logo-text-v1.png" alt="herdr.io" />
                        </div>
                    </Link>
                    {user.accessToken === "" &&
                        <>
                            <Link className="registerButton" to="" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); navigate("/" ); }}>
                                Join Waitlist
                            </Link>
                            <Link className="loginButton" to="/login">
                                Login
                            </Link>
                            <Link className="pricingLink" to="/pricing">
                                Pricing
                            </Link>
                        </>
                    }
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2 custom-toggler"/>
                </Navbar>
            </header>
            <SettingsContext params={settingsContextParams} />
        </>
    );
}

export default Navigation;