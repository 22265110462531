import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getRoadmap, addRoadmapCard, saveRoadmapCard, setRoadmapCards } from '../Store/Roadmap';
import { ContextMenu } from '../Models/ContextMenu';
import { AddRoadmapCardContextMenuParams } from '../Models/Requests/AddRoadmapCardContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { RoadmapCategory } from '../Models/RoadmapCategory';
import { getQuarter } from 'date-fns';

interface Props {
    params: AddRoadmapCardContextMenuParams | undefined;    
}

function AddRoadmapCardContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [addRoadmapCardContextMenu, setAddRoadmapCardContextMenu] = useState<ContextMenu>();
    const [months, setMonths] = useState<Map<number, string>>();
    const [toMonth, setToMonth] = useState(-1);
    const [fromMonth, setFromMonth] = useState(-1);
    const [error, setError] = useState("");

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((addRoadmapCardContextMenu && addRoadmapCardContextMenu._id !== props.params.id.toString()) || !addRoadmapCardContextMenu) {

                let menuWidth = 300;
                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                if (menuXStart + 300 > window.outerWidth) {
                    menuXStart = window.outerWidth - 400;
                }

                contextMenu._id = props.params.id.toString();
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;

                let spotCheck = props.params.id;
                if (props.params.card) {
                    spotCheck = getQuarter(new Date(`1970/${props.params.card.startPeriod+1}/07`));
                    setName(props.params.card.title);
                    setCategory(props.params.card.categoryId);
                    setFromMonth(props.params.card.startPeriod);
                    setToMonth(props.params.card.startPeriod + props.params.card.periodsToSpan - 1);
                }

                /* Set the month drop down based on the Quarter being clicked on roadmap */
                let monthMap = new Map(months);
                if (monthMap) {
                    monthMap.clear();
                    if (spotCheck === 1 || props.params.mode === "edit") {
                        
                        monthMap.set(0, "January");
                        monthMap.set(1, "February");
                        monthMap.set(2, "March");
                        monthMap.set(3, "April");
                        monthMap.set(4, "May");
                        monthMap.set(5, "June");
                        monthMap.set(6, "July");
                        monthMap.set(7, "August");
                        monthMap.set(8, "September");
                        monthMap.set(9, "October");
                        monthMap.set(10, "November");
                        monthMap.set(11, "December");
                        setMonths(monthMap);
                        
                    } else if (spotCheck === 2) {

                        monthMap.set(3, "April");
                        monthMap.set(4, "May");
                        monthMap.set(5, "June");
                        monthMap.set(6, "July");
                        monthMap.set(7, "August");
                        monthMap.set(8, "September");
                        monthMap.set(9, "October");
                        monthMap.set(10, "November");
                        monthMap.set(11, "December");
                        setMonths(monthMap);

                    } else if (spotCheck === 3) {

                        monthMap.set(6, "July");
                        monthMap.set(7, "August");
                        monthMap.set(8, "September");
                        monthMap.set(9, "October");
                        monthMap.set(10, "November");
                        monthMap.set(11, "December");
                        setMonths(monthMap);

                    } else if (spotCheck === 4) {

                        monthMap.set(9, "October");
                        monthMap.set(10, "November");
                        monthMap.set(11, "December");
                        setMonths(monthMap);

                    }
                }

            }
            setAddRoadmapCardContextMenu(contextMenu);
        }
    }, [props.params]);

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 2000);
        }
    }, [error]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setName("");
        setCategory("");
        setToMonth(-1);
        setFromMonth(-1);
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setAddRoadmapCardContextMenu(contextMenu);
    }
    
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(e.currentTarget.value);
    }

    const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>, field: string) => {
        if (field === "to") {
            setToMonth(parseInt(e.currentTarget.value));
        } else {
            setFromMonth(parseInt(e.currentTarget.value));
        }
    }

    const handleAddRoadmapCard = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (name === "") {
            setError("Please enter a Name");
            return;
        }

        if (fromMonth === -1 || toMonth === -1) {
            setError("Please enter Months");
            return;
        }

        if (props.params) {
            let uomNumberToSpan = toMonth - fromMonth + 1;
            dispatch(addRoadmapCard({
                roadmapId: props.params.roadmap._id,
                categoryId: category,
                name: name,
                startPeriod: fromMonth,
                periodsToSpan: toMonth - fromMonth + 1,
                index: determineSlot("add", fromMonth, uomNumberToSpan)
            }));
            closeContextMenu();
        }
    }

    const handleSaveRoadmapCard = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (name === "") {
            setError("Please enter a Name");
            return;
        }

        if (fromMonth === -1 || toMonth === -1) {
            setError("Please enter Months");
            return;
        }

        if (props.params) {
            let card = props.params.card;
            if (card) {
                //console.log(card);
                let tempCard = {...card};
                let uomNumberToSpan = toMonth - fromMonth + 1;
                tempCard.categoryId = category;
                tempCard.title = name;
                console.log(card.startPeriod + "!==" + fromMonth);
                console.log(toMonth + "!==" + card.periodsToSpan);
                if (card.startPeriod !== fromMonth || card.periodsToSpan !== uomNumberToSpan) {
                    let slot = determineSlot("edit", fromMonth, uomNumberToSpan);

                    if (slot === -1) {
                        setError("No room for card");
                        return;
                    }

                    tempCard.startPeriod = fromMonth;
                    tempCard.periodsToSpan = uomNumberToSpan;
                    tempCard.index = slot;
                }

                dispatch(saveRoadmapCard(tempCard));

                let cards = [...roadmapState.cards];
                let index = cards.findIndex(c => c._id === tempCard._id);
                if (index !== -1) {
                    cards[index] = tempCard;
                    dispatch(setRoadmapCards(cards));
                }

                closeContextMenu();
            }
        }
        
    }

    /*************************************************************************
     * Determine the slot to place the card in. We will store this slot in the
     * card's index field and use it for showing on the roadmap. The slot will
     * be the starting slot of the card if it spans multiples slots.
     *************************************************************************/

    const determineSlot = (mode: "add" | "edit", startPeriod: number, slotsToSpan: number): number => {
        if (props.params) {
            if (props.params.roadmap) {

                let roadmapCards = roadmapState.cards.filter(c => c.roadmapId === props.params!.roadmap._id);
                if (!roadmapCards) return startPeriod;

                let slotStart = 0;
                if (mode === "add") {
                    slotStart = startPeriod;
                } else {
                    if (props.params.card) {
                        slotStart = props.params.card.index + (startPeriod - props.params.card.startPeriod);
                    }
                }

                let slotEnd = slotStart + slotsToSpan - 1;
                let slot = slotStart;
                while (slot <= slotEnd) {

                    let indexTaken = roadmapCards.find(c =>   
                        slot >= c.index && slot <= c.index + c.periodsToSpan - 1 ||
                        slotEnd >= c.index && slotEnd <= c.index + c.periodsToSpan - 1 ||
                        c.index >= slot && c.index <= slotEnd
                    )

                    if (mode === "edit") {
                        let cardsInSlots = roadmapCards.filter(c => 
                            slot >= c.index && slot <= c.index + c.periodsToSpan - 1 ||
                            slotEnd >= c.index && slotEnd <= c.index + c.periodsToSpan - 1 ||
                            c.index >= slot && c.index <= slotEnd
                        )    
                        console.log(cardsInSlots);
                        if (cardsInSlots.length > 1) {
                            return -1; /* we tried to expand the current card into another */
                        }             
                    }

                    if (indexTaken) {
                        let skip = false;
                        if (props.params.card) {
                            if (indexTaken._id === props.params.card._id) {
                                skip = true;
                            }
                        }
                        if (!skip) {
                            slot += 12;
                            slotEnd += 12;
                            continue;
                        }
                    }
                    return slot;
                }
                return startPeriod;
            }
        }
        return startPeriod;
    }

    let mode = props.params?.mode;
    let categories: Array<RoadmapCategory> = [];

    if (props.params) {
        categories = roadmapState.categories.filter(c => c.roadmapId === props.params!.roadmap._id);
        categories = categories.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
    }
        
    const sContextMenu: React.CSSProperties = {
        top: (addRoadmapCardContextMenu) ? addRoadmapCardContextMenu.y : 0,
        left: (addRoadmapCardContextMenu) ? addRoadmapCardContextMenu.x : 0,
        width: (addRoadmapCardContextMenu) ? addRoadmapCardContextMenu.width : 300,
        height: "auto",
        paddingBottom: "15px",
    }

    let numberOfUomToSpan = "";

    if (props.params) {
        if (props.params.roadmap) {
            let roadmap = props.params.roadmap;
            if (roadmap.uom === "quarterly") {
                numberOfUomToSpan = "Quarters";
            } else if (roadmap.uom === "monthly") {
                numberOfUomToSpan = "Months";
            } else if (roadmap.uom === "weekly") {
                numberOfUomToSpan = "Weeks";
            } else if (roadmap.uom === "daily") {
                numberOfUomToSpan = "Days";
            }
        }
    }

    let fromMonthOptions: Array<JSX.Element> = [];
    let toMonthOptions: Array<JSX.Element | undefined> = [];
    if (months) {
        fromMonthOptions = Array.from(months).map(([key, value]) => {
            return (
                <option key={key} value={key}>
                    {value}
                </option>
            );
        });
        toMonthOptions = Array.from(months).map(([key, value]) => {
            if (key >= fromMonth) {
                return (
                    <option key={key} value={key}>
                        {value}
                    </option>
                );
            }   
        });
    }

    return (
        <>
        {addRoadmapCardContextMenu && addRoadmapCardContextMenu._id !== "" &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>
                        {mode === "edit" ? "Edit Card" : "Add Card" }
                    </h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    {error !== "" &&
                        <div className="error2">
                            {error}
                        </div>
                    }
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div>
                            <input type="text" 
                                className="addContainerInput"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => handleNameChange(e)} 
                                />
                        </div>
                        {categories.length > 0 &&
                            <div>
                                <select 
                                    className="addContainerInput"
                                    value={category}
                                    onChange={(e) => handleCategoryChange(e)}>
                                    <option value={0}>Uncategorized</option>
                                    {categories.map(c => {
                                        return (
                                        <option key={c._id} value={c._id}>
                                            {c.title}
                                        </option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        <div>
                            <select 
                                className="addContainerInput"
                                value={fromMonth}
                                onChange={(e) => handleMonthChange(e, "from")}>
                                    <option key="start-month" value={-1}>From</option>
                                {fromMonthOptions}
                            </select>
                        </div>
                        <div>
                            <select 
                                className="addContainerInput"
                                value={toMonth}
                                onChange={(e) => handleMonthChange(e, "to")}>
                                    <option key="end-month" value={-1}>To</option>
                                {toMonthOptions}
                            </select>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            {mode === "edit" ? 
                                <Link to="" onClick={(e) => handleSaveRoadmapCard(e)} className="btn-orange">
                                    Save
                                </Link>
                            :
                                <Link to="" onClick={(e) => handleAddRoadmapCard(e)} className="btn-orange">
                                    Add
                                </Link>
                            } 
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default AddRoadmapCardContextMenu;