import { IBaseModel } from './IBaseModel';

export interface RoadmapCategory extends IBaseModel {
    roadmapId: string;
    title: string;
    backgroundColor: string;
    fontColor: string;
}

export const EmptyRoadmapCategory = {
    _id: "",
    roadmapId: "",
    created: new Date(),
    modified: new Date(),
    active: false,
    title: "",
    backgroundColor: "",
    fontColor: "",
}