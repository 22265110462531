import React, { CSSProperties } from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Core/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
import { getRoadmap, getRoadmapPublic, getMembersPublic } from "../../Store/Roadmap";
import NotFound404 from './NotFound404';
import Avatar from '../../Components/Avatar';

function RoadmapInvite() {

    const dispatch = useAppDispatch();
    const {roadmap, link } = useParams();
    const roadmapState = useAppSelector(getRoadmap);

    useEffect(() => {
        if (roadmap && link) {
            dispatch(getRoadmapPublic({ roadmapId: roadmap, link: link }));
            dispatch(getMembersPublic({ roadmapId: roadmap, link: link }));
        }
    }, []);

    let members = roadmapState.members.filter(m => m.active);
    const owner = members.find(o => o.memberType?.toString() === 'owner');

    //console.log(roadmapState.roadmaps);
    //console.log(members.length);

    const avatars = members.map(m => {
        let style: CSSProperties = { 
            display: "inline-block",
            marginRight: "1px",
            position: "relative",
            top: (m.avatar) ? "8px" : "0px"
        }
        return (
            <div style={style}>
                <Avatar size="small" member={m} inline={true} />
            </div>
        )
    });

    /* We use Roadmap not found errror below as well because if the user is logged in, but visits an
       invalid share link, the roadmaps array will still load all their roadmaps and thus would show
       them an invalid share page, so we also use the error which will be last thrown by the dispatches
       in this page. 

    if (roadmapState.roadmaps.length === 0 || roadmapState.errorMessage === "Roadmap not found.") {
        return (<NotFound404 />)
    }
        */
        

    if (roadmap && roadmapState.roadmaps.length > 0) {

        let loginLink = "https://herdr.io/login/" + roadmap + "/" + link;
        let registerLink = "https://herdr.io/register/" + roadmap + "/" + link;

        return (
            <div className="pt-4 text-center">
                <div style={{ paddingBottom: "10px" }}>
                    <img src="/images/herdr-orange-logo-cat-v1.png" style={{ width: "80px" }} alt="Herdr" />    
                </div>
                <div style={{ fontSize: "18pt" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {owner?.firstName + " " + owner?.lastName}
                    </span> 
                    <span>
                        &nbsp;shared workspace&nbsp;
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                        {roadmapState.roadmaps[0].name}
                    </span>
                    <span>
                        &nbsp;with you.
                    </span>
                </div>
                <div style={{ marginTop: "15px" }}>
                    {avatars}
                </div>
                <div style={{ marginTop: "15px" }}>
                    Join {owner?.firstName + " " + owner?.lastName} and others
                    in collaborating on this work.
                </div>
                <div style={{ textAlign: "center", marginTop: "15px", marginBottom: "35px" }}>
                    <a href={registerLink} className="inviteBtnOrange">
                        Sign Up
                    </a>
                    <a href={loginLink} className="inviteBtnGray">
                        Login
                    </a>
                </div>
            </div>
        );
    } else {
        // just to satisfy App that this component can't be undefined
        return (<div></div>);
    }
}

export default RoadmapInvite;