import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { createProject, moveProject } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { MoveProjectContextMenuParams } from '../Models/Requests/MoveProjectContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { getFolder } from '../Store/Folder';
import { getProject } from '../Store/Project';

interface Props {
    params: MoveProjectContextMenuParams | undefined;    
}

function MoveProjectContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const folderState = useAppSelector(getFolder);
    const projectState = useAppSelector(getProject);
    //const [name, setName] = useState("");
    const [folderId, setFolderId] = useState("");
    //const [uom, setUom] = useState("Quarter");
    //const [uomNumber, setUomNumber] = useState(0);
    const [projectContextMenu, setProjectContextMenu] = useState<ContextMenu>();

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((projectContextMenu && projectContextMenu._id !== props.params._id) || !projectContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu._id = props.params._id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setProjectContextMenu(contextMenu);
           /*
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
                if (roadmap) {
                    setName(roadmap.name);
                    setUom(roadmap.uom);
                    setUomNumber(roadmap.uomNumber);
                }
            }
                */
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setFolderId("");
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setProjectContextMenu(contextMenu);
    }
    
    const handleMoveProject = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params) {
            dispatch(moveProject({
                _id: props.params._id,
                folderId: folderId
            }));
            closeContextMenu();
        }
    }

    const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFolderId(e.currentTarget.value);
    }

    const sContextMenu: React.CSSProperties = {
        top: (projectContextMenu) ? projectContextMenu.y : 0,
        left: (projectContextMenu) ? projectContextMenu.x : 0,
        width: (projectContextMenu) ? projectContextMenu.width : 3000,
        height: "auto",
        paddingBottom: "15px",
    }

    let projectId = (props.params) ? props.params._id : "";
    let project = projectState.projects.find(p => p._id === projectId);
    let roadmapId = (project) ? project.roadmapId : "";
    let existingFolderId = (project) ? project.folderId : "";

    let folders = [...folderState.workFolders].filter(f => f.roadmapId === roadmapId && f._id !== existingFolderId);
    folders = folders.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return (
        <>
        {projectContextMenu && projectContextMenu._id !== "" &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Move Project</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div>
                            <select 
                                className="standardInput"
                                value={folderId}
                                onChange={(e) => handleFolderChange(e)}>
                                    <option value={0}>No Folder</option>
                                    {folders.map((f,i) => {
                                        return (
                                            <option key={i} value={f._id}>{f.name}</option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <Link to="" onClick={(e) => handleMoveProject(e)} className="btn-orange">
                                Move
                            </Link> 
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default MoveProjectContextMenu;