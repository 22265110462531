import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { Roadmap } from '../Models/Roadmap';
import SharingContext from "../Modals/SharingContext";
import { SharingContextParam } from "../Models/Requests/SharingContextParam";
import { getRoadmap, getMembers } from "../Store/Roadmap";
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { BsThreeDots } from "react-icons/bs";
import Avatar from './Avatar';
import { format } from 'date-fns';
import { getSettings } from '../Store/Settings';
import { MemberContextMenuParam } from '../Models/Requests/MemberContexMenuParam';
import MemberContextMenu from '../Modals/MemberContextMenu';

interface Props {
    roadmap: Roadmap;
}

function RoadmapMember(props: Props) {

    //const [showShare, setShowShare] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);
    const [sharingContextParams, setSharingContextParams] = useState<SharingContextParam>();
    const [memberContextMenuParams, setMemberContextMenuParams] = useState<MemberContextMenuParam>();

    useEffect(() => {
        dispatch(getMembers(props.roadmap._id));
        setSharingContextParams(undefined);
    },[])

    useEffect(() => {
        if (roadmapState.status === "sharedViaEmail") {
            dispatch(getMembers(props.roadmap._id));
            console.log("rs1");
        }
    },[roadmapState.status])

    const toggleMemberContextMenu = (e: React.MouseEvent<HTMLAnchorElement>, roadmapId: string, email: string) => {
        e.preventDefault();
        setMemberContextMenuParams({ roadmapId: roadmapId, email: email, event: e });  
    };

    const handleShowShare = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        //setShowShare(!showShare);
        setSharingContextParams({ _id: props.roadmap._id, email: "", event: e });
    }

    const editMember = (e: React.MouseEvent<HTMLDivElement>, email: string) => {
        //e.preventDefault();
        //e.stopPropagation();

        alert("You clicked to edit member: " + email);
    }

    let col1 = "40%";
    let col2 = "20%";
    let col3 = "20%";
    let col4 = "20%";

    let roadmap = roadmapState.roadmaps.find(r => r.loaded);
    let searchText = (settingsState.settings.searchText) ? settingsState.settings.searchText.toLowerCase() : "";
    
    let filteredMembers = (settingsState.settings.searchText) ? roadmapState.members.filter(m => 
        m.firstName.toLowerCase().includes(searchText) ||
        m.lastName.toLowerCase().includes(searchText) ||
        m.email.toLowerCase().includes(searchText) ||
        (m.memberType && m.memberType.toString().toLowerCase().includes(searchText))
    ) : roadmapState.members;


    let members = filteredMembers.map((m, i) => {
        if (roadmap) {
            let name = (m.firstName) ? m.firstName.charAt(0).toUpperCase() + m.firstName.slice(1) + " " + m.lastName.charAt(0).toUpperCase() + m.lastName.slice(1) : "Pending Invite";
            let status = (m.firstName !== "") ? (m.active) ? "Active" : "Inactive" : "Invited";
            let temp = {...m};
            let avatarAdjustment = "-10px";
            if (m.avatar === "" && m.defaultAvatarColor === "") {
                temp.firstName = "Pending";
                temp.lastName = "Invite";
                temp.defaultAvatarColor = "#000";
                temp.defaultAvatarFontColor = "#fff";
            }
            if (m.avatar !== "") {
                avatarAdjustment = "-1px";
            }
            let sBadge: CSSProperties = {
                position: "relative",
                top: "-1px",
                marginLeft: "5px",  
                fontSize: "8pt",
                textTransform: "uppercase",
                borderRadius: "30px",
                padding: "2px 5px",
            };

            if (m.memberType?.toLowerCase() === "owner") {
                sBadge.backgroundColor = "#d35400";
                sBadge.color = "#fff";
            } else if (m.memberType?.toLowerCase() === "admin") {
                sBadge.backgroundColor = "#2980b9";
                sBadge.color = "#fff";
            } else if (m.memberType?.toLowerCase() === "member") {
                sBadge.backgroundColor = "#16a085";
                sBadge.color = "#fff";
            } else {
                sBadge.backgroundColor = "#f1c40f";
                sBadge.color = "#fff";
            }

            let meDiv = undefined;
            let sBadgeMe = {...sBadge};
            if (m.me) {
                sBadgeMe.backgroundColor = "#8e44ad";
                sBadgeMe.color = "#fff";
                meDiv = (<span style={sBadgeMe}>ME</span>);
            }

            let rowStyle = {
                width: "100%", 
                display: "flex", 
                alignItems: "center", 
                marginTop: "10px",
                //padding: "10px",
                //backgroundColor: (i % 2 === 0) ? "#f5f4f4" : "#fff"
            }

            return (
            <div style={rowStyle}>
                <div style={{ width: `${col1}`, display: "inline-block" }}>
                    <div style={{ display: "inline-block", position: "relative", top: `${avatarAdjustment}`, marginRight: "10px" }}>
                        <Avatar size="small" member={temp} />
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <div style={{ fontSize: "14pt", marginBottom: "-5px" }}>
                            {(name === "Pending Invite") ? <i>{name}</i> : name}
                            {meDiv}
                            <span style={sBadge}>{m.memberType}</span>
                        </div>
                        <div style={{ fontSize: "10pt", fontWeight: "600" }}>{m.email}</div>
                    </div>
                </div>
                <div style={{ width: `${col2}`, display: "inline-block"}}>
                    {(m.lastLogin) ? format(new Date(m.lastLogin), "M/d/yy") : ""}
                </div>
                <div style={{ width: `${col3}`, display: "inline-block"}}>
                    {status}
                </div>
                <div style={{ width: `${col4}`, fontSize: "18pt", lineHeight: "18pt", 
                    display: "inline-block", textAlign: "right", paddingRight: "10px" }}>
                    {m.memberType?.toString().toLowerCase() !== "owner" &&
                    <Link to="" onClick={(e) => toggleMemberContextMenu(e, roadmap!._id, m.email)} style={{ color: "#000" }}>
                        <BsThreeDots size={24} />
                    </Link>
                    }
                </div>
            </div>
            );
        }
    });

    return (
        <div>
            <div className="rightContentDefault" style={{ padding: "15px 25px", overflowY: "auto" }}>
                <div>
                    <div style={{ display: "inline-block" }}>
                        <h3>Members</h3>
                        <div>Manage access to this roadmap and its projects.</div>
                    </div>
                    <div style={{ display: "inline-block", float: "right", marginTop: "10px" }}>
                        <Link to="" className="btn-orange"
                            onClick={e => handleShowShare(e)}>
                            Invite users
                        </Link>
                    </div>
                </div>
                <div style={{ marginTop: "10px", marginBottom: "10px", fontSize: "11pt", fontWeight: "bold" }}>
                    <div style={{ width: `${col1}`, display: "inline-block"}}>
                        User
                    </div>
                    <div style={{ width: `${col2}`, display: "inline-block"}}>
                        Last Active
                    </div>
                    <div style={{ width: `${col3}`, display: "inline-block"}}>
                        Status
                    </div>
                    <div style={{ width: `${col4}`, display: "inline-block"}}>
                    </div>
                </div>
                <hr style={{ margin: "0px 0px 5px 0px" }} />
                {members}
            </div>
            <SharingContext params={sharingContextParams} />
            <MemberContextMenu params={memberContextMenuParams} />
        </div>
    );
}

export default RoadmapMember;