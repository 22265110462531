import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { 
    getProject,
    setCards,
    saveCard
} from '../Store/Project';
import { getRoadmap } from '../Store/Roadmap';
import { ContextMenu } from '../Models/ContextMenu';
import { AssignItemContextMenuParam } from'../Models/Requests/AssignItemContextMenuParam';
import { AiOutlineClose } from "react-icons/ai";
import { ProjectCard } from '../Models/ProjectCard';
import { Card } from 'reactstrap';

interface Props {
    params: AssignItemContextMenuParam | undefined;    
}

function AssignItemContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const [moveCardContextMenu, setMoveCardContextMenu] = useState<ContextMenu>();
    const [whereToMove, setWhereToMove] = useState<string>("");
    const [selectedItemId, setSelectedItemId] = useState<string>("");
    const [error, setError] = useState("");

    useEffect(() => {
        if (props.params) {
            setWhereToMove("");
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((moveCardContextMenu && moveCardContextMenu._id !== props.params.cardId) || !moveCardContextMenu) {

                let menuWidth = window.outerWidth / 5;
                let menuHeight = 205; //window.outerHeight * .4;
                let menuXStart = props.params.event.clientX - menuWidth - 80;
                let menuYStart = 10;
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                
                contextMenu._id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }

            let card = projectState.cards.find(c => c._id === props.params?.cardId);
            if (card) {
                setSelectedItemId(card.roadmapItem);
            } else {
                setSelectedItemId("");
            }

            setMoveCardContextMenu(contextMenu);
        }
    }, [props.params]);

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 2000);
        }
    }, [error]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setMoveCardContextMenu(contextMenu);
    }

    const handleSaveCard = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();
        e.stopPropagation();

        if (selectedItemId !== "") {
            let card = projectState.cards.find(c => c._id === props.params?.cardId);
            if (card) {
                let tempCard = {...card};
                tempCard.roadmapItem = selectedItemId;
                let cards = [...projectState.cards];
                let exists = projectState.cards.findIndex(c => c._id === tempCard._id);
                if (exists !== -1) {
                    cards[exists] = tempCard;
                    dispatch(setCards(cards)); // update frontend
                    dispatch(saveCard(tempCard)); // update backend
                    closeContextMenu(e);
                }
            }
        } else {
            setError("Select an item");
        }
    };

    const selectItemId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedItemId(e.currentTarget.value);
    }

    const sContextMenu: React.CSSProperties = {
        top: (moveCardContextMenu) ? moveCardContextMenu.y : 0,
        left: (moveCardContextMenu) ? moveCardContextMenu.x : 0,
        width: (moveCardContextMenu) ? moveCardContextMenu.width : 0,
        height: (moveCardContextMenu) ? moveCardContextMenu.height : 0,
    }

    let project = projectState.projects.find(p => p.loaded);
    let cards = roadmapState.cards.filter(c => c.roadmapId === project?.roadmapId && 
        c.categoryId === project?.categoryId).sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

    return (
        <>
            {moveCardContextMenu && moveCardContextMenu._id !== "" &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <h1 style={{ marginBottom: "0px" }}>Roadmap Item</h1>
                        <div className="divClose">
                            <Link to="" onClick={(e) => closeContextMenu(e)}>
                                <AiOutlineClose color="#555" size="13" />
                            </Link>
                        </div>
                        {error !== "" &&
                            <div className="error2">
                                {error}
                            </div>
                        }
                        <h2 className="mt-2" style={{ fontSize: "12pt" }}>
                            Assign To
                        </h2>
                        <div>
                            <select className="moveCardList" value={selectedItemId} onChange={(e) =>selectItemId(e)}>
                                <option key={0} value={0}>-- select list --</option>
                                {cards.map(c => {
                                    return (
                                        <option key={c.title} value={c._id}>{c.title}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div style={{ marginTop: "12px" }}>
                            <Link to="" onClick={(e) => handleSaveCard(e)} className="btn-orange">
                                Save
                            </Link>
                        </div> 
                    </div>
                </div>
            }
        </>
    );
}

export default AssignItemContextMenu;