import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Project } from '../Models/Project';
import { ProjectCard } from '../Models/ProjectCard';
import { ProjectList } from '../Models/ProjectList';
import { LabelToCard } from '../Models/LabelToCard';
import { Label } from '../Models/Label';
import { MComment } from '../Models/MComment';
import { loadedSubComponentType, projectStatus } from '../Helpers/types';
import { CreateProjectParams } from '../Models/Requests/CreateProjectParams';
import { NewListParams } from '../Models/Requests/NewListParams';
import { SaveListParams } from '../Models/Requests/SaveListParams';
import { MinimizeListParams } from '../Models/Requests/MinimizeListParams';
import { NewCardParams } from '../Models/Requests/NewCardParams';
import { ChangeCardWorkParams } from '../Models/Requests/ChangeCardWorkParams';
import { Member } from '../Models/Requests/Member';
import { ChangeCardOwnerParams } from '../Models/Requests/ChangeCardOwnerParams';

export interface ProjectState {
  projects: Array<Project>;
  lists: Array<ProjectList>;
  cards: Array<ProjectCard>;
  labels: Array<Label>;
  labelsToCards: Array<LabelToCard>;
  comments: Array<MComment>;
  members: Array<Member>;
  status: projectStatus;
  errorMessage: string;
  projectSwitchingStatus: boolean;
}

const initialState: ProjectState = {
  projects: [],
  lists: [],
  cards: [],
  labels: [],
  labelsToCards: [],
  comments: [],
  members: [],
  status: "unset",
  errorMessage: "",
  projectSwitchingStatus: false,
};

export const minimizeList = createAsyncThunk(
  'minimize-list',
  async (params: MinimizeListParams) => {    
    return await axiosPrivate.put('api/project/list/minimize',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addList = createAsyncThunk(
  'add-project-list',
  async (params: NewListParams ) => {    
    return await axiosPrivate.post('api/project/list',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getLists = createAsyncThunk(
  'get-lists',
  async (projectId: string) => {    
    return await axiosPrivate.get('api/project/' + projectId +'/list',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveList = createAsyncThunk(
  'save-list',
  async (param: SaveListParams) => {    
    return await axiosPrivate.put('api/project/list',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getProjects = createAsyncThunk(
  'get-projects',
  async (roadmapId: string) => {    
    return await axiosPrivate.get('api/project/' + roadmapId,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const setProjects = createAsyncThunk(
  'set-projects',
  async (projects: Array<Project>) => {    
      return projects;
});

export const createProject = createAsyncThunk(
  'api-project-create',
  async (param: CreateProjectParams) => {    
    return await axiosPrivate.post('api/project',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const loadProject = createAsyncThunk(
  'api/project/load',
  async (_id: string) => {    
    return await axiosPrivate.post('api/project/load',
      JSON.stringify({
        _id: _id
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const unloadProject = createAsyncThunk(
  'api/project/unload',
  async (_id: string) => {    
    return await axiosPrivate.put('api/project/unload',
      JSON.stringify({
        _id: _id
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const moveProject = createAsyncThunk(
  'api/project/move',
  async (params: { _id: string, folderId: string }) => {    
    return await axiosPrivate.put('api/project/move',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveProjectMode = createAsyncThunk(
  'api/project/mode',
  async (params: { _id: string, mode: loadedSubComponentType }) => {    
    return await axiosPrivate.put('api/project/mode',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveProjects = createAsyncThunk(
  'api-project-save',
  async (projects: Array<Project>) => {    
    return await axiosPrivate.post('api/project/save',
      JSON.stringify({
        projects: projects 
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteProject = createAsyncThunk(
  'api-delete-project',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/project/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveProject = createAsyncThunk(
  'api-save-project',
  async (project: Project) => {    
    return await axiosPrivate.put('api/project',
      JSON.stringify(project),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveListObject = createAsyncThunk(
  'save-list-object',
  async (list: ProjectList ) => {    
    return await axiosPrivate.put('api/project/list/object',
      JSON.stringify(list),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addCard = createAsyncThunk(
  'add-card',
  async (params: NewCardParams ) => {    
    return await axiosPrivate.post('api/project/card',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getCards = createAsyncThunk(
  'get-cards',
  async (projectId: string) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/card',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveCard = createAsyncThunk(
  'save-card',
  async (card: ProjectCard ) => {    
    return await axiosPrivate.put('api/project/card',
      JSON.stringify(card),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const copyCard = createAsyncThunk(
  'copy-card',
  async (card: ProjectCard ) => {
    return await axiosPrivate.post('api/project/card/copy',
      JSON.stringify(card),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteList = createAsyncThunk(
  'delete-list',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/project/list/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveComment = createAsyncThunk(
  'save-comment',
  async (comment: MComment ) => {    
    return await axiosPrivate.put('api/project/comment',
      JSON.stringify(comment),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getComments = createAsyncThunk(
  'get-comments',
  async (projectId: string) => {    
    return await axiosPrivate.get('api/project/' + projectId +'/comment',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteCard = createAsyncThunk(
  'delete-card',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/project/card/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteComment = createAsyncThunk(
  'delete-comment',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/project/comment/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveLabel = createAsyncThunk(
  'save-label',
  async (label: Label ) => {    
    return await axiosPrivate.post('api/project/label',
      JSON.stringify(label),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const updateLabel = createAsyncThunk(
  'update-label',
  async (label: Label) => {    
    return await axiosPrivate.put('api/project/label',
      JSON.stringify(label),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteLabel = createAsyncThunk(
  'delete-label',
  async (_id: string) => {    
    return await axiosPrivate.delete('api/project/label/' + _id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const attachLabelToCard = createAsyncThunk(
  'attach-label-to-card',
  async (labelToCard: LabelToCard ) => {    
    return await axiosPrivate.post('api/project/label/attach',
      JSON.stringify(labelToCard),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const detachLabelFromCard = createAsyncThunk(
  'detach-label-from-card',
  async (labelToCard: LabelToCard ) => {    
    return await axiosPrivate.post('api/project/label/detach',
      JSON.stringify(labelToCard),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getLabelsToCards = createAsyncThunk(
  'get-labeltocard',
  async (projectId: string) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/labeltocard',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getLabels = createAsyncThunk(
  'get-labels',
  async (projectId: string) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/label',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const changeCardWork = createAsyncThunk(
  'change-card-work',
  async (param: ChangeCardWorkParams) => {    
    return await axiosPrivate.put('api/project/card/work',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeCardOwner = createAsyncThunk(
  'change-card-owner',
  async (param: ChangeCardOwnerParams) => {    
    return await axiosPrivate.put('api/project/card/owner',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const setProjectStatus = createAsyncThunk(
  'set-project-status',
  async (status: projectStatus) => {    
      return status;
});

export const setLists = createAsyncThunk(
  'set-lists',
  async (lists: Array<ProjectList>) => {    
      return lists;
});

export const setCards = createAsyncThunk(
  'set-cards',
  async (cards: Array<ProjectCard>) => {    
      return cards;
});

export const setComments = createAsyncThunk(
  'set-comments',
  async (comments: Array<MComment>) => {    
      return comments;
});

export const setLabels = createAsyncThunk(
  'set-labels',
  async (labels: Array<Label>) => {    
      return labels;
});

export const setLabelsToCards = createAsyncThunk(
  'set-labelstocards',
  async (labelsToCards: Array<LabelToCard>) => {    
      return labelsToCards;
});

export const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeCardOwner.fulfilled, (state, action) => {
        state.status = 'cardOwnerChanged';
        let card: ProjectCard = action.payload;
        let cardExists = state.cards.findIndex(c => c._id === card._id);
        if (cardExists !== -1) {
          let cards = [...state.cards];
          cards[cardExists] = card;
          state.cards = cards;
        }
      })
      .addCase(changeCardWork.fulfilled, (state, action) => {
        let card: ProjectCard = action.payload;
        let cardExists = state.cards.findIndex(c => c._id === card._id);
        if (cardExists !== -1) {
          let cards = [...state.cards];
          cards[cardExists] = card;
          state.cards = cards;
        }
        state.status = "workChanged";
      })
      .addCase(getLabels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labels = [...action.payload];
      })
      .addCase(getLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getLabelsToCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLabelsToCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labelsToCards = [...action.payload];
      })
      .addCase(getLabelsToCards.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setLabelsToCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labelsToCards = [...action.payload];
      })
      .addCase(detachLabelFromCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(detachLabelFromCard.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(detachLabelFromCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(attachLabelToCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(attachLabelToCard.fulfilled, (state, action) => {
        state.status = 'labelAttachedToCard';
        state.labelsToCards.push(action.payload);
      })
      .addCase(attachLabelToCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setLabels.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labels = [...action.payload];
      })
      .addCase(saveLabel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveLabel.fulfilled, (state, action) => {
        state.status = 'labelAdded';
        state.labels.push(action.payload);
      })
      .addCase(saveLabel.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setComments.fulfilled, (state, action) => {
        state.status = 'idle';
        state.comments = [...action.payload];
      })
      .addCase(getComments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.status = 'idle';
        state.comments = [...action.payload];
      })
      .addCase(getComments.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveComment.fulfilled, (state, action) => {
        state.status = 'idle';
        let comment = action.payload;
        let commentExists = state.comments.findIndex(c => c._id === comment._id);
        if (commentExists !== -1) {
          // existing comment, update in place
          let comments = [...state.comments];
          comments[commentExists] = comment;
          state.comments = comments;
        } else {
          // newly added comment, add to beginning of array
          state.comments.unshift(action.payload);
        }
      })
      .addCase(deleteList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = [...action.payload];
      })
      .addCase(deleteList.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(copyCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(copyCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(copyCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(getCards.pending, (state) => {
        state.status = 'loadingCards';
      })
      .addCase(getCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
        state.projectSwitchingStatus = false;
      })
      .addCase(getCards.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards.push(action.payload);
      })
      .addCase(addCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setLists.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = [...action.payload];
      })
      .addCase(minimizeList.fulfilled, (state, action) => {
        let list = action.payload;
        let listExists = state.lists.findIndex(l => l._id === list._id);
        if (listExists !== -1) {
          let lists = [...state.lists];
          lists[listExists] = list;
          state.lists = lists;
        }
      })
      .addCase(saveList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = action.payload;
      })
      .addCase(getLists.pending, (state) => {
        state.status = 'loadingLists';
      })
      .addCase(getLists.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = [...action.payload];
      })
      .addCase(getLists.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addList.fulfilled, (state, action) => {
        state.status = 'listAdded';
        state.lists = [...action.payload];
      })
      .addCase(addList.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setProjects.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = [...action.payload];
      })
      .addCase(saveProjects.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.roadmaps = action.payload;
      })
      .addCase(setProjectStatus.fulfilled, (state, action) => {
        state.status = action.payload;
        //console.log(action.payload);
      })
      .addCase(loadProject.pending, (state) => {
        state.projectSwitchingStatus = true;
      })
      .addCase(loadProject.fulfilled, (state, action) => {
        //state.status = 'projectLoaded';
        let projects = [...state.projects];
        let loadedProject = state.projects.findIndex(p => p.loaded);
        if (loadedProject !== -1) {
          projects[loadedProject].loaded = false;
        }

        let projectExists = state.projects.findIndex(p => p._id === action.payload._id);
        if (projectExists !== -1) {
          projects[projectExists].loaded = true;
        }
        state.projects = projects;
        state.status = 'projectLoaded';
      })
      .addCase(moveProject.fulfilled, (state, action) => {
        state.status = 'idle';
        let projects = [...state.projects];
        let projectExists = state.projects.findIndex(p => p._id === action.payload._id);
        if (projectExists !== -1) {
          projects[projectExists].folderId = action.payload.folderId;
        }
        state.projects = projects;
      })
      .addCase(saveProjectMode.fulfilled, (state, action) => {
        state.status = 'idle';
        let projects = [...state.projects];
        let projectExists = state.projects.findIndex(p => p._id === action.payload._id);
        if (projectExists !== -1) {
          projects[projectExists].mode = action.payload.mode;
        }
        state.projects = projects;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status = 'projectCreated';
        let projects = [...state.projects];
        let project = action.payload;
        projects.push(project);
        state.projects = projects;
      })
      .addCase(getProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.status = 'projectsLoaded';
        state.projects = [...action.payload];
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
  },
});

export const getProject = (state: RootState) => state.project;
export default ProjectSlice.reducer;
