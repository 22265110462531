import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, changeCardOwner } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { ChangeCardOwnerContextMenuParams } from '../Models/Requests/ChangeCardOwnerContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { getRoadmap } from '../Store/Roadmap';

interface Props {
    params: ChangeCardOwnerContextMenuParams | undefined;    
}

function ChangeCardOwnerContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const [ownerId, setOwnerId] = useState("");
    const [changeCardOwnerContextMenu, setChangeCardOwnerContextMenu] = useState<ContextMenu>();

    useEffect(() => {
        if (projectState.status === "cardOwnerChanged") {
            closeContextMenu();
        }
    }, [projectState.status]);

    useEffect(() => {
        //console.log(props.params);
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((changeCardOwnerContextMenu && changeCardOwnerContextMenu._id !== props.params.cardId) || !changeCardOwnerContextMenu) {

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }

                contextMenu._id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
            }
            setChangeCardOwnerContextMenu(contextMenu);
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setChangeCardOwnerContextMenu(contextMenu);
    }

    const handleChangeCardOwner = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params && props.params.cardId && ownerId !== "empty") {
            dispatch(changeCardOwner({
                ownerId: ownerId,
                cardId: props.params.cardId,
            }));
            closeContextMenu();
        }
    }

    const handleOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setOwnerId(e.currentTarget.value);
    }

    const sContextMenu: React.CSSProperties = {
        top: (changeCardOwnerContextMenu) ? changeCardOwnerContextMenu.y : 0,
        left: (changeCardOwnerContextMenu) ? changeCardOwnerContextMenu.x : 0,
        height: "auto",
        paddingBottom: "15px",
    }

    const card = projectState.cards.find(c => c._id === props.params?.cardId);
    const sortedMembers = roadmapState.members.filter(m => m.active && m.userId && m.userId !== card?.ownerId).sort(
            (a,b) => a.firstName.toLocaleLowerCase().localeCompare(b.firstName.toLocaleLowerCase()));

    const mappedMembers = sortedMembers.map((member, index) => {
        return (
            <option value={member.userId}>{member.firstName} {member.lastName}</option>
        );
    });

    return (
        <>
        {card && changeCardOwnerContextMenu && changeCardOwnerContextMenu._id !== "" &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Change Card Owner</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <select style={{ padding: "5px", border: "1px solid #efefef", marginRight: "10px" }}
                            value={ownerId}
                            onChange={(e) => handleOwnerChange(e)}>
                            <option value={"empty"}>Select New Owner</option>
                            {card.ownerId !== "" &&
                                <option value={""}>Clear Owner</option>
                            }
                            {mappedMembers}
                        </select>
                        <Link to="" onClick={(e) => handleChangeCardOwner(e)} className="btn-orange">
                            Save
                        </Link>  
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default ChangeCardOwnerContextMenu;