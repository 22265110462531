import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap, loadRoadmap, setRoadmapStatus, setRoadmaps, deleteRoadmap } from '../Store/Roadmap';
 import { createProject, getProject, getProjects, setProjectStatus } from '../Store/Project';
import { loadComponent, getSettings, toggleRoadmaps, toggleChat } from '../Store/Settings';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import ChatSubNav from './ChatSubNav';
import RoadmapContextMenu from '../Modals/RoadmapContextMenu';
import MoveRoadmapContextMenu from '../Modals/MoveRoadmapContextMenu';
import { RoadmapContextMenuParams } from '../Models/Requests/RoadmapContextMenuParams';
import { MoveRoadmapContextMenuParams } from '../Models/Requests/MoveRoadmapContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
//import { setTimeout } from 'timers/promises';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaTrash, FaFolder } from 'react-icons/fa';
import { FaRegFolder, FaRegFolderClosed, FaRegFolderOpen } from "react-icons/fa6";
import { PiFolderSimpleDuotone, PiFolderSimple } from "react-icons/pi";
import PopupContextMenu from '../Modals/PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';
import { createFolder, expandFolder, deleteFolder, saveFolders,
    getFolder, setFolderStatus, saveFolderName, setRoadmapFolders
 } from '../Store/Folder';
import { Folder } from '../Models/Folder';
import { PiLineVerticalThin } from "react-icons/pi";
import { rearrangeFoldersAndFiles, onFolderFileDragEnd } from '../Helpers/dragAndDropFilesAndFolders';

function RoadmapSubNav() {
/*
    interface FileFolder {
        type: string;
        id: number;
        index: number;
        folderId: number;
        expanded: boolean;
    }
*/
    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);
    const folderState = useAppSelector(getFolder);
    const projectState = useAppSelector(getProject);
    const [search, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    const [roadmapContextMenuParams, setRoadmapContextMenuParams] = useState<RoadmapContextMenuParams>();
    const [moveRoadmapContextMenuParams, setMoveRoadmapContextMenuParams] = useState<MoveRoadmapContextMenuParams>();
    const [editingFolder, setEditingFolder] = useState<string>("");
    const [editingFolderValue, setEditingFolderValue] = useState("");
    const [roadmapId, setRoadmapId] = useState<string>("");

    useEffect(() => {
        //console.log("roadmaps.length: " + roadmapState.roadmaps.length + " roadmapFolders: " + folderState.roadmapFolders.length);
        saveFilesAndFolders();
        //console.log("rs9");
    },[roadmapState.roadmaps.length, folderState.roadmapFolders.length]);

    useEffect(() => {
        if (roadmapState.status === "roadmapMoved") {
            saveFilesAndFolders();
            setRoadmapStatus("idle");
            console.log("rs2");
        }
    },[roadmapState.status]);

    useEffect(() => {
        if (projectState.status === "projectsLoaded" && roadmapId !== "") {
            setProjectStatus("idle");
            dispatch(loadRoadmap(roadmapId));
            setRoadmapId("");
            console.log("rs3");
        }
    }, [projectState.status]);

    useEffect(() => {
        if (roadmapState.status === "roadmapLoaded") {
            dispatch(setRoadmapStatus("idle"));
            handleLoadComponent(dispatch, "roadmap");
            console.log("rs4");
        } else if (roadmapState.status === "roadmapCreated") {
            dispatch(setRoadmapStatus("idle"));
            /* if the roadmaps section is compresssed and someone adds one, open it
               so they can see their new roadmap. */
            if (!settingsState.settings.roadmapsExpanded) {
                dispatch(toggleRoadmaps(true));
            }

            let roadmaps = [...roadmapState.roadmaps];
            // _id fix below, may not work roadmaps.sort(function(a, b) { return b.id - a.id });
            roadmaps.sort(function(a, b) { return b.sort - a.sort });

            /* If new roadmap was created in a folder, expand the folder so they
               know it was created. */
            if (roadmaps.length > 0) {
                let folders = [...folderState.roadmapFolders];
                let folder = folders.find(f => f._id === roadmaps[0].folderId);
                if (folder) {
                    let newFolder = Object.assign({}, folder);
                    newFolder.expanded = true;
                    let folderIndex = folders.findIndex(f => f._id === roadmaps[0].folderId);
                    if (folderIndex !== -1) {
                        folders.splice(folderIndex,1);
                        folders.splice(folderIndex,0,newFolder);
                        dispatch(setRoadmapFolders(folders));
                        dispatch(expandFolder({ _id: roadmaps[0].folderId, expanded: true })); 
                    }
                }   
            }
            /* create uncategorized project */
            if (settingsState.settings.projectCreation === "roadmap" || 
                settingsState.settings.projectCreation === "roadmap_item"
            ) {
                if (roadmaps.length > 0) {
                    dispatch(createProject({
                        name: "Uncategorized",
                        roadmapId: roadmaps[0]._id,
                        folderId: "",
                        categoryId: ""
                    }));
                }
            }
            console.log("rs5");
        }
    }, [roadmapState.status]);

    useEffect(() => {
        if (folderState.status === "folderCreated") {
            dispatch(setFolderStatus("idle"));
            if (folderState.roadmapFolders.length > 0) {
                setEditingFolder(folderState.roadmapFolders[0]._id);
                setEditingFolderValue(folderState.roadmapFolders[0].name);
            }
        }
    }, [folderState.status]);

    const saveFilesAndFolders = () => {
        if (roadmapState.roadmaps.length > 0 || folderState.roadmapFolders.length > 0) {
            let folders = [...folderState.roadmapFolders].filter((f => f.type === "roadmap"));
            rearrangeFoldersAndFiles(dispatch, folders, [...roadmapState.roadmaps], 'roadmap');
        }
    }

    const handleToggleRoadmaps = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        //dispatch(toggleChat(false));
        dispatch(toggleRoadmaps(!settingsState.settings.roadmapsExpanded));
    }

    const handleShowAddRoadmap = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setRoadmapContextMenuParams({ event: e, _id: "1" }); 
        //setRoadmapContextMenuParams({ event: e, id: 1 });   
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>  {
        e.preventDefault();
        setSearchText(e.currentTarget.value);
    }

    const handleLoadRoadmap = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        //e.stopPropagation();
        /* If we are loading a different roadmap than the one loaded, load
           it and it's projects, else just move on.
         */
        let loadedRoadmap = roadmapState.roadmaps.find(r => r.loaded);
        if (loadedRoadmap && loadedRoadmap._id === id) {
            handleLoadComponent(dispatch, "roadmap");
        } else {
            setRoadmapId(id);
            dispatch(getProjects(id));
        }
    }

    const handleMoveToFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        setMoveRoadmapContextMenuParams({ event: e, _id: id });   
    }

    const handleDeleteRoadmap = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Show the delete confirmation window */
        setPopupParams({
            _id: id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteRoadmapPopup,
            buttonHandlerWithId: deleteRoadmapConfirmed,
            message: "This will delete this roadmap. There is no undo.",
            headingText: "Delete Roadmap",
            backButton: true,
        });
    }

    const closeDeleteRoadmapPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteRoadmapPopup,
            buttonHandlerWithId: deleteRoadmapConfirmed,
            message: "",
            headingText: "",
            backButton: true,
        });
    }

    const deleteRoadmapConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Remove roadmap from frontend */
        let roadmaps = [...roadmapState.roadmaps];
        let index = roadmaps.findIndex(r => r._id === id);
        if (index !== -1) {
            /* Remove from frontend */
            roadmaps.splice(index, 1);
            dispatch(setRoadmaps(roadmaps));
            /* Remove from backend */
            dispatch(deleteRoadmap(id));
            closeDeleteRoadmapPopup(e);
        }
    }

    const createRoadmapFolder = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(createFolder({ type: 'roadmap', roadmapId: "" }));
    }

    const handleShowRenameFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        let folder = folderState.roadmapFolders.find(f => f._id === id);
        if (folder) {
            setEditingFolder(folder._id);
            setEditingFolderValue(folder.name);
        }
    }

    const handleToggleFolder = (e: React.MouseEvent<HTMLDivElement>, id: string, expanded: boolean) => {
        e.preventDefault();   
        e.stopPropagation();

        /* Update the frontend */
        let folders = [...folderState.roadmapFolders];
        let folder = folders.find(f => f._id === id);
        if (folder) {
            let newFolder = Object.assign({}, folder);
            newFolder.expanded = expanded;
            let folderIndex = folders.findIndex(f => f._id === id);
            if (folderIndex !== -1) {
                folders.splice(folderIndex,1);
                folders.splice(folderIndex,0,newFolder);
                dispatch(setRoadmapFolders(folders));
                /* update the backend */
                dispatch(expandFolder({ _id: id, expanded: expanded }));
                /* Under consideration: Reindex all the roadmaps for the user when a folder is expanded or
                   collapsed because it affects drag and drop */
            }
        }
    }

    const handleFolderNameChange = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {  
        //e.preventDefault();
        
        let key = "";
        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            key = keypress.key;
            //if (keypress.key === 'Enter') {
             //   return;
            //}            
        }
        
        if (key === 'Enter' || e.type !== "keydown") {
            /* save frontend to prevent delay */
            let folders = [...folderState.roadmapFolders];
            let folder = folders.find(f => f._id === editingFolder);
            if (folder) {
                let newFolder = Object.assign({}, folder);
                newFolder.name = editingFolderValue;
                let folderIndex = folders.findIndex(f => f._id === editingFolder);
                if (folderIndex !== -1) {
                    folders.splice(folderIndex,1);
                    folders.splice(folderIndex,0,newFolder);
                    //folders[folderIndex].name = editingFolderValue;
                    dispatch(setRoadmapFolders(folders));
                }
            }

            /* save backend */
            dispatch(saveFolderName({ _id: editingFolder, name: editingFolderValue }));
            setEditingFolder("");
            setEditingFolderValue("");
        }
    }

    const handleEditingFolderName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditingFolderValue(e.currentTarget.value);
    }

    const handleDeleteFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Show the delete confirmation window */
        setPopupParams({
            _id: id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteFolderPopup,
            buttonHandlerWithId: deleteFolderConfirmed,
            message: "Delete the folder? If it contains files, this will move the files to no folder",
            headingText: "Delete Folder",
            backButton: false,
        });
    }

    const closeDeleteFolderPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteFolderPopup,
            buttonHandlerWithId: deleteFolderConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }

    const deleteFolderConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Remove roadmap from frontend */
        let folders = [...folderState.roadmapFolders];
        let index = folders.findIndex(f => f._id === id);
        if (index !== -1) {
            /* Remove the folder from the fronted */
            folders.splice(index, 1);
            dispatch(setRoadmapFolders(folders));
            /* Remove all roadmaps from under the folder on the frontend */
            let roadmaps = [...roadmapState.roadmaps];
            roadmaps.forEach(r => {
                if (r.folderId === id) {
                    let newRoadmap = Object.assign({}, r);
                    newRoadmap.folderId = "";
                    let index = roadmaps.findIndex(r => r._id === r._id);
                    if (index !== -1) {
                        roadmaps.splice(index,1);
                        roadmaps.splice(index,0,newRoadmap);
                        dispatch(setRoadmaps(roadmaps));
                    }
                }
            })
            /* Remove from backend */
            dispatch(deleteFolder(id));
            closeDeleteFolderPopup(e);
        }
    }

    let folders = [...folderState.roadmapFolders].filter((f => f.type === "roadmap"));
    //let folders = [...folderState.allFolders].filter((f => f.type === "roadmap"));
    folders.sort(function(a, b) { return a.sort - b.sort });

    let roadmaps = (search && searchText !== "") ? [...roadmapState.roadmaps.filter(r => r.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))] : [...roadmapState.roadmaps];
    roadmaps.sort(function(a, b) { return a.sort - b.sort });

    let navItems: Array<JSX.Element> = [];
    //let key = 0;
    //console.log(key);
    /* Load roadmaps that belong to folders */

    let counter = (() => {
        let count = 0;
        return () => count++;
    })();

    folders.forEach((f,i) => {
        //console.log(!f.expanded);
        //console.log(key);
        const folderKey = counter();
        const key = "folder-" + f._id;
        navItems.push(
            <Draggable key={key} draggableId={"folder-" + f._id.toString()} index={folderKey}>
            {(provided, snapshot) => (
                <div className="roadmapNavFolder"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div style={{ display: "inline-block", position: "relative", top: "-8px" }}>
                        {f.expanded ?
                            <FaRegFolderOpen color="#bec1c3" size={15} />
                        :
                            <FaRegFolderClosed color="#bec1c3" size={15} />
                        }
                        {/*<FaFolder color="#bec1c3" size={15} />*/}
                    </div>
                    {editingFolder === f._id ?
                        <div style={{ display: "inline-block", paddingLeft: "10px", color: "#bec1c3",
                            textOverflow: "ellipsis", width: "calc(80%)", overflow: "hidden", whiteSpace: "nowrap" }}>
                            <input type="text" value={editingFolderValue} className="standardInput2" 
                                autoFocus
                                onChange={(e) => handleEditingFolderName(e)}
                                onKeyDown={(e) => handleFolderNameChange(e)} 
                                onBlur={(e) => handleFolderNameChange(e)} />
                        </div>
                    :
                        <div style={{ display: "inline-block", paddingLeft: "10px", color: "#bec1c3",
                            textOverflow: "ellipsis", width: "calc(80%)", overflow: "hidden", whiteSpace: "nowrap" }}
                            onClick={(e) => handleShowRenameFolder(e, f._id)}
                            onContextMenu={(e) => handleDeleteFolder(e, f._id)}>
                            {f.name}
                        </div>
                    }
                    <div style={{ float: "right", paddingLeft: "5px" }}
                        onClick={(e) => handleToggleFolder(e, f._id, !f.expanded)}>
                        {f.expanded ? 
                            <VscTriangleUp size={18} color="#bec1c3" />
                        :
                            <VscTriangleDown size={18} color="#bec1c3" />
                        }
                    </div>
                </div>
            )}
            </Draggable>
        );
        
        if (f.expanded) {
            roadmaps.filter(r => r.folderId === f._id).forEach((r,i) => {
                //key++;
                //console.log(key);
                const roadmapKey = counter();
                const key = "file-" + r._id;
                navItems.push(
                    <Draggable key={key} draggableId={"file-" + r._id.toString()} index={roadmapKey}>
                        {(provided, snapshot) => (
                        <div
                            className="roadmapNavItem"
                            onClick={(e) => handleLoadRoadmap(e, r._id)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div style={{ display: "inline-block" }}>
                                <PiLineVerticalThin color="#bec1c3" size={16} />
                            </div>    
                            {r.name}
                            <div style={{ right: "30px"}} className="trashIcon" onClick={(e) => handleMoveToFolder(e, r._id)}>
                                <FaFolder size="16" color="#909495" />
                            </div>
                            <div className="trashIcon" onClick={(e) => handleDeleteRoadmap(e, r._id)}>
                                <FaTrash size="14" color="#909495" />
                            </div>
                        </div>
                        )}
                    </Draggable>
                );
            });
        }
        //key++;
        //console.log(key);
    });

    /* Load roadmaps that do not belong to a folder */
    //roadmaps.filter(r => r.folderId === 0 || !r.folderId).forEach((r,i) => {
    roadmaps.filter(r => r.folderId === "" || !r.folderId).forEach((r,i) => {
        const roadmapKey = counter();
        const key = "file-" + r._id;
        navItems.push(
            <Draggable key={key} draggableId={"file-" + r._id.toString()} index={roadmapKey}>
            {(provided, snapshot) => (
                <div 
                    className="roadmapNavItem"
                    onClick={(e) => handleLoadRoadmap(e, r._id)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {r.name}
                    <div style={{ right: "30px"}} className="trashIcon" onClick={(e) => handleMoveToFolder(e, r._id)}>
                        <FaFolder size="16" color="#909495" />
                    </div>
                    <div className="trashIcon" onClick={(e) => handleDeleteRoadmap(e, r._id)}>
                        <FaTrash size="14" color="#909495" />
                    </div>
                </div>
                )}
            </Draggable>
        );
        //key++;
        //console.log(key);
    });


    //console.log(navItems);
/*
    let formattedItems = roadmaps.map((r, i) => {
        return (
            <Draggable key={r.id} draggableId={r.id.toString()} index={i}>
                {(provided, snapshot) => (
                <div
                    onClick={(e) => handleLoadRoadmap(e, r.id)}
                    className="roadmapNavItem"
                    key={r.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {r.name}
                    <div className="trashIcon" onClick={(e) => handleDeleteRoadmap(e, r.id)}>
                        <FaTrash size="14" color="gray" />
                    </div>
                </div>
                )}
            </Draggable>
        );
    });
*/
    return (
        <>
        <div className="leftSubNavWrapper">
            <div style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "15px" }}>
                {/*
                <div style={{ width: "10%", display: "inline-block", position: "relative", top: "-2px" }}>
                    <IoMapOutline size={18} color="#909495" />
                </div>*/}
                <div style={{ fontWeight: "500", color: "#F8F9FA", width: "40%",fontSize: "12pt", display: "inline-block" }}>
                    Roadmaps
                </div>
                <div style={{ width: "60%", display: "inline-block", textAlign: "right" }}>
                    {!search ?
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => setShowSearch(true)}>
                            <BiSearch size={16} color="#909495" />
                        </div>
                    :
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => { setSearchText(""); setShowSearch(false) }}>
                            <AiOutlineClose size={16} color="#909495" />
                        </div>
                    }
                    <div style={{ display: "inline-block", paddingRight: "10px", cursor: "pointer" }}
                        onClick={(e) => createRoadmapFolder(e)}>
                        <FaRegFolder size={14} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "0px", cursor: "pointer" }}
                        onClick={(e) => handleShowAddRoadmap(e)}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                    {/*
                    <div style={{ display: "inline-block", position: "relative", top: "1px", cursor: "pointer" }}
                        onClick={(e) => handleToggleRoadmaps(e)}>
                        {settingsState.settings.roadmapsExpanded ? 
                            <VscTriangleUp size={18} color="#909495" />
                        :
                            <VscTriangleDown size={18} color="#909495" />
                        }
                    </div>
                    */}
                </div>
            </div>
            {settingsState.settings.roadmapsExpanded &&
                <hr style={{ width: "94%", color: "#909495", margin: "15px auto 0px auto" }} />
            }
            {search &&
                <div style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "20px" }}>
                    <input type="text" placeholder="Search roadmaps..." className="navSearch"
                        value={searchText}
                        onChange={(e) => handleSearchChange(e)} />
                </div>
            }

            {settingsState.settings.roadmapsExpanded &&
                <div className="roadmapsExpanded" style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "10px",
                    height: "80vh", overflowY: "scroll"
                 }}>
                    {roadmapState.status === "idle" && folders.length === 0 && roadmaps.length === 0 ?
                        <div style={{ textAlign: "center", fontStyle: "italic" }}>
                            no roadmaps to show
                        </div>
                    :
                    <DragDropContext onDragEnd={(e) => onFolderFileDragEnd(e, dispatch, folders, roadmaps, 'roadmap')}>                
                        <Droppable droppableId={"1"}>
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {navItems}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    }
                </div>
            }
            {/*
            <ChatSubNav />
            */}
        </div>
        <PopupContextMenu params={popupParams} />
        <RoadmapContextMenu params={roadmapContextMenuParams} />
        <MoveRoadmapContextMenu params={moveRoadmapContextMenuParams} />
        </>
    );
}

export default RoadmapSubNav;