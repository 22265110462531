import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

//import Footer from './Components/Footer';
//import Login from './Pages/Public/Login';
import Home from './Pages/Public/Home';
//import MemberHome from './Pages/Private/MemberHome';
import Privacy from './Pages/Public/Privacy';
import Terms from './Pages/Public/Terms';
import Products from './Pages/Public/Products';
import WhyHerdr from './Pages/Public/WhyHerdr';
import Team from './Pages/Public/Team';
import Pricing from './Pages/Public/Pricing';
import TechStartupFounder from './Pages/Public/TechStartupFounder';
import CompanyExecutives from './Pages/Public/CompanyExecutives';
import ProductDesigner from './Pages/Public/ProductDesigner';
import CreativeAgency from './Pages/Public/CreativeAgency';
import Freelancer from './Pages/Public/Freelancer';
import BootstrappedEntrepreneur from './Pages/Public/BootstrappedEntrepreneur';
import NonProfit from './Pages/Public/NonProfit';
import AcademicResearchTeam from './Pages/Public/AcademicResearchTeam';
import SoftwareDevelopmentTeam from './Pages/Public/SoftwareDevelopmentTeam';
import ConsultantCoach from './Pages/Public/ConsultantCoach';
import EcommerceTeam from './Pages/Public/EcommerceTeam';
import RoadmapInvite from './Pages/Public/RoadmapInvite';
import NotFound404 from './Pages/Public/NotFound404';
import Login from './Pages/Public/Login';
import Register from './Pages/Public/Register';
import Forgot from './Pages/Public/Forgot';
import Recover from './Pages/Public/Recover';
import Dash from './Pages/Private/Dash';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import { ToastContainer } from 'react-toastify';
import { refreshAccessToken, getUser, getAvatar } from './Store/Auth';
import { getRoadmap, getRoadmaps, getRoadmapCards, getRoadmapCategories, 
  getMembers, setRoadmapStatus } from './Store/Roadmap';
import { getRoadmapFolders, getWorkFolders } from './Store/Folder';
import { getProjects, getProject, getLists, getCards, getComments, 
  getLabelsToCards, getLabels } from './Store/Project';
import { getUserSettings, heartBeat } from './Store/Settings';
import { useAppSelector, useAppDispatch } from './Core/hooks';
import { getFolder, setFolderStatus } from './Store/Folder';
import { getSettings } from './Store/Settings';

interface ComponentProps {
  children ?: React.ReactNode;
}

type Props = ComponentProps;

function App(props: Props) {
  
  const dispatch = useAppDispatch();
  const userState = useAppSelector(getUser);
  const folderState = useAppSelector(getFolder);
  const roadmapState = useAppSelector(getRoadmap);
  const projectState = useAppSelector(getProject);
  const settingsState = useAppSelector(getSettings);

  useEffect (() => {
    dispatch(refreshAccessToken());
    /* hearbeat to stay logged in */
    window.setInterval(() => {
      dispatch(heartBeat());
    }, 60000);
  },[]);

  useEffect (() => {
    if (userState.accessToken !== "" && roadmapState.roadmaps.length === 0) {
      dispatch(getAvatar());
      dispatch(getRoadmapFolders());
      //dispatch(getRoadmaps());
      dispatch(getUserSettings());
    }
  }, [userState.accessToken]);

  useEffect (() => {
    if (folderState.status === "roadmapFoldersLoaded") {
      dispatch(setFolderStatus("idle"));
      dispatch(getRoadmaps());
    }
  }, [folderState.status]);

  useEffect (() => {
    if (roadmapState.roadmaps.length > 0) {
      if (roadmapState.status !== "roadmapModeSaved") {
        let loadedRoadmap = roadmapState.roadmaps.find(r => r.loaded);
        if (loadedRoadmap) {
          //dispatch(getProjects(loadedRoadmap._id));
          dispatch(getWorkFolders(loadedRoadmap._id));
          dispatch(getMembers(loadedRoadmap._id));
          dispatch(getRoadmapCategories(loadedRoadmap._id));
          dispatch(getRoadmapCards(loadedRoadmap._id));
        }
      } else {
        dispatch(setRoadmapStatus("idle"));
      }
    }
  //}, [roadmapState.roadmaps]);
  }, [roadmapState.roadmaps.map(roadmap => roadmap.loaded).join(",")]);

  useEffect (() => {
    if (folderState.status === "workFoldersLoaded") {
      let loadedRoadmap = roadmapState.roadmaps.find(r => r.loaded);
      if (loadedRoadmap) {
        dispatch(setFolderStatus("idle"));
        dispatch(getProjects(loadedRoadmap._id));
      }
    }
  }, [folderState.status]);

  useEffect (() => {
    if (projectState.projects.length > 0 && settingsState.settings.loadedComponentType === "work") {
      let loadedProject = projectState.projects.find(p => p.loaded);
      if (loadedProject) {
        dispatch(getLists(loadedProject._id));
        dispatch(getCards(loadedProject._id));
        dispatch(getLabelsToCards(loadedProject._id));
        dispatch(getLabels(loadedProject._id));
        dispatch(getComments(loadedProject._id));
      }
    }
  }, [projectState.projects]);

  return (
      <Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          /> 
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/login">
            <Route path=":roadmap/:link" element={<Login />} />
            <Route path=":ac" element={<Login />} />
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/register">
            <Route path=":roadmap/:link" element={<Register />} />
            <Route path="" element={<Register />} />
          </Route>
          <Route path="/recover">
            <Route path=":ar" element={<Recover />} />
            <Route path="" element={<Recover />} />
          </Route>
          <Route path="/roadmap/invite/">
            <Route path=":roadmap/:link" element={<RoadmapInvite />} />
          </Route>
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/products' element={<Products />} />
          <Route path='/why-herdr' element={<WhyHerdr />} />
          <Route path='/team' element={<Team />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/tech-startup-founders' element={<TechStartupFounder />} />
          <Route path='/creative-agencies' element={<CreativeAgency />} />
          <Route path='/freelancers' element={<Freelancer />} />
          <Route path='/product-designers' element={<ProductDesigner />} />
          <Route path='/bootstrapped-entrepreneurs' element={<BootstrappedEntrepreneur />} />
          <Route path='/small-nonprofits' element={<NonProfit />} />
          <Route path='/academic-research-teams' element={<AcademicResearchTeam />} />
          <Route path='/software-development-teams' element={<SoftwareDevelopmentTeam />} />
          <Route path='/consultants-coaches' element={<ConsultantCoach />} />
          <Route path='/ecommerce-teams' element={<EcommerceTeam />} />
          <Route path='/company-executives' element={<CompanyExecutives />} />
          <Route path='/forgot' element={<Forgot />} />
          <Route path='/dash' element={<Dash />} />
          <Route path='*' element={<NotFound404 />} />
        </Routes>
      </Router>
  );
}

export default App;
