import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getRoadmap, setMembers, deleteMember } from '../Store/Roadmap';
import { ContextMenu } from '../Models/ContextMenu';
import { MemberContextMenuParam } from'../Models/Requests/MemberContexMenuParam';
import { AiOutlineClose } from "react-icons/ai";
import PopupContextMenu from './PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';
import SharingContext from "../Modals/SharingContext";
import { SharingContextParam } from "../Models/Requests/SharingContextParam";

interface Props {
    params: MemberContextMenuParam | undefined;    
}

function MemberContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    const [memberContextMenu, setMemberContextMenu] = useState<ContextMenu>();
    const [sharingContextParams, setSharingContextParams] = useState<SharingContextParam>();
    //const [roadmapId, setRoadmapId] = useState<number>(0);
    //const [email, setEmail] = useState<string>("");

    useEffect(() => {
        setSharingContextParams(undefined);
    },[])

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ( (memberContextMenu && memberContextMenu._id !== props.params.roadmapId) || !memberContextMenu) {
                if (props.params.event) {
                    let menuWidth = window.outerWidth / 5;
                    //let menuHeight = window.outerHeight / 2;
                    let menuXStart = props.params.event.clientX - 10;
                    let menuYStart = props.params.event.clientY + 20
                    if (menuXStart + menuWidth > window.outerWidth) {
                        menuXStart = window.outerWidth - menuWidth - 80;
                    }
                    contextMenu._id = props.params.roadmapId;
                    contextMenu.x = menuXStart;
                    contextMenu.y = menuYStart - 100;
                    contextMenu.width = menuWidth;
                    //contextMenu.height = menuHeight;
                }
            }
            setMemberContextMenu(contextMenu);
        }
    }, [props.params]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setMemberContextMenu(contextMenu);
    }

    const editMember = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.params) {
            closeMenu();
            setSharingContextParams({ _id: props.params.roadmapId, email: props.params.email, event: e });
        }
    }


    const closeDeleteMemberPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        /* Key here is to zero-out label */
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteMemberPopup,
            buttonHandler: deleteMemberConfirmed,
            message: "",
            headingText: "",
            backButton: true,
        });
    }
    
    const deleteMemberConfirmed = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.params) {
            let roadmapId = props.params.roadmapId;
            let email = props.params.email;
            dispatch(deleteMember({ roadmapId: roadmapId, email: email }));
            let members = [...roadmapState.members];
            let removeMemberIndex = members.findIndex(m => m.email === email);
            if (removeMemberIndex !== -1) {
                members.splice(removeMemberIndex, 1);
                dispatch(setMembers(members));
                closeDeleteMemberPopup(e);
                closeMenu();
            }
        }
    }
    
    const handleDeleteMember = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (memberContextMenu) {
            setPopupParams({
                _id: memberContextMenu._id,
                event: e,
                buttonClass: "btnDelete",
                buttonText: "Remove",
                closeHandler: closeDeleteMemberPopup,
                buttonHandler: deleteMemberConfirmed,
                message: "This will remove this member from the roadmap. There is no undo.",
                headingText: "Remove Member",
                backButton: true,
            });
        }
    }

    const sContextMenu: React.CSSProperties = {
        top: (memberContextMenu) ? memberContextMenu.y : 0,
        left: (memberContextMenu) ? memberContextMenu.x : 0,
        width: (memberContextMenu) ? memberContextMenu.width : 0,
        //height: (memberContextMenu) ? memberContextMenu.height : 0,
    }

    //const member = roadmapState.members.find(m => m.userId === memberContextMenu?.id);

    return (
        <>
            {memberContextMenu && memberContextMenu._id !== "" &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <h1>Member Options</h1>
                        <div className="divClose">
                            <Link to="" onClick={(e) => closeContextMenu(e)}>
                                <AiOutlineClose color="#555" size="13" />
                            </Link>
                        </div>
                        <div className="divDeleteList" onClick={(e) => editMember(e)}>
                            Edit Member
                        </div>
                        <div className="divDeleteList" onClick={(e) => handleDeleteMember(e)}>
                            Remove Member
                        </div>
                    </div>
                </div>
            }
            <SharingContext params={sharingContextParams} />
            <PopupContextMenu params={popupParams} />
        </>
    );
}

export default MemberContextMenu;