import React, { CSSProperties, useEffect, useState, } from 'react';
import { Project } from '../Models/Project';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getProject, addList, saveList, minimizeList, 
    setLists, saveListObject, addCard, setCards, saveCard,
    copyCard,
    setProjectStatus,
 } from '../Store/Project';
 import { getRoadmap , getRoadmapCards} from '../Store/Roadmap';
 import { getSettings } from '../Store/Settings';

import { FaChevronCircleLeft, FaChevronCircleRight, FaCopy, FaEllipsisV, FaUserCircle  } from "react-icons/fa";
import { ProjectCard } from'../Models/ProjectCard';
import { Label } from '../Models/Label';

//import CardContext from '../../Components/CardContext';
//import { ContextMenu } from '../Models/ContextMenu';
import { BsThreeDots } from "react-icons/bs";
import { FiMinimize2 } from "react-icons/fi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdLabel} from "react-icons/md";

import { ListContextMenuParam } from '../Models/Requests/ListContexMenuParam';
import ListContextMenu from '../Modals/ListContextMenu';
import { CardContextParam } from '../Models/Requests/CardContextParam';
import CardContextMenu from '../Modals/CardContext';
import { LabelContextMenuParam } from '../Models/Requests/LabelContextMenuParam';
import LabelContextMenu from '../Modals/LabelContextMenu';

//import Avatar from "../../Components/Avatar";


interface Props {
    project: Project;
}

function WorkBoard(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);
    const [newList, setNewList] = useState("");
    const [newCardTitles, setNewCardTitles] = useState<Array<string>>([]);
    const [listNames, setListNames] = useState<Array<string>>([]);
    const [listNamesInEditMode, setListNamesInEditMode] = useState<Array<boolean>>([]);
    const [showAddListField, setShowAddListField] = useState<Array<boolean>>([]);
    const [hideLabelText, setHidelabelText] = useState<boolean>(false);
    const [listContextMenuParams, setListContextMenuParams] = useState<ListContextMenuParam>();
    const [cardContextParams, setCardContextParams] = useState<CardContextParam>();
    const [labelContextMenuParams, setLabelContextMenuParams] = useState<LabelContextMenuParam>();
    
    useEffect(() => {
       if (props.project) {
            setCardContextParams({ projectId: props.project._id, cardId: "" });
            setListContextMenuParams({ projectId: props.project._id, listId: "" });
        }
    }, [projectState.projects])

    /* working on this - need to refetch roadmap cards when estimated or
       actual work has been updated, so progress field updates on roadmap items
     */
    useEffect(() => {
        if (projectState.status === "workChanged") {
            dispatch(setProjectStatus("idle"));
            dispatch(getRoadmapCards(props.project.roadmapId));
        }
    }, [projectState.status]);

    useEffect(() => {

        setCardContextParams(undefined); /* close card window on project refresh */
        
        if (projectState.lists.length > 0) {
            
            let tempListNames = [...listNames];
            projectState.lists.forEach((l, i) => {
                tempListNames[i] = l.name;
            });

            setListNames(tempListNames);

            if (projectState.status === "listAdded") {
                /* hide all add list inputs */
                setShowAddListField([]);
            }

        }
    },[projectState.lists]);

    const handleNewListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewList(e.target.value);
    }

    const createNewList = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (['Enter'].indexOf(e.key) !== -1 && newList !== "") {
            e.preventDefault();
            e.stopPropagation();
            if (props.project) {
                dispatch(addList({ 
                    projectId: props.project._id,
                    name: newList,
                    index: index
                }));
                setNewList("");
            }
        }
    }

    const handleNewCardTitleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let cardTitles = [...newCardTitles];
        cardTitles[index] = e.target.value;
        setNewCardTitles(cardTitles);
    }

    const createNewCard = (e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>, index: number, listId: string) => {
        
        let cardTitles = [...newCardTitles];
        if (cardTitles[index] !== "") {
            if ((e.type === "keydown" && ['Enter'].indexOf((e as React.KeyboardEvent<HTMLInputElement>).key) !== -1) ||
                e.type === "blur") {

                e.preventDefault();
                e.stopPropagation();

                dispatch(addCard({ title: newCardTitles[index], listId: listId}));           
                cardTitles[index] = "";
                setNewCardTitles(cardTitles);
            }
        }
    }

    const handleListNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        
        let tempListNames = [...listNames];
        tempListNames[index] = e.target.value;
        setListNames(tempListNames);
        
    }

    const updateListName = (e: React.KeyboardEvent<HTMLInputElement>, index: number, listId: string) => {
        
        let tempListNames = [...listNames];
        if (['Enter'].indexOf(e.key) !== -1 && tempListNames[index] !== "") {

            e.preventDefault();
            e.stopPropagation();

            let tempListNamesInEditMode = [...listNamesInEditMode];
            tempListNamesInEditMode[index] = false;
 
            setListNamesInEditMode(tempListNamesInEditMode);
            dispatch(saveList({ _id: listId, name: tempListNames[index] }));           
        }
    }

    const updateListNameOnBlur = (e: React.FocusEvent<HTMLInputElement>, index: number, listId: string) => {
        
        let tempListNames = [...listNames];
        e.preventDefault();
        let tempListNamesInEditMode = [...listNamesInEditMode];
        tempListNamesInEditMode[index] = false;
        setListNamesInEditMode(tempListNamesInEditMode);
        dispatch(saveList({ _id: listId, name: tempListNames[index] }));
        
    }

    const toggleEditListName = (index: number) => {
        let tempListNamesInEditMode = [...listNamesInEditMode];
        tempListNamesInEditMode[index] = !tempListNamesInEditMode[index];
        setListNamesInEditMode(tempListNamesInEditMode);
    }

    const toggleListContextMenu = (e: React.MouseEvent<HTMLAnchorElement>, listId: string) => {
        e.preventDefault();
        setListContextMenuParams({ projectId: project._id, listId: listId, event: e });  
    };

    const toggleCardContext = (e: React.MouseEvent<HTMLDivElement>, cardId: string) => {
        e.preventDefault();
        setCardContextParams({ projectId: project._id, cardId: cardId, event: e });   
    };

    const handleCopyCard = (e: React.MouseEvent<HTMLDivElement>, card: ProjectCard) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(copyCard(card));
    }

    const toggleAddListField = (e: React.MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        let temp = [...showAddListField];
        temp[index] = !temp[index];
        setShowAddListField(temp);
    }

    const onDragEnd = (result: any) => {
        
        let cardId = result.draggableId;
        let newIndex = parseInt(result.destination.index);
        let oldIndex = parseInt(result.source.index);
        let newListId = result.destination.droppableId;
        let oldListId = result.source.droppableId;

        let cardsInOldList: Array<ProjectCard> = [];
        let cardsInNewList: Array<ProjectCard> = [];
        let cardsToUpdate: Array<ProjectCard> = [];

        let card = projectState.cards.find(c => c._id === cardId);

        // Reshuffle the cards in the list the card is being dragged into
        cardsInNewList = projectState.cards.filter(c => c.listId === newListId).sort(({ index :a }, { index :b }) => a-b );
        if (card) {
            if (oldListId === newListId) {
                cardsInNewList.splice(oldIndex, 1);
            }
            cardsInNewList.splice(newIndex, 0, card);
            let sortedCardsInList = cardsInNewList.map((c, i) => {
                return {...c, index: i, listId: newListId };
            });

            sortedCardsInList.forEach( c => {
                cardsToUpdate.push(c);
            }); 
        }

        if (oldListId !== newListId) {
            cardsInOldList = projectState.cards.filter(c => c.listId === oldListId).sort(({ index :a }, { index :b }) => a-b );
            if (card) {
                cardsInOldList.splice(oldIndex, 1);
                let sortedCardsInList = cardsInOldList.map((c, i) => {
                    return {...c, index: i };
                });
                sortedCardsInList.forEach( c => {
                    cardsToUpdate.push(c);
                });
            }
        }

        // Update frontend
        let cards = projectState.cards.map(card => {
            let cardUpdated = cardsToUpdate.find(c => c._id === card._id);
            if (cardUpdated) {
                return cardUpdated;
            }
            return card;
        })
        cards.sort(({ index :a }, { index :b }) => a-b );
        dispatch(setCards(cards)); // update frontend

        // update the backend
        cardsToUpdate.forEach(card => {
            dispatch(saveCard(card));
        });
              
    }

    const moveList = (id: string, adjustment: number) => {
        
        let listIdsToUpdate: Array<string> = [];
        let listIndex = projectState.lists.findIndex(l => l._id === id);
        
        if (listIndex !== -1) {
            let moveToIndex = listIndex + adjustment;
            let lists = projectState.lists.map((list, index) => {
                if(index === listIndex) {
                    listIdsToUpdate.push(list._id);
                    return { ...list, index: moveToIndex };
                } else if (index === moveToIndex) {
                    listIdsToUpdate.push(list._id);
                    return { ...list, index: listIndex };
                }
                return list;
            });

            lists.sort(({ index :a }, { index :b }) => a-b );
            dispatch(setLists(lists)); // update frontend
            lists.forEach(list => {
                let listNeedsUpdated = listIdsToUpdate.findIndex(l => l === list._id);
                if (listNeedsUpdated !== -1) {
                    dispatch(saveListObject(list));
                }
            });
        }
    }

    const handleHideLabelText = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setHidelabelText(!hideLabelText);
    }

    const toggleLabelContextMenu = (e: React.MouseEvent<HTMLDivElement>, cardId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setLabelContextMenuParams({ cardId: cardId, event: e });
    };

    const handleMinimizeList = (e: React.MouseEvent<HTMLAnchorElement>, id: string, minimize: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(minimizeList({ _id: id, minimize: minimize }));
    }

    //let project = projectState.projects.find(p => p.loaded);
    let project = props.project;
    let projectCardCount = 0;
    let projectLists = projectState.lists.filter(l => l.projectId === project._id);

    if (projectLists) {
        projectLists.forEach(l => {
            let listCards = projectState.cards.filter(c => c.listId === l._id);
            projectCardCount += listCards.length;
        });
    }

    //let category = roadmapState.categories.find(c => c._id === project.categoryId);
    //let listStyle: CSSProperties = (category) ? { backgroundColor : category.backgroundColor, color: category.fontColor } : {};
    //console.log("Project: " + props.project.id);
    //console.log("Project Lists: " + projectState.lists.length);
    let lists = projectState.lists.map((list, index) => {
        let placeholderDropText = (list.minimized) ? projectState.cards.filter(c => c.listId === list._id).length : "drop card here";
        
        let cards: Array<ProjectCard> = (settingsState.settings.searchText === "" || !settingsState.settings.searchText) ?
            projectState.cards.filter(c => c.listId === list._id) 
        : 
            projectState.cards.filter(c => c.listId === list._id && 
                (c.title.toLowerCase().includes(settingsState.settings.searchText.toLowerCase()) || 
                 c.description.toLowerCase().includes(settingsState.settings.searchText.toLowerCase())));

/*
        let cards: Array<Card> = (projectState.searchText === "") ?
                projectState.cards.filter(c => c.listId === list.id)
            :
                projectState.cards.filter(c => c.listId === list.id && 
                    (c.title.includes(projectState.searchText) || 
                     c.description.includes(projectState.searchText)
                    ));
*/
        let formattedCards = (list.minimized) ? [] : cards.map((c, index) => {
            //let cardOwner = projectState.projectMembers.find(m => m.userId === c.ownerId && c.ownerId > 0);
            let labelsToCards = projectState.labelsToCards.filter(lc => lc.cardId === c._id);
            if (project && c.roadmapItem !== "") {
                labelsToCards.unshift({
                    _id: "",
                    labelId: "",
                    cardId: c._id,
                    created: new Date(),
                })
                /* used to be below 
                labelsToCards.unshift({
                    id: -1,
                    labelId: -1,
                    cardId: c.id,
                    created: new Date(),
                })*/
            }

            return (
                <Draggable key={c._id} draggableId={c._id.toString()} index={index}>
                    {(provided, snapshot) => (
                        <div
                            onClick={(e) => toggleCardContext(e, c._id)}
                            className="activeCard"
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className="cardNotHover">
                                {project?.permission?.toString() !== "observer" &&
                                <>
                                    <div className="copyCardIcon" onClick={e => handleCopyCard(e, c)}>
                                        <span style={{ position: "relative", top: "-2px" }}>
                                            <FaCopy size="14" color="gray" />
                                        </span> 
                                    </div>
                                    <div className="addCardLabelIcon" onClick={(e) => toggleLabelContextMenu(e, c._id)}>
                                        <span style={{ position: "relative", top: "-2px", right: "-1px" }}>
                                            <MdLabel size="14" color="gray" />
                                        </span>
                                   </div>
                                </>
                                }

                                {labelsToCards.map(lc => {
                                    let label: Label | undefined = undefined;
                                    if (lc._id === "") { // roadmap item category
                                        let roadmapItem = roadmapState.cards.find(ri => ri._id === c.roadmapItem);
                                        if (project && roadmapItem) {
                                            let roadmapItemLabel = roadmapState.categories.find(rc => rc._id === roadmapItem!.categoryId);
                                            if (roadmapItemLabel) {
                                                label = {
                                                    _id: "",
                                                    projectId: project._id,
                                                    title: roadmapItem.title,
                                                    backgroundColor: roadmapItemLabel.backgroundColor,
                                                    fontColor: roadmapItemLabel.fontColor,
                                                    created: new Date(),
                                                    modified: new Date(),
                                                    active: true,
                                                }
                                            }
                                        }
                                    } else {
                                        label = projectState.labels.find(l => l._id === lc.labelId);
                                    }
                                    let style = {
                                        backgroundColor: label?.backgroundColor,
                                        color: label?.fontColor
                                    }

                                    if (label) {
                                        if (label.title.length >= 30) {
                                           label.title = label.title.substring(0,27) + "...";
                                        }
                                    }
                                    if (label) {
                                        return (
                                        <div key={"mh-"+lc._id} className="labelBadgeSmall mb-1" style={style} onClick={(e) => handleHideLabelText(e)}>
                                            {!hideLabelText &&
                                                <>
                                                {label?.title === "" ?
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                :
                                                    <span>{label?.title}</span>
                                                }
                                                </>
                                            }
                                        </div>
                                        );
                                    }
                                })}
                                {labelsToCards.length > 0 &&
                                    <div style={{clear: "both"}}></div>
                                }
                                <div style={{ lineHeight:1.3, whiteSpace: "normal" }}>
                                    {c.title}
                                </div>
                                {c.ticketId > 0 &&
                                    <div className="cfTicket">
                                        <div style={{ display: "inline-block", paddingTop: "5px" }}>
                                            <FaUserCircle size="18" />
                                        </div>
                                        {/*
                                        {c.ownerId > 0 ?
                                            <Avatar size="xxsmall" projectMember={cardOwner} inline={true} />
                                        :
                                            <div style={{ display: "inline-block", paddingTop: "5px" }}>
                                                <FaUserCircle size="18" />
                                            </div>
                                        }
                                            */}
                                        <span style={{paddingRight: "3px" }}>&nbsp;</span>
                                        <span>
                                            {format(new Date(c.created),"eee L/d").toUpperCase()}
                                            &nbsp;
                                            H{c.ticketId}
                                            &nbsp;
                                            {c.status}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
        // 2 6 2 2 default
        let cCenterLeft = "";
        let cRight = "";

        let cLeft = (index !== 0) ? "col-2" : "";

        if (index === 0 && index === projectState.lists.length - 1) {
            //cCenterLeft = "col-10";
            cCenterLeft = "col-9";
        } else if (index !== 0 && index !== projectState.lists.length - 1) {
            //cCenterLeft = "ps-0 col-7";
            cCenterLeft = "ps-0 col-6";
        } else if (index === 0) {
            //cCenterLeft = "col-9";
            cCenterLeft = "col-8";
        } else if (index === projectState.lists.length - 1) {
            //cCenterLeft = "p-0 col-8";
            cCenterLeft = "p-0 col-7";
        }
        
        if (index === 0 && index === projectState.lists.length - 1) {
            //cRight = "col-2";
            cRight = "col-3";
        }else if (index === projectState.lists.length - 1) {
            cRight = "col-1";
            //cRight = "col-2";
        } else if (index === 0) {
            //cRight = "col-3";
            cRight = "col-4";
        } else {
            //cRight = "col-3";
            cRight = "col-4";
        }

        let listClass = (list.minimized) ? "listBackgroundMinimized" : "listBackground";
        let cardPlaceholderClass = (list.minimized) ? "cardPlaceholderMinimized" : "cardPlaceholder";

        let cardPlaceholderMinimizeStyle: CSSProperties = {
            writingMode: "vertical-rl",
            textOrientation: "mixed",
        }

        let cardPlaceholderStyle = (list.minimized) ? cardPlaceholderMinimizeStyle : {};

        let listNameMinimizedStyle: CSSProperties = {
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            marginLeft: "9px",
            //marginLeft: "4px",
            color: "gray",
            marginTop: "5px",
            fontWeight: "normal"
        }
        let listNameStyle = (list.minimized) ? listNameMinimizedStyle : {};

        return (
            <>
            {index === 0 && 
                <>
                    {showAddListField[index] &&
                        <input type="text" className="newList ms-3" placeholder="+ add list"
                            value={newList}
                            onChange={(e) => handleNewListChange(e)} 
                            onKeyDown={(e) => createNewList(e, index)}
                            style={{ marginRight: "0px" }}
                        />
                    }
                    {project?.permission?.toString() !== "observer" ?
                        <div className="listAddButton">
                            <span>
                                <Link to="" onClick={e => toggleAddListField(e, index)}>
                                    <FaEllipsisV color="#efefef" size="20" />
                                </Link>
                            </span>
                        </div>
                    :
                        <div className="listAddButton" style={{ width: "16px" }}></div>
                    }
                </>
            }
            {/* style={listStyle} */}
            <div className={listClass} key={"list-" + index}>
                {!listNamesInEditMode[index] ?
                    <div className="row">
                        {!list.minimized &&
                            <div className={cLeft}>
                                {index > 0 && project?.permission?.toString() !== "observer" &&
                                    <Link to="" onClick={(e) => {
                                        e.preventDefault();
                                        moveList(list._id, -1)}
                                    }>
                                        <span className="iconHover">
                                            <FaChevronCircleLeft size="20" />
                                        </span>
                                    </Link>
                                }
                            </div>
                        }
                        {list.minimized && project?.permission?.toString() !== "observer" &&
                            <>
                                <div className="col-12" style={{ marginLeft: "-2px" }}>
                                    <Link to="" onClick={(e) => handleMinimizeList(e, list._id, list?.minimized ? false: true )}>
                                        <FiMinimize2 color="gray" />
                                    </Link>
                                </div>
                            </>
                        }
                        {project?.permission?.toString() !== "observer" && !list.minimized ?
                            <div className={`${cCenterLeft} text-wrap`} onClick={() => toggleEditListName(index)} style={listNameStyle}>
                                {listNames[index]}
                            </div>
                        :
                            <div className="col-12 text-wrap" style={listNameStyle}>
                                {listNames[index]}
                            </div>
                        }
                        {!list.minimized &&
                            <div className={cRight}>
                                {project?.permission?.toString() !== "observer" &&
                                    <> 
                                        <Link to="" onClick={(e) => handleMinimizeList(e, list._id, list?.minimized ? false: true )} style={{ marginRight: "10px" }}>
                                            <FiMinimize2 size="14" />
                                        </Link>
                                        <Link to="" onClick={(e) => toggleListContextMenu(e, list._id)}>
                                            <BsThreeDots />
                                        </Link>
                                        {index < projectState.lists.length - 1 && 
                                            <>
                                                &nbsp;&nbsp;
                                                <Link to="" onClick={(e) => {
                                                        e.preventDefault();
                                                        moveList(list._id, 1)}
                                                    }>
                                                    <span className="iconHover">
                                                        <FaChevronCircleRight size="20" />
                                                    </span>
                                                </Link>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                :
                    <div>
                        <input className="changeListName" type="text"
                            value={listNames[index]}
                            autoFocus
                            onChange={(e) => handleListNameChange(e, index)} 
                            onKeyDown={(e) => updateListName(e, index, list._id)}
                            onBlur={(e) => updateListNameOnBlur(e, index, list._id)}
                        />             
                    </div>
                }
                
                <Droppable droppableId={(list._id) ? list._id.toString() : ""}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {formattedCards}
                            {formattedCards.length === 0 && projectCardCount > 0 &&
                                <div className={cardPlaceholderClass} style={cardPlaceholderStyle}>
                                    {placeholderDropText}
                                </div>
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {!list.minimized &&
                    <div>
                        <input className="addCard" type="text" placeholder="+ add card"
                            value={(newCardTitles[index]) ? newCardTitles[index] : ""}
                            onChange={(e) => handleNewCardTitleChange(e, index)} 
                            onKeyDown={(e) => createNewCard(e, index, list._id)}
                            onBlur={(e) => createNewCard(e, index, list._id)}
                        />
                    </div>
                }
                {/*
               {projectState.searchText === "" && !list.minimized &&
                <div>
                    <input className="addCard" type="text" placeholder="+ add card"
                        value={(newCardTitles[index]) ? newCardTitles[index] : ""}
                        onChange={(e) => handleNewCardTitleChange(e, index)} 
                        onKeyDown={(e) => createNewCard(e, index, list.id)}
                        onBlur={(e) => createNewCard(e, index, list.id)}
                    />
                </div>
                }
                */}
            </div>
            <>
                {project?.permission?.toString() !== "observer" ?
                    <div className="listAddButton">
                        <span>
                            <Link to="" onClick={e => toggleAddListField(e, index + 1)}>
                                <FaEllipsisV color="#efefef" size="20" />
                            </Link>
                        </span>
                    </div>
                :
                    <div className="listAddButton" style={{ width: "20px" }}></div>
                }
                {showAddListField[index+1] &&
                    <input type="text" className="newList" placeholder="+ add list"
                        value={newList}
                        onChange={(e) => handleNewListChange(e)} 
                        onKeyDown={(e) => createNewList(e, index + 1)}
                    />
                }
            </>
        </>
        )
    });

    return (
        <div className="rightContentDefault" style={{ padding: "13px 0px"}}>
            {!projectState.projectSwitchingStatus &&
                <div className="listBody">
                    <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                        {lists}
                    </DragDropContext>
                    {lists.length === 0 &&
                        <input type="text" className="newList ms-3" placeholder="+ add list"
                            value={newList}
                            onChange={(e) => handleNewListChange(e)} 
                            onKeyDown={(e) => createNewList(e, 0)}
                        />
                    }
                </div>
            }
            <ListContextMenu params={listContextMenuParams} />
            <CardContextMenu params={cardContextParams} />
            <LabelContextMenu params={labelContextMenuParams} />
        </div>
    );
}

export default WorkBoard;