import React, { CSSProperties, useEffect } from 'react';
import { IoChatbubbleOutline, IoMapOutline } from "react-icons/io5";
import { MdWorkspacesOutline } from "react-icons/md";
import { LuKanban } from "react-icons/lu";
import { BsBell } from "react-icons/bs";
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { getSettings, toggleChat, toggleSidebar } from '../Store/Settings';
import { loadComponent } from '../Store/Settings';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import { getFolder, setFolderStatus } from '../Store/Folder';
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

function RoadmapNav() {

    const dispatch = useAppDispatch();
    const folderState = useAppSelector(getFolder);
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);

    const handleLoadRoadmapList = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        /* Maps icon should either load the roadmaps home, i.e. global_maps, or 
           if the user is setting in chat it needs to load whatever work they
           are working on, that way they can quickly toggle between chat and work
         */
        if(settingsState.settings.loadedComponentType !== "chat") {
            handleLoadComponent(dispatch, "global_maps", settingsState.settings); 
        } else {
            handleLoadComponent(dispatch, settingsState.settings.previousComponentType, settingsState.settings);
        }
    }

    const handleLoadChat = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        handleLoadComponent(dispatch, "chat", settingsState.settings);       
    }

    const handleToggleSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(toggleSidebar(!settingsState.settings.sidebarExpanded));
    }

    let navItemStyle = { 
        cursor: "pointer",
        paddingTop: "5px", 
        fontSize: "8pt", 
        color: "#F8F9FA", 
        fontWeight: "450",
    };

    let navItemSelectedStyle = {
        cursor: "pointer",
        paddingTop: "5px", 
        fontSize: "8pt",  
        fontWeight: "450",
        color: "#e55c02",
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "1px",
    };

    //let mapsTabLoaded = settingsState.settings.loadedComponentType === "global_maps";
    //let mapsTabLoaded = settingsState.settings.sidebarExpanded;
    let mapsTabLoaded = settingsState.settings.loadedComponentType !== "chat";
    let mapsIconColor = (mapsTabLoaded) ? "#e55c02" : "#bec1c3";
    let mapsDivStyle: CSSProperties = (mapsTabLoaded) ? navItemSelectedStyle : navItemStyle;

    //let chatTabLoaded = settingsState.settings.chatExpanded;
    let chatTabLoaded = settingsState.settings.loadedComponentType === "chat";
    let chatIconColor = (chatTabLoaded) ? "#e55c02" : "#bec1c3";
    let chatDivStyle: CSSProperties = (chatTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let workTabLoaded = settingsState.settings.loadedComponentType === "global_work";
    let workIconColor = (workTabLoaded) ? "#e55c02" : "#bec1c3";
    let workDivStyle: CSSProperties = (workTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let actionTabLoaded = settingsState.settings.loadedComponentType === "global_action";
    let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let actionDivStyle: CSSProperties = (actionTabLoaded) ? navItemSelectedStyle : navItemStyle;

    let herdrTabLoaded = settingsState.settings.loadedComponentType === "global_herdr";
    //let actionIconColor = (actionTabLoaded) ? "#e55c02" : "#bec1c3";
    let herdrDivStyle: CSSProperties = (herdrTabLoaded) ? navItemSelectedStyle : navItemStyle;  


    return (
        <div style={{ height: "100vh", backgroundColor: "#02152a", borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px", margin: "0px auto", textAlign: "center",
            paddingTop: "15px"
         }}>
            
            <div style={{ cursor: "pointer", paddingBottom: "20px" }} onClick={(e) => handleToggleSidebar(e)}>
                {settingsState.settings.sidebarExpanded ?
                    <BsToggleOn size={25} color="#bec1c3" />
                :
                    <BsToggleOff size={25} color="#bec1c3" />
                }
            </div>
            
            {/*
            <div style={{ backgroundColor: "#fff", borderRadius: "50%", width: "27px", height: "27px",
                margin: "0px auto", textAlign: "center", cursor: "pointer" }} 
                onClick={(e) => handleLoadComponent(dispatch, "global_herdr")}>
                <img src='https://herdr.io/images/orange-cat-head.jpg' style={{ width: "17px", 
                    position: "relative", top: "-2px", left: "1px"
                 }} />
            </div>
            <div style={herdrDivStyle} 
                onClick={(e) => handleLoadComponent(dispatch, "global_herdr")}>
                Setup
            </div>


            <div style={{ backgroundColor: "#fff", borderRadius: "50%", width: "27px", height: "27px",
                margin: "0px auto", textAlign: "center", cursor: "pointer" }} 
                onClick={(e) => handleLoadRoadmapList(e)}>
                <img src='https://herdr.io/images/orange-cat-head.jpg' style={{ width: "17px", 
                    position: "relative", top: "-2px", left: "1px"
                 }} />
            </div>
            <div style={herdrDivStyle} 
                onClick={(e) => handleLoadRoadmapList(e)}>
                Home
            </div>
                        */}
            <div style={{ cursor: "pointer" }} onClick={(e) => handleLoadRoadmapList(e)}>
                <IoMapOutline color={mapsIconColor} size={25} />
            </div>
            <div style={mapsDivStyle} onClick={(e) => handleLoadRoadmapList(e)}>
                Maps
            </div>
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadChat(e)}>
                <IoChatbubbleOutline color={chatIconColor} size={25} />
            </div>
            <div style={chatDivStyle} onClick={(e) => handleLoadChat(e)}>
                Chat
            </div>
            {/*
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={() => dispatch(toggleChat(!settingsState.settings.chatExpanded))}>
                <IoChatbubbleOutline color={chatIconColor} size={25} />
            </div>
            <div style={chatDivStyle} onClick={() => dispatch(toggleChat(!settingsState.settings.chatExpanded))}>
                Chat
            </div>
            */}
            {/*
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadComponent(dispatch, "global_work")}>
                <LuKanban color={workIconColor} size={25} />
            </div>
            <div style={workDivStyle} onClick={(e) => handleLoadComponent(dispatch, "global_work")}>
                Work
            </div>
            */}
            {/*
            <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={(e) => handleLoadComponent(dispatch, "global_action")}>
                <BsBell color={actionIconColor} size={25} />
            </div>
            <div style={actionDivStyle} onClick={(e) => handleLoadComponent(dispatch, "global_action")}>
                Action
            </div>
            */}
        </div>
    );
}

export default RoadmapNav;