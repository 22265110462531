import { IBaseModel } from './IBaseModel';

export interface Label extends IBaseModel {
    projectId: string;
    title: string;
    backgroundColor: string;
    fontColor: string;
}

export const EmptyLabel = {
    _id: "",
    projectId: "",
    created: new Date(),
    modified: new Date(),
    active: true,
    title: "",
    backgroundColor: "",
    fontColor: "",
}