import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getProject, loadProject, setProjectStatus, saveProjects, 
    setProjects, deleteProject, createProject,  getProjects } from '../Store/Project';
import { loadComponent, getSettings, toggleWork, toggleChat } from '../Store/Settings';
//import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
//import { RxDashboard } from "react-icons/rx";
//import { BsBell } from "react-icons/bs";
import { LuKanban } from "react-icons/lu";
import { BiSolidLeftArrow, BiSearch } from "react-icons/bi";
//import { IoChatbubbleOutline, IoMapOutline, IoSettingsOutline, IoPeopleOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Roadmap } from '../Models/Roadmap';
//import { loadedComponentType, loadedSubComponentType } from '../Helpers/types';
import { handleLoadComponent } from '../Helpers/handleLoadComponent';
import ChatSubNav from './ChatSubNav';
import ProjectContextMenu from '../Modals/ProjectContextMenu';
import MoveProjectContextMenu from '../Modals/MoveProjectContextMenu';
import { ProjectContextMenuParams } from '../Models/Requests/ProjectContextMenuParams';
import { MoveProjectContextMenuParams } from '../Models/Requests/MoveProjectContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
//import { setTimeout } from 'timers/promises';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaTrash, FaFolder } from 'react-icons/fa';
import { FaRegFolder, FaRegFolderClosed, FaRegFolderOpen } from "react-icons/fa6";
//import { PiFolderSimpleDuotone, PiFolderSimple } from "react-icons/pi";
import PopupContextMenu from '../Modals/PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';
import { createFolder, expandFolder, deleteFolder, getFolder, 
    setFolderStatus, saveFolderName, setWorkFolders, getWorkFolders } from '../Store/Folder';
//import { Folder } from '../Models/Folder';
import { PiLineVerticalThin } from "react-icons/pi";
import { rearrangeFoldersAndFiles, onFolderFileDragEnd } from '../Helpers/dragAndDropFilesAndFolders';
import { Project } from '../Models/Project';
import { getMembers, getRoadmap } from '../Store/Roadmap';

interface Props {
    roadmap: Roadmap | undefined;
}

function WorkSubNav(props: Props) {

    const dispatch = useAppDispatch();
    const roadmapState = useAppSelector(getRoadmap);
    const projectState = useAppSelector(getProject);
    const settingsState = useAppSelector(getSettings);
    const folderState = useAppSelector(getFolder);
    const [search, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    const [projectContextMenuParams, setProjectContextMenuParams] = useState<ProjectContextMenuParams>();
    const [moveProjectContextMenuParams, setMoveProjectContextMenuParams] = useState<MoveProjectContextMenuParams>();
    const [editingFolder, setEditingFolder] = useState<string>("");
    const [editingFolderValue, setEditingFolderValue] = useState("");

    useEffect(() => {
        if (projectState.projects.length > 0) {
            if (props.roadmap) {
                let folders = [...folderState.workFolders].filter((f => f.type === 'work' && f.roadmapId === props.roadmap!._id));
                let projects: Array<Project> = [];
                projects = [...projectState.projects.filter(p => p.roadmapId === props.roadmap!._id)];
                projects = projects.filter(p => p.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));  
                rearrangeFoldersAndFiles(dispatch, folders, projects, 'work');
            }
        }
    },[projectState.projects.length, folderState.workFolders.length]);

    /*
    useEffect(() => {
        if (!search || searchText === "") {
            dispatch(getProjects());
        }
    },[search, searchText]);
    */

    useEffect(() => {
        if (projectState.status === "projectCreated") {
            dispatch(setProjectStatus("idle"));

            // Retrieve roadmap members because we may have added the project for them
            if (props.roadmap) {
               dispatch(getMembers(props.roadmap._id));
                //console.log("project created and fired");
            }
            // if the roadmaps section is compresssed and someone adds one, open it so they can see their new roadmap.
            if (!settingsState.settings.workExpanded) {
                dispatch(toggleWork(true));
            }
            let projects = [...projectState.projects];
            projects.sort(function(a, b) { return b.sort - a.sort });

            // If new project was created in a folder, expand the folder so they know it was created.
            if (projects.length > 0) {
                // Update the frontend
                let folders = [...folderState.workFolders];
                let folder = folders.find(f => f._id === projects[0].folderId);
                if (folder) {
                    let newFolder = Object.assign({}, folder);
                    newFolder.expanded = true;
                    let folderIndex = folders.findIndex(f => f._id === projects[0].folderId);
                    if (folderIndex !== -1) {
                        folders.splice(folderIndex,1);
                        folders.splice(folderIndex,0,newFolder);
                        dispatch(setWorkFolders(folders));
                        dispatch(expandFolder({ _id: projects[0].folderId, expanded: true })); 
                    }
                }   
            }
        }
    }, [projectState.status]);

    useEffect(() => {
        if (projectState.status === "projectLoaded") {
            dispatch(setProjectStatus("idle"));
            handleLoadComponent(dispatch, "work", settingsState.settings);
        }
    }, [projectState.status]);

    useEffect(() => {
        if (folderState.status === "folderCreated") {
            dispatch(setFolderStatus("idle"));
            if (folderState.workFolders.length > 0) {
                setEditingFolder(folderState.workFolders[0]._id);
                setEditingFolderValue(folderState.workFolders[0].name);
            }
        }
    }, [folderState.status]);
   
    const handleShowAddProject = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (props.roadmap) {
            setProjectContextMenuParams({ event: e, _id: props.roadmap._id });   
        }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>  {
        e.preventDefault();
        setSearchText(e.currentTarget.value);
    }

    const handleLoadProject = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        /* If project is already loaded, just switch to project screen, else load the project first */
        let project = projectState.projects.find(p => p.loaded);
        if (project && project._id === id) {
            handleLoadComponent(dispatch, "work", settingsState.settings);
        } else {
            // old.getFolders() dispatch(getFolders());
            dispatch(loadProject(id));
        }
    }

    const handleDeleteProject = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Show the delete confirmation window */
        setPopupParams({
            _id: id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteProjectPopup,
            buttonHandlerWithId: deleteProjectConfirmed,
            message: "This will delete this project. There is no undo.",
            headingText: "Delete Project",
            backButton: true,
        });
    }

    const closeDeleteProjectPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteProjectPopup,
            buttonHandlerWithId: deleteProjectConfirmed,
            message: "",
            headingText: "",
            backButton: true,
        });
    }

    const deleteProjectConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        let projects = [...projectState.projects];
        let index = projects.findIndex(p => p._id === id);
        if (index !== -1) {
            projects.splice(index, 1);
            dispatch(setProjects(projects));
            dispatch(deleteProject(id));
            closeDeleteProjectPopup(e);
        }
    }

    const handleMoveToFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.roadmap) {
            setMoveProjectContextMenuParams({ event: e, _id: id });   
        }
    }

    const createProjectFolder = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatch(createFolder({
            type: 'work',
            roadmapId: (props.roadmap) ? props.roadmap!._id : "" 
        }));
    }

    const handleShowRenameFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        let folder = folderState.workFolders.find(f => f._id === id);
        if (folder) {
            setEditingFolder(folder._id);
            setEditingFolderValue(folder.name);
        }
        //dispatch(createFolder('roadmap'));
    }

    const handleToggleFolder = (e: React.MouseEvent<HTMLDivElement>, id: string, expanded: boolean) => {
        e.preventDefault();   
        e.stopPropagation();

        /* Update the frontend */
        let folders = [...folderState.workFolders];
        let folder = folders.find(f => f._id === id);
        if (folder) {
            let newFolder = Object.assign({}, folder);
            newFolder.expanded = expanded;
            let folderIndex = folders.findIndex(f => f._id === id);
            if (folderIndex !== -1) {
                folders.splice(folderIndex,1);
                folders.splice(folderIndex,0,newFolder);
                dispatch(setWorkFolders(folders));
                dispatch(expandFolder({ _id: id, expanded: expanded }));
            }
        }
    }

    const handleFolderNameChange = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {  
        
        let key = "";
        if (e.type === "keydown" ) {
            let keypress = e as React.KeyboardEvent;
            key = keypress.key;          
        }
        
        if (key === 'Enter' || e.type !== "keydown") {
            let folders = [...folderState.workFolders];
            let folder = folders.find(f => f._id === editingFolder);
            if (folder) {
                let newFolder = Object.assign({}, folder);
                newFolder.name = editingFolderValue;
                let folderIndex = folders.findIndex(f => f._id === editingFolder);
                if (folderIndex !== -1) {
                    folders.splice(folderIndex,1);
                    folders.splice(folderIndex,0,newFolder);
                    dispatch(setWorkFolders(folders));
                }
            }
            dispatch(saveFolderName({ _id: editingFolder, name: editingFolderValue }));
            setEditingFolder("");
            setEditingFolderValue("");
        }
    }

    const handleEditingFolderName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditingFolderValue(e.currentTarget.value);
    }

    const handleDeleteFolder = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Show the delete confirmation window */
        setPopupParams({
            _id: id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteFolderPopup,
            buttonHandlerWithId: deleteFolderConfirmed,
            message: "Delete the folder? If it contains files, this will move the files to no folder",
            headingText: "Delete Folder",
            backButton: false,
        });
    }

    const closeDeleteFolderPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setPopupParams({
            _id: "",
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteFolderPopup,
            buttonHandlerWithId: deleteFolderConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }

    const deleteFolderConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        /* Remove roadmap from frontend */
        let folders = [...folderState.workFolders];
        let index = folders.findIndex(f => f._id === id);
        if (index !== -1) {
            /* Remove the folder from the fronted */
            folders.splice(index, 1);
            dispatch(setWorkFolders(folders));
            /* Remove all projects from under the folder on the frontend */
            let projects = [...projectState.projects];
            projects.forEach(p => {
                if (p.folderId === id) {
                    let newProject = Object.assign({}, p);
                    newProject.folderId = "";
                    let index = projects.findIndex(p => p._id === p._id);
                    if (index !== -1) {
                        projects.splice(index,1);
                        projects.splice(index,0,newProject);
                        dispatch(setProjects(projects));
                    }
                }
            })
            /* Remove from backend */
            dispatch(deleteFolder(id));
            closeDeleteFolderPopup(e);
        }
    }

    const getStyle = (style: any, projectId: string) => {
        let loadedProject = projectState.projects.find(p => p.loaded);    
        if (loadedProject && projectId === loadedProject._id && settingsState.settings.loadedComponentType === "work") {
            return {
                ...style,
                backgroundColor: "#bb4b01",
                borderRadius: "5px",
                color: "#fff",
                marginTop: "5px",
                marginBottom: "5px",
                //cursor: "auto"
            };
        } else {
            return { ...style };
        }
    }

    //console.log("Roadmap ID: " + props.roadmap!.id);
    let loadedProject = projectState.projects.find(p => p.loaded);
    
    //let folders = [...folderState.allFolders].filter((f => f.type === 'work' && f.roadmapId === props.roadmap!.id));
    let folders = [...folderState.workFolders].filter((f => f.type === 'work' && f.roadmapId === props.roadmap!._id));
    folders.sort(function(a, b) { return a.sort - b.sort });
    //console.log(folders);
    let projects: Array<Project> = [];

    projects = [...projectState.projects.filter(p => p.roadmapId === props.roadmap!._id)];
    if (search && searchText !== "") { 
        projects = projects.filter(p => p.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
    }    
    projects.sort(function(a, b) { return a.sort - b.sort });
    /*if (props.roadmap) {
        console.log(props.roadmap);
    }
    console.log(projects);
*/
    let navItems: Array<JSX.Element> = [];
    let expandedProjectsClass: CSSProperties = {
        width: "94%",
        margin: "0px auto",
        color: "#909495",
        paddingTop: "10px",
        height: "75vh",
        overflowY: "scroll"
    }
    let workTitleWrapper: CSSProperties = {
        width: "94%",
        margin: "0px auto",
        color: "#909495",
        paddingTop: "10px"
    }
    let workTitleDivider: CSSProperties = {
        width: "94%",
        color: "#909495",
        margin: "10px auto 0px auto"
    }

    /*
    let leftSubNavWrapper = "";

    if (!props.roadmap) {
        leftSubNavWrapper = "leftSubNavWrapper";
        expandedProjectsClass.height = "80vh";
        workTitleWrapper.paddingTop = "20px";
        workTitleDivider.margin = "15px auto 0px auto";
    }
        */

    /* Load projects that belong to folders */

    let counter = (() => {
        let count = 0;
        return () => count++;
    })();

    folders.forEach((f,i) => {
        const folderKey = counter();
        const key = "folder-" + f._id;
        navItems.push(
            <Draggable key={key} draggableId={"folder-" + f._id.toString()} index={folderKey}>
            {(provided, snapshot) => (
                <div className="projectNavFolder"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div style={{ display: "inline-block", position: "relative", top: "-8px" }}>
                        {f.expanded ?
                            <FaRegFolderOpen color="#bec1c3" size={15} />
                        :
                            <FaRegFolderClosed color="#bec1c3" size={15} />
                        }
                    </div>
                    {editingFolder === f._id ?
                        <div style={{ display: "inline-block", paddingLeft: "10px", color: "#bec1c3",
                            textOverflow: "ellipsis", width: "calc(80%)", overflow: "hidden", whiteSpace: "nowrap" }}>
                            <input type="text" value={editingFolderValue} className="standardInput2" 
                                autoFocus
                                onChange={(e) => handleEditingFolderName(e)}
                                onKeyDown={(e) => handleFolderNameChange(e)} 
                                onBlur={(e) => handleFolderNameChange(e)} />
                        </div>
                    :
                        <div style={{ display: "inline-block", paddingLeft: "10px", color: "#bec1c3",
                            textOverflow: "ellipsis", width: "calc(80%)", overflow: "hidden", whiteSpace: "nowrap" }}
                            onClick={(e) => handleShowRenameFolder(e, f._id)}
                            onContextMenu={(e) => handleDeleteFolder(e, f._id)}>
                            {f.name}
                        </div>
                    }
                    <div style={{ float: "right", paddingLeft: "5px" }}
                        onClick={(e) => handleToggleFolder(e, f._id, !f.expanded)}>
                        {f.expanded ? 
                            <VscTriangleUp size={18} color="#bec1c3" />
                        :
                            <VscTriangleDown size={18} color="#bec1c3" />
                        }
                    </div>
                </div>
            )}
            </Draggable>
        );
        //onClick={(e) => !p.loaded && handleLoadProject(e, p._id)}
        if (f.expanded) {
            projects.filter(p => p.folderId === f._id).forEach((p,i) => {
                const projectKey = counter();
                const key = "file-" + p._id;
                navItems.push(
                    <Draggable key={key} draggableId={"file-" + p._id.toString()} index={projectKey}>
                        {(provided, snapshot) => (
                        <div
                            className="projectNavItem"
                            onClick={(e) => handleLoadProject(e, p._id)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getStyle({...provided.draggableProps.style}, p._id)}
                        >
                            <div style={{ display: "inline-block" }}>
                                <PiLineVerticalThin color="#bec1c3" size={16} />
                            </div>    
                            {p.name}
                            <div style={{ right: "30px"}} className="trashIcon" onClick={(e) => handleMoveToFolder(e, p._id)}>
                                <FaFolder size="16" color="#909495" />
                            </div>
                            <div className="trashIcon" onClick={(e) => handleDeleteProject(e, p._id)}>
                                <FaTrash size="14" color="#909495" />
                            </div>
                        </div>
                        )}
                    </Draggable>
                );
            });
        }
    });

    //onClick={(e) => !p.loaded && handleLoadProject(e, p._id)}

    /* Load projects that do not belong to a folder */
    projects.filter(p => p.folderId === "" || !p.folderId).forEach((p,i) => {
        const projectKey = counter();
        const key = "file-" + p._id;
        navItems.push(
            <Draggable key={key} draggableId={"file-" + p._id.toString()} index={projectKey}>
            {(provided, snapshot) => (
                <div 
                    className="projectNavItem"
                    onClick={(e) => handleLoadProject(e, p._id)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle({...provided.draggableProps.style}, p._id)}
                    >
                    {p.name}
                    {folders.length > 0 &&
                        <div style={{ right: "30px"}} className="trashIcon" onClick={(e) => handleMoveToFolder(e, p._id)}>
                            <FaFolder size="16" color="#909495" />
                        </div>
                    }
                    <div className="trashIcon" onClick={(e) => handleDeleteProject(e, p._id)}>
                        <FaTrash size="14" color="#909495" />
                    </div>
                </div>
                )}
            </Draggable>
        );
    });

    return (
        <>
        <div >
            <div style={workTitleWrapper}>
                <div style={{ width: "7%", display: "inline-block" }}>
                    <LuKanban size={20} color="#909495" />
                </div>
                <div style={{color: "#bec1c3", width: "33%",fontSize: "12pt", display: "inline-block", paddingLeft: "7px" }}>
                    Work
                </div>
                <div style={{ width: "60%", display: "inline-block", textAlign: "right" }}>
                    {!search ?
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => setShowSearch(true)}>
                            <BiSearch size={16} color="#909495" />
                        </div>
                    :
                        <div style={{ display: "inline-block", position: "relative", top: "1px", paddingRight: "10px", cursor: "pointer" }}
                            onClick={(e) => { setSearchText(""); setShowSearch(false) }}>
                            <AiOutlineClose size={16} color="#909495" />
                        </div>
                    }
                    <div style={{ display: "inline-block", paddingRight: "10px", cursor: "pointer" }}
                        onClick={(e) => createProjectFolder(e)}>
                        <FaRegFolder size={14} color="#909495" />
                    </div>
                    <div style={{ display: "inline-block", paddingRight: "0px", cursor: "pointer" }}
                        onClick={(e) => handleShowAddProject(e)}>
                        <AiOutlinePlus size={16} color="#909495" />
                    </div>
                </div>
            </div>
            {settingsState.settings.workExpanded &&
                <hr style={workTitleDivider} />
            }
            {search &&
                <div style={{ width: "94%", margin: "0px auto", color: "#909495", paddingTop: "20px" }}>
                    <input type="text" placeholder="Search projects..." className="navSearch"
                        value={searchText}
                        onChange={(e) => handleSearchChange(e)} />
                </div>
            }
{/* roadmapState.status !== "loadingMembers" && */}
            {settingsState.settings.workExpanded &&
                <div className="roadmapsExpanded" style={expandedProjectsClass}>
                    {(folderState.status !== "loadingWorkFolders" && 
                        roadmapState.status !== "loadingRoadmapCategories" && roadmapState.status !== "loadingRoadmapCards") &&
                        <DragDropContext onDragEnd={(e) => onFolderFileDragEnd(e, dispatch, folders, projects, 'work')}>                
                            <Droppable droppableId={"1"}>
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {navItems}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                </div>
            }
            {/*
            <ChatSubNav />
            */}
        </div>
        <PopupContextMenu params={popupParams} />
        <ProjectContextMenu params={projectContextMenuParams} />
        <MoveProjectContextMenu params={moveProjectContextMenuParams} />
        </>
    );
}

export default WorkSubNav;