import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getRoadmap } from "../Store/Roadmap";
import { getProject } from '../Store/Project';
import { Roadmap } from '../Models/Roadmap';
import { getSettings } from '../Store/Settings';

/*
interface Props {
    roadmap: Roadmap;
}
*/

function ChatMain() {
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const settingsState = useAppSelector(getSettings);
    const dispatch = useAppDispatch();

    return (
        <div className="rightContentDefault" style={{ marginTop: "-5px" }}>
            Main chat thread will be here...
        </div>
    );
}

export default ChatMain;