import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getRoadmap, createRoadmap, loadRoadmap } from '../Store/Roadmap';
import { ContextMenu } from '../Models/ContextMenu';
import { RoadmapContextMenuParams } from '../Models/Requests/RoadmapContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
//import { CARD_STATUSES, REPORT_FILTERS } from '../Helpers/Constants';
import { getFolder } from '../Store/Folder';
import { getSettings } from '../Store/Settings';
//import { getRoadmap } from '../Store/Roadmap';
//import { Folder } from '../Models/Folder';

interface Props {
    params: RoadmapContextMenuParams | undefined;    
}

function RoadmapContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const folderState = useAppSelector(getFolder);
    const settingsState = useAppSelector(getSettings);
    const roadmapState = useAppSelector(getRoadmap);
    //const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [name, setName] = useState("");
    const [folderId, setFolderId] = useState("");
    const [uom, setUom] = useState("quarterly");
    const [uomNumber, setUomNumber] = useState(4);
    //const [firstQuarterMonth, setFirstQuarterMonth] = useState(0);
    const [roadmapContextMenu, setRoadmapContextMenu] = useState<ContextMenu>();
    const [error, setError] = useState("");

    useEffect(() => {
        if (props.params) {
            let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
            if ((roadmapContextMenu && roadmapContextMenu._id !== props.params._id) || !roadmapContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu._id = props.params._id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setRoadmapContextMenu(contextMenu);
           /*
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                let roadmap = projectState.roadmaps.find(r => r.containerId === container!.id);
                if (roadmap) {
                    setName(roadmap.name);
                    setUom(roadmap.uom);
                    setUomNumber(roadmap.uomNumber);
                }
            }
                */
        }
    }, [props.params]);

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 2000);
        }
    }, [error]);

    /* this works, but comment out for now
    useEffect(() => {
        if (roadmapState.status === "roadmapCreated") {
            closeContextMenu();
            let newRoadmap = roadmapState.roadmaps[roadmapState.roadmaps.length - 1];
            if (newRoadmap) {
                dispatch(loadRoadmap(newRoadmap.id));
            }
        }
    }, [roadmapState.status]);
    */
    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setName("");
        //setUom("");
        //setUomNumber(0);
        setFolderId("");
        //setShowCreateFolder(false);
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setRoadmapContextMenu(contextMenu);
    }
    
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    /*
    const handleUomChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUom(e.currentTarget.value)
    }

    const handleUomNumberChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUomNumber(parseInt(e.currentTarget.value))
    }
    */
    const handleCreateRoadmap = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        //let container = projectState.containers.find(c => c.loaded);
        //if (container) {
        if (name === "") {
            setError("Please enter a Name");
            return;
        }
/*
        if (firstQuarterMonth === 0) {
            setError("Please select a Q1 Start");
            return;
        }
*/
        dispatch(createRoadmap({
            //containerId: container.id,
            name: name,
            uom: uom,
            uomNumber: uomNumber,
            //firstQuarterMonth: firstQuarterMonth,
            folderId: folderId,
            projectCreation: settingsState.settings.projectCreation,
        }));
        closeContextMenu();
        //}
    }

    const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFolderId(e.currentTarget.value);
    }
/*
    const handleChangeFirstQuarterMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFirstQuarterMonth(parseInt(e.currentTarget.value));
    }
*/
    const sContextMenu: React.CSSProperties = {
        top: (roadmapContextMenu) ? roadmapContextMenu.y : 0,
        left: (roadmapContextMenu) ? roadmapContextMenu.x : 0,
        width: (roadmapContextMenu) ? roadmapContextMenu.width : 3000,
        height: "auto",
        paddingBottom: "15px",
    }

    let folders = [...folderState.roadmapFolders].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return (
        <>
        {roadmapContextMenu && roadmapContextMenu._id !== "" &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>New Roadmap</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    {error !== "" &&
                        <div className="error2">
                            {error}
                        </div>
                    }
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div>
                            <input type="text" 
                                className="standardInput"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => handleNameChange(e)} 
                                />
                        </div>
                        {/*
                        <div>
                            <select 
                                className="standardInput"
                                value={firstQuarterMonth}
                                onChange={(e) => handleChangeFirstQuarterMonth(e)}>
                                <option value={0}>Start of Q1 (Month)</option>
                                <option value={1}>January</option>
                                <option value={2}>February</option>
                                <option value={3}>March</option>
                                <option value={4}>April</option>
                                <option value={5}>May</option>
                                <option value={6}>June</option>
                                <option value={7}>July</option>
                                <option value={8}>August</option>
                                <option value={9}>September</option>
                                <option value={10}>October</option>
                                <option value={11}>November</option>
                                <option value={12}>December</option>
                            </select>
                        </div>
                        */}
                        {folders.length > 0 &&
                            <div>
                                <select 
                                    className="standardInput"
                                    value={folderId}
                                    onChange={(e) => handleFolderChange(e)}>
                                        <option value={0}>Folder (optional)</option>
                                        {folders.map((f,i) => {
                                            return (
                                                <option key={i} value={f._id}>{f.name}</option>
                                            );
                                        })}
                                </select>
                            </div>
                        }
                        {/*
                        <div>
                            <select 
                                className="standardInput"
                                value={uom}
                                onChange={(e) => handleUomChange(e)}>
                                <option value="">Timeline</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option value="daily">Daily</option>
                            </select>
                        </div>
                        <div>
                            <select 
                                className="standardInput"
                                value={uomNumber}
                                onChange={(e) => handleUomNumberChange(e)}>
                                <option value={0}>Number of Periods</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                            </select>
                        </div>
                        */}
                        <div style={{ textAlign: "left" }}>
                            <Link to="" onClick={(e) => handleCreateRoadmap(e)} className="btn-orange">
                                Create
                            </Link> 
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default RoadmapContextMenu;