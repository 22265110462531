import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ProjectCard } from '../Models/ProjectCard';
import { getProject, saveCard, setCards } from '../Store/Project';
import { useAppDispatch, useAppSelector } from '../Core/hooks';

/* Quill imports are required because nested lists in editor use classes, not
   true semmantic nested lists. To get them to display correctly in view mode, 
   we must import these and apply the same class Quill does in editor mode.
   https://github.com/quilljs/quill/issues/1314
*/
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

interface Props {
    card: ProjectCard;
    closeHandler: (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>, visible: boolean) => void;
    editing: boolean;
}

function CardEditor(props: Props) {
    const projectState = useAppSelector(getProject);
    const editorRef = useRef<ReactQuill | null>(null);

    const dispatch = useAppDispatch();
    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription((props.card.description));
    }, []);

    useEffect(() => {
        if (props.editing && editorRef && editorRef.current && editorRef.current.editor) {
            let descriptionLength = editorRef.current.editor.getLength();
            editorRef.current.editor.setSelection(descriptionLength +1,1);
        }
    }, [props.editing])

    const saveDescription = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        let tempCard = {...props.card};
        tempCard.description = description;

        let cards = [...projectState.cards];
        let updatedCardIndex = cards.findIndex(c => c._id === tempCard._id);
        if (updatedCardIndex !== -1) {
            cards[updatedCardIndex] = tempCard;
            dispatch(setCards(cards));
        }

        dispatch(saveCard(tempCard));
        props.closeHandler(e, false);
    }

    let project = projectState.projects.find(p => p.loaded);

    return (
        <>
            {props.editing ?
                <>
                    <div className="descriptionBox">
                        <div className="descriptionDetails" onClick={(e) => {e.preventDefault(); e.stopPropagation(); }}> 
                            <ReactQuill theme="snow"
                                ref={editorRef}
                                value={description}
                                onChange={setDescription}
                                modules={{
                                    toolbar: {
                                      container: [...[
                                        { 'header': '1' },
                                        { 'header': '2' },
                                        "underline",
                                        "bold",
                                        "italic",
                                        "link",
                                        { 'list': 'ordered' },
                                        { 'list': 'bullet' },
                                        { 'indent': '-1' },
                                        { 'indent': '+1' }, 
                                        "image",
                                        "video",
                                        "code-block",
                                        'blockquote',
                                      ]],
                                    },
                                  }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <Link to="" onClick={(e) => saveDescription(e)} className="btn-orange">
                            Save
                        </Link>
                        <Link to="" onClick={(e) => {props.closeHandler(e, false); }}
                            className="ps-3" style={{ fontWeight: "500" }}>
                            Cancel
                        </Link>
                    </div>
                </>
            :
                <>
                {(!props.card.hasOwnProperty('description') || props.card.description === "") && description === "" && project?.permission?.toString() !== "observer" ?
                    <div onClick={(e) => {props.closeHandler(e, true)}} className="mt-2" style={{ borderRadius: "5px", backgroundColor: "#efefef" }}>
                        <div style={{ padding: "20px", fontWeight: "500", cursor: "pointer" }}>
                            Add a more detailed description...
                        </div>
                    </div>
                :
                    <>
                    {project?.permission?.toString() !== "observer" ?
                        <div onClick={(e) => e.stopPropagation()} 
                            style={{ height: "auto", paddingLeft: "0px" }}
                            className="cardDescription pt-3 ql-editor" 
                            dangerouslySetInnerHTML={{
                            __html: (description === '') ? props.card.description : description
                        }} />
                    :
                        <div className="cardDescription pt-3 ql-editor"
                            style={{ height: "auto", paddingLeft: "0px" }}
                            dangerouslySetInnerHTML={{
                            __html: (description === '') ? props.card.description : description
                        }} />
                    }
                    </>
                }
                </>
            }
        </>
    );    
}

export default CardEditor;