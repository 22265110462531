import React, { CSSProperties } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getRoadmap } from '../../Store/Roadmap';
import { getSettings } from '../../Store/Settings';
import { getProject } from '../../Store/Project';
//import Footer from '../../Components/Footer';
//import Navigation from '../../Components/Navigation';
import SubNav from '../../Components/SubNav';
import TopNav from '../../Components/TopNav';
import LeftNav from '../../Components/LeftNav';
import LeftSubNav from '../../Components/LeftSubNav';
import Main from '../../Components/Main';
import RoadmapMain from '../../Components/RoadmapMain';
import RoadmapGenerate from '../../Components/RoadmapGenerate';
import RoadmapMember from '../../Components/RoadmapMember';
import RoadmapSettings from '../../Components/RoadmapSettings';
import RoadmapSubNav from '../../Components/RoadmapSubNav';
//import ActionSubNav from '../../Components/ActionSubNav';
import WorkSubNav from '../../Components/WorkSubNav';
import HerdrSubNav from '../../Components/HerdrSubNav';
import Redirect from '../../Components/Redirect';
import Settings from '../../Components/Settings';
import WorkCalendar from '../../Components/WorkCalendar';
//import WorkList from '../../Components/WorkList';
import WorkBoard from '../../Components/WorkBoard';
import WorkMember from '../../Components/WorkMember';
import ChatSubNav from '../../Components/ChatSubNav';
import ChatMain from '../../Components/ChatMain';

function Dash() {

  const roadmapState = useAppSelector(getRoadmap);
  const projectState = useAppSelector(getProject);
  const settingsState = useAppSelector(getSettings);
  const roadmap = roadmapState.roadmaps.find(r => r.loaded);
  const project = projectState.projects.find(p => p.loaded);
  //console.log("Project Loaded: " + project?.id);
  const settings = settingsState.settings;

  let mainStyle: CSSProperties = { display: "inline-block" };
  
  if (settingsState.settings.loadedComponentType === "global_herdr") {
    //{ display: "inline-block", width: "70%" }
    mainStyle.width = "100%";
  } else {
    if (settingsState.settings.sidebarExpanded) {
      //if (settingsState.settings.chatExpanded) {
      //  mainStyle.width = "55%";
      //} else {
        mainStyle.width = "77%";
      //}
    } else {
      mainStyle.width = "100%";
    }
  }

  if (settingsState.settings.loadedComponentType === "") 
    return (
      <>
        <Redirect />
        <div style={{ width: "100%", margin: "0px auto", paddingTop: "100px", textAlign: "center" }}>
          <div>
              <img style={{ width: "125px"}} src="/images/herdr-orange-logo-cat-v1.png" alt="herdr.io" />
          </div>
          <div style={{ fontStyle: "italic", fontSize: "28pt" }}>Loading...</div>
        </div>
      </>
    );

    let loadedComponent = (settingsState.status !== "loadingComponent") ? <Main /> : <div />;
    
    if (settings.loadedComponentType === "global_herdr") {
        loadedComponent = <Settings />;
    } else if (settings.loadedComponentType === "roadmap") {
      //let roadmap = roadmapState.roadmaps.find(r => r.loaded);
      if (roadmap) {
        if (roadmap.mode === "roadmap") {
          loadedComponent = <RoadmapMain roadmap={roadmap} />;
          //console.log("loadedComponent: " + loadedComponent);
        //} else if (roadmap.mode === "generate") {
        //  loadedComponent = <RoadmapGenerate roadmap={roadmap} />;
        } else if (roadmap.mode === "members") {
          loadedComponent = <RoadmapMember roadmap={roadmap} />;
        //} else if (roadmap.mode === "settings") {
        //  loadedComponent = <RoadmapSettings roadmap={roadmap} />;
        }
      }
    } else if (settings.loadedComponentType === "work") {
      //let project = projectState.roadmaps.find(r => r.loaded);
      if (project) {
        //if (project.mode === "calendar") {
        //  loadedComponent = <WorkCalendar project={project} />;
        if (project.mode === "board") {
          loadedComponent = <WorkBoard project={project} />;
        //} else if (project.mode === "list") {
          //loadedComponent = <WorkList project={project} />;
        //} else if (project.mode === "members") {
        //  loadedComponent = <WorkMember project={project} />;
        }
      }
    } else if (settings.loadedComponentType === "chat") {
      loadedComponent = <ChatMain />;
    }

  return (
    <>
      <Redirect />
      <div style={{ display: "flex", alignItems: "top", margin: "0px auto", 
          backgroundColor: "#F8F9FA"
        }}>
        <div style={{ display: "inline-block", width: ".5%" }}></div>

        <div style={{ display: "inline-block", width: "70px" }}>
          <LeftNav />
        </div>

        {settingsState.settings.sidebarExpanded &&
          <>
            {(settingsState.settings.loadedComponentType === "roadmap" ||
            settingsState.settings.loadedComponentType === "work") &&
              <div style={{ display: "inline-block", width: "18%" }}>
                <LeftSubNav roadmap={roadmap} project={project} />
              </div>
            }
            {settingsState.settings.loadedComponentType === "global_maps" &&
              <div style={{ display: "inline-block", width: "18%" }}>
                <RoadmapSubNav />
              </div>
            }
            {settingsState.settings.loadedComponentType === "chat" &&
              <div style={{ display: "inline-block", width: "18%" }}>
                <ChatSubNav />
              </div>
            }
            {/*
            {settingsState.settings.loadedComponentType === "global_work" &&
              <div style={{ display: "inline-block", width: "18%" }}>
                <WorkSubNav />
              </div>
            }
            */}

            {/*
            {settingsState.settings.loadedComponentType === "global_herdr" &&
              <div style={{ display: "inline-block", width: "25%" }}>
                <HerdrSubNav />
              </div>
            }
            */}
            {settingsState.settings.loadedComponentType !== "global_herdr" &&
              <div style={{ display: "inline-block", width: "10px", backgroundColor: "#02152a", height: "100vh" }}></div>
            }
          </>
        }
{/*
        {settingsState.settings.chatExpanded &&
        <>
          <div style={{ display: "inline-block", width: "22%" }}>
            <ChatSubNav />
          </div>
        </>
        }
*/}
        <div style={mainStyle}>
          <TopNav />
          <SubNav />
          {loadedComponent}
        </div>
        <div style={{ display: "inline-block", width: ".5%" }}></div>
      </div>
    </>
  );
}

export default Dash;