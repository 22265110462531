import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getRoadmap } from '../Store/Roadmap';
import { getProject, setCards, saveCard, deleteCard, deleteComment, setComments } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { CardContextParam } from'../Models/Requests/CardContextParam';
import { AiOutlineClose } from "react-icons/ai";
import { ProjectCard, EmptyProjectCard } from '../Models/ProjectCard';
import { FaRegTrashAlt, FaCat, FaTag } from "react-icons/fa";
import CardEditor from '../Components/CardEditor';
import CardComment from '../Components/CardComment';
import LabelContextMenu from '../Modals/LabelContextMenu';
import ChangeCardWorkContextMenu from '../Modals/ChangeCardWorkContextMenu';
import AssignItemContextMenu from '../Modals/AssignItemContextMenu';
import { Label } from '../Models/Label';
import { LabelContextMenuParam } from '../Models/Requests/LabelContextMenuParam';
import { AssignItemContextMenuParam } from '../Models/Requests/AssignItemContextMenuParam';
import { ChangeCardWorkContextMenuParams } from '../Models/Requests/ChangeCardWorkContextMenuParams';
import { IoMapOutline } from 'react-icons/io5';
import { PiTag } from "react-icons/pi";
import { getSettings } from '../Store/Settings';
import { getUser } from '../Store/Auth';
import Avatar from "../Components/Avatar";
import { FaUserCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import ChangeCardOwnerContextMenu from './ChangeCardOwnerContextMenu';
import { ChangeCardOwnerContextMenuParams } from '../Models/Requests/ChangeCardOwnerContextMenuParams';

interface Props {
    params: CardContextParam | undefined;
}

function CardContext(props: Props) {
    
    const dispatch = useAppDispatch();
    const userState = useAppSelector(getUser);
    const projectState = useAppSelector(getProject);
    const roadmapState = useAppSelector(getRoadmap);
    const settings = useAppSelector(getSettings);
    const [cardContext, setCardContext] = useState<ContextMenu>();
    const [card, setCard] = useState<ProjectCard>(EmptyProjectCard);
    const [editingTitle, setEditingTitle] = useState<boolean>();
    const [editingDescription, setEditingDescription] = useState<boolean>(false);
    const [cardTitle, setCardTitle] = useState<string>("");
    const [labelContextMenuParams, setLabelContextMenuParams] = useState<LabelContextMenuParam>();
    const [assignItemContextMenuParams, setAssignItemContextMenuParams] = useState<AssignItemContextMenuParam>();
    const [changeCardWorkContextMenuParams, setChangeCardWorkContextMenuParams] = useState<ChangeCardWorkContextMenuParams>();
    const [changeCardOwnerContextMenuParams, setChangeCardOwnerContextMenuParams] = useState<ChangeCardOwnerContextMenuParams>();
    const [showEditComment, setShowEditComment] = useState<Array<boolean>>([]);

    useEffect(() => {
        let project = projectState.projects.find(p => p.loaded);
        if (project?._id !== props.params?.projectId) {
            setCardContext({ _id: "", x: 0, y: 0, width: 0, height: 0 });
        } else {
            if ((project) && props.params) {
                let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
                if (project?._id === props.params.projectId && 
                    ((cardContext && cardContext._id !== props.params.cardId) || !cardContext)
                ) {
                    let menuWidth = window.outerWidth * .6;
                    let menuXStart = window.outerWidth * .2;
                    let menuYStart = 10;

                    if (menuXStart + menuWidth > window.outerWidth) {
                        menuXStart = window.outerWidth - menuWidth - 10;
                    }

                    contextMenu._id = props.params.cardId;
                    contextMenu.x = menuXStart;
                    contextMenu.y = menuYStart;
                    contextMenu.width = menuWidth;
                }
                setCardContext(contextMenu);

                let card = projectState.cards.find(c => c._id === contextMenu._id);
                if (card) {
                    setCard(card);
                    setCardTitle(card.title);
                }
            }
        }
    }, [props.params]);

    useEffect(() => {
        /* If the card is updated in state via this window, then we
           need to refresh it so changes reflect in the UI
         */
        if (card) {
            if (card._id !== "") {
                let tempCard = projectState.cards.find(c => c._id === card._id);
                if (tempCard) {
                    setCard(tempCard);
                }
            }
        }
    }, [projectState.cards]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { _id: "", x: 0, y: 0, width: 0, height: 0 };
        setCardContext(contextMenu);
        setEditingTitle(false);
        setEditingDescription(false);
        setLabelContextMenuParams(undefined);
        setAssignItemContextMenuParams(undefined);
        setChangeCardWorkContextMenuParams(undefined);
        setChangeCardOwnerContextMenuParams(undefined);
    }

    const handleChangeCardOwner = (e: React.MouseEvent<HTMLSpanElement>, cardId: string) => {
        e.preventDefault();
        e.stopPropagation();
        //setChangeCardOwnerContextMenuParams({ event: e, cardId: cardId, roadmap: props.params?.roadmap });
        setChangeCardOwnerContextMenuParams({ event: e, cardId: cardId });
    }

    const trashCard = () => {
        if (cardContext) {
            // delete on the backend
            dispatch(deleteCard(cardContext._id));
            // delete on front end
            const cards: Array<ProjectCard> = [...projectState.cards];
            const index = projectState.cards.findIndex(c => c._id === cardContext._id);
            cards.splice(index, 1);
            dispatch(setCards(cards));
            closeMenu();
        }
    }

    const toggleEditingTitle = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEditingTitle(!editingTitle);
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCardTitle(e.target.value);
    }

    const updateCard = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> , _id: string | undefined) => {
        
        let update = false;

        if (e.type === "blur") {
            update = true;
        } else if (e.type === "keydown") {
            let event = e as React.KeyboardEvent<HTMLInputElement>;
            if (['Enter'].indexOf(event.key) !== -1 && cardTitle !== "") {
                update = true;
            }
        }

        if (update) {
            let tempCard = {...card};
            if(tempCard) {
                tempCard.title = cardTitle;
                let tempCards = projectState.cards.map(card => {
                    if (card._id === tempCard._id) {
                        return tempCard;
                    }
                    return card;
                });
                // frontend
                dispatch(setCards(tempCards));
                // save on backend
                dispatch(saveCard(tempCard));
                setEditingTitle(false);
            }
        }
    
    }

    const toggleEditDescription = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>, visible: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        /* Don't open the description editor if the user is highlighting text */
        if (window?.getSelection()?.type === 'Range' && visible) {
            return;
        }
        setEditingDescription(visible);
    }

    const toggleLabelContextMenu = (e: React.MouseEvent<HTMLDivElement>, cardId: string) => {
        e.preventDefault();
        e.stopPropagation();
        setLabelContextMenuParams({ cardId: cardId, event: e, roadmap: props.params?.roadmap });   
    };

    const showAssignItem = (e: React.MouseEvent<HTMLDivElement>, cardId: string) => {
        setAssignItemContextMenuParams({ event: e, cardId: cardId });  
    };

    const handleDeleteComment = (e: React.MouseEvent<HTMLAnchorElement>, _id: string) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(deleteComment(_id));
        let comments = [...projectState.comments];
        let commentIndex = comments.findIndex(c => c._id === _id);
        if (commentIndex !== -1) {
            comments.splice(commentIndex, 1);
            dispatch(setComments(comments));
        }
    }

    const toggleOpenEditComment = (e: React.MouseEvent<HTMLAnchorElement>, id: number) => {
        e.preventDefault();
        e.stopPropagation();
        let showComments: Array<boolean> = [];
        showComments[id] = true;
        setShowEditComment(showComments);
    }

    const toggleCloseEditComment = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        let showComments: Array<boolean> = [];
        setShowEditComment(showComments);
    }

    const handleChangeCardWork = (e: React.MouseEvent<HTMLSpanElement>, cardId: string, workType: string) => {
        e.preventDefault();
        e.stopPropagation();
        setChangeCardWorkContextMenuParams({ event: e, cardId: cardId, workType: workType, roadmap: props.params?.roadmap });
    }

    let project = projectState.projects.find(p => p.loaded);
    let permission = "";
    let list = projectState.lists.find(l => l._id === card.listId);
    let labels = projectState.labelsToCards.filter(lc => lc.cardId === card._id); 
    if (project && card.roadmapItem !== "") {
        labels.unshift({
            _id: "",
            labelId: "",
            cardId: card._id,
            created: new Date(),
        })
    }
    let cardCols = "col-9";
    let comments = projectState.comments.filter(c => c.cardId === card._id);
    /* below line will be broken until I load members for projects */
    let cardOwner = roadmapState.members.find(pm => pm.userId === card.ownerId);
    let statusColor = (card.status.toLocaleLowerCase() === "open") ? "#CB4335" : (card.status.toLocaleLowerCase() === "started") ? "#D4AC0D" : "#28B463";

    let actualWorkInMinutes =  card.actualWork; 
    let estimatedWorkInMinutes = card.estimatedWork;
    
    let aWeeks = Math.floor(actualWorkInMinutes / 2400);  
    let aDays = Math.floor((actualWorkInMinutes - (aWeeks * 2400)) / 480);
    let aHours = Math.floor((actualWorkInMinutes - (aWeeks * 2400) - (aDays * 480)) / 60);
    let aMinutes = Math.floor(actualWorkInMinutes - (aWeeks * 2400) - (aDays * 480) - (aHours * 60));

    let eWeeks = Math.floor(estimatedWorkInMinutes / 2400);  
    let eDays = Math.floor((estimatedWorkInMinutes - (eWeeks * 2400)) / 480);
    let eHours = Math.floor((estimatedWorkInMinutes - (eWeeks * 2400) - (eDays * 480)) / 60);
    let eMinutes = Math.floor(estimatedWorkInMinutes - (eWeeks * 2400) - (eDays * 480) - (eHours * 60));

    const sContextMenu: React.CSSProperties = {
        top: (cardContext) ? cardContext.y : 0,
        left: (cardContext) ? cardContext.x : 0,
        width: (cardContext) ? cardContext.width : 0,
        marginTop: "25px",
        marginBottom: "25px",
        minHeight: "90vh",
    }

    let formattedComments = comments.map(c => {

        let commentOwner = roadmapState.members.find(m => m.userId === c.userId);
        let firstName = "";
        let lastName = "";
        if (c.userId === "") {
            firstName = userState.firstName;
            lastName = userState.lastName;
        } else {
            if (commentOwner) {
                firstName = commentOwner.firstName;
                lastName = commentOwner.lastName;
            }
        }

        return (
            <div className="mt-2 mb-3">
                <div>
                    <div className="mb-2">
                        <Avatar size="xxsmall" member={commentOwner} inline={true} />
                        <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                            {firstName} {lastName}
                        </span>
                        <span style={{ paddingLeft: "5px", color: "gray", fontSize: "9pt" }}>
                            at {format(new Date(c.modified), 'MMM d h:mm a')}
                        </span>
                    </div>
                    {showEditComment[parseInt(c._id)] === true ?
                        <CardComment card={card} mode="edit" comment={c} closeHandler={toggleCloseEditComment} />
                    :
                    <>
                        <div className="cardComment ql-editor" 
                            onClick={(e) => {e.stopPropagation(); }}
                            dangerouslySetInnerHTML={{ __html: c.richText }}
                            style={{
                                padding: "7px 12px", borderRadius: "5px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                fontWeight: "500", fontSize: "11pt"
                            }}
                        /> 
                        {settings.settings.userId === c.userId && permission !== "observer" &&
                            <div className="mt-2">
                                <Link to="" onClick={(e) => toggleOpenEditComment(e, parseInt(c._id))} className="commentLink">
                                    Edit
                                </Link> 
                                <span className="commentLinkBullet ms-1 me-1">&bull;</span>
                                <Link to="" onClick={(e) => handleDeleteComment(e, c._id)} className="commentLink">
                                    Delete
                                </Link>
                            </div>
                        }
                    </>
                    }
                </div> 
            </div>
        );
    });

    return (
        <>
        {cardContext && cardContext._id !== "" &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} 
                    onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    <div style={{ position: "relative" }}>
                        <div className="divClose">
                            <Link to="" onClick={(e) => closeContextMenu(e)}>
                                <AiOutlineClose color="#555" size="13" />
                            </Link>
                        </div>
                    </div>
                    <div className="row cardH1" style={{ marginLeft: "10px" }}>
                        <div className="col-12">
                            <span style={{ cursor: "pointer", verticalAlign: "top" }} 
                                onClick={(e) => handleChangeCardOwner(e, card._id)}>
                                {card.ownerId && cardOwner ?
                                    <Avatar size="xsmall" member={cardOwner} inline={true} />
                                :
                                    <IconContext.Provider value={{ className: "imgxSmallAvatar" }}>
                                        <FaUserCircle />
                                    </IconContext.Provider>
                                }
                            </span>
                            {editingTitle ?
                                <input className="changeName" type="text"
                                    value={cardTitle}
                                    autoFocus
                                    onChange={(e) => handleTitleChange(e)} 
                                    onKeyDown={(e) => updateCard(e, card._id)}
                                    onBlur={(e) => updateCard(e, card._id)}
                                    style={{ marginLeft: "10px" }}  
                                />  
                            :
                                <>
                                {permission !== "observer" ?
                                    <div className="title" onClick={e => toggleEditingTitle(e)} style={{ marginLeft: "10px" }}>
                                        {card.title}
                                    </div>
                                :
                                    <div className="title" style={{ marginLeft: "10px" }}>
                                        {card.title}
                                    </div>
                                }
                                </>
                            }
                            <div className='listName' style={{ marginTop: "5px" }}>
                                Card <b>{card.ticketId > 0 ? "H" + card.ticketId : ""}</b> in list <b>{list?.name}</b> created on <b>{format(new Date(card.created),"eee L/d").toUpperCase()}</b>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{marginLeft: "10px", marginRight: "10px" }}>
                        <div className={cardCols}>
                            {labels.length > 0 &&
                            <>
                                <div className="labelsTitle">
                                    <b>Labels</b>
                                </div>
                                <div className="labelsSection">
                                    {labels.map(lc => {
                                        let label: Label | undefined = undefined;
                                        if (lc._id === "") { // roadmap item category
                                            let roadmapItem = roadmapState.cards.find(c => c._id === card.roadmapItem);
                                            if (project && roadmapItem) {
                                                let roadmapItemLabel = roadmapState.categories.find(c => c._id === roadmapItem!.categoryId);
                                                if (roadmapItemLabel) {
                                                    label = {
                                                        _id: "",
                                                        projectId: project._id,
                                                        title: roadmapItem.title,
                                                        backgroundColor: roadmapItemLabel.backgroundColor,
                                                        fontColor: roadmapItemLabel.fontColor,
                                                        created: new Date(),
                                                        modified: new Date(),
                                                        active: true,
                                                    }
                                                }
                                            }
                                        } else {
                                            label = projectState.labels.find(l => l._id === lc.labelId);
                                        }
                                        
                                        let style = {
                                            backgroundColor: label?.backgroundColor,
                                            color: label?.fontColor
                                        }                                        
                                        let style2 = {
                                            backgroundColor: label?.backgroundColor,
                                            color: label?.fontColor,
                                            cursor: "auto",
                                        }

                                        if (label) {
                                            return (
                                                <>
                                                {permission !== "observer" ?
                                                    <div key={"ls-" + lc._id} className="labelBadge" style={style} onClick={(e) => toggleLabelContextMenu(e, lc.cardId)}>
                                                        {label?.title}
                                                    </div>
                                                :
                                                    <div key={"ls-" + lc._id} className="labelBadge" style={style2}>
                                                        {label?.title}
                                                    </div>
                                                }
                                                </>
                                            );
                                        }
                                    })}
                                    <div style={{ clear: "both", marginBottom: "10px" }}></div>
                                </div>
                            </>
                            }
                            <div>
                                <div style={{ float: "left", fontWeight: "bold", padding: "5px 0px", fontSize: "14pt" }}>
                                    Description
                                </div>
                                {!editingDescription && permission !== "observer" &&
                                    <div onClick={(e) => {toggleEditDescription(e, true)}} 
                                        style={{ cursor: "pointer", float: "right", fontWeight: "bold", padding: "5px 10px", backgroundColor: "#efefef" }}>
                                        Edit
                                    </div>
                                }
                                <div style={{ clear: "both" }}></div>
                            </div>
                            <CardEditor card={card} closeHandler={toggleEditDescription} editing={editingDescription} />
                            <div style={{ marginTop: "20px", fontWeight: "bold", padding: "5px 0px", fontSize: "14pt" }}>
                                Activity
                            </div>
                            <CardComment card={card} mode="add" />
                            <div>
                                {formattedComments}
                            </div>
                        </div>
                        {permission !== "observer" &&
                            <div className="col-3">
                                <h2 className="cardH2">Options</h2>
                                <div className="divCardOption" onClick={(e) => toggleLabelContextMenu(e, card._id)}>
                                    <span className="icon">
                                        <PiTag size="12" />
                                    </span>
                                    <span className="label">
                                        Labels
                                    </span>
                                </div>
                                {!props.params?.roadmap &&
                                <>
                                    <div className="divCardOption mt-1" onClick={(e) => showAssignItem(e, card._id)}>
                                        <span className="icon">
                                            <IoMapOutline size="12" />
                                        </span>
                                        <span className="label">
                                            Roadmap Item
                                        </span>
                                    </div> 
                                    <div className="divCardOption mt-1" onClick={() => trashCard()}>
                                        <span className="icon">
                                            <FaRegTrashAlt size="12" />
                                        </span>
                                        <span className="label">
                                            Delete Card
                                        </span>
                                    </div>  
                                </>
                                }
                                <div style={{ margin: "10px auto 0px auto", width: "100%", textAlign: "center" }}>
                                    <div className="labelsTitle" style={{ marginTop: "5px", fontWeight: "bold" }}>
                                        <div style={{ marginBottom: "2px", textAlign: "center" }}>Estimated</div>
                                        <div className="workDetails" style={{
                                            display: "inline-block",
                                            padding: "5px 10px",
                                            borderRadius: "30px",
                                            color: "#fff",
                                            backgroundColor: "#273746",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textAlign: "center",
                                            width: "100%"
                                        }} onClick={(e) => handleChangeCardWork(e, card._id, "Estimated")}>
                                            {estimatedWorkInMinutes === 0 || !estimatedWorkInMinutes ? "Add Time" : ""}{eWeeks > 0 ? eWeeks + "W" : ""} {eDays > 0 ? eDays + "D" : ""} {eHours > 0 ? eHours + "H" : ""} {eMinutes > 0 ? eMinutes + "M" : ""}
                                        </div>
                                    </div>
                                    <div className="labelsTitle" style={{ fontWeight: "bold", margin: "0px auto" }}>
                                        <div style={{ marginBottom: "2px", textAlign: "center" }}>Actual</div>
                                        <div className="workDetails" style={{
                                            display: "inline-block",
                                            padding: "5px 10px",
                                            borderRadius: "30px",
                                            color: "#fff",
                                            backgroundColor: "#273746",
                                            fontWeight: 400,
                                            cursor: "pointer",
                                            textAlign: "center",
                                             width: "100%"
                                        }} onClick={(e) => handleChangeCardWork(e, card._id, "Actual")}>
                                            {actualWorkInMinutes === 0 || !actualWorkInMinutes ? "Add Time" : ""}{aWeeks > 0 ? aWeeks + "W" : ""} {aDays > 0 ? aDays + "D" : ""} {aHours > 0 ? aHours + "H" : ""} {aMinutes > 0 ? aMinutes + "M" : ""}
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        }
                    </div>
                    <LabelContextMenu params={labelContextMenuParams} />
                    <ChangeCardWorkContextMenu params={changeCardWorkContextMenuParams} />
                    <ChangeCardOwnerContextMenu params={changeCardOwnerContextMenuParams} />
                    <AssignItemContextMenu params={assignItemContextMenuParams} />
                </div>
            </div>
        }
        </>
    );
}

export default CardContext;