import React, { useState, useRef, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getSettings, saveProjectCreation } from '../Store/Settings';

function Settings() {

    const dispatch = useAppDispatch();
    const settingsState = useAppSelector(getSettings);
    const [projectCreation, setProjectCreation] = useState<string>(settingsState.settings.projectCreation);

    const handleProjectCreationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setProjectCreation(e.currentTarget.value);
        dispatch(saveProjectCreation(e.currentTarget.value));
    }

    return (
        <div className="rightContentDefault" style={{ fontSize: "10pt" }}>
            {/*
            <div style={{ width: "100%" }}>
                <div style={{ fontWeight: "600" }}>
                    Project Creation
                </div>
                <div>
                    Choose how your roadmaps will generate projects
                </div>
                <div className="pt-2">
                    <select className="form-control-custom-1"
                        value={projectCreation}
                        onChange={(e) => handleProjectCreationChange(e)}>
                        <option value="roadmap_item">1 project per roadmap item</option>
                        <option value="roadmap">1 project per roadmap</option>
                        <option value="manual">I will manually create projects</option>
                    </select>
                </div>
                <hr style={{ color: "#909495", margin: "15px 0px 0px 0px" }} />
            </div>
            */}
        </div>
    );
}

export default Settings;